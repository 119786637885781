import React from "react";
// Customizable Area Start
import BuyerMarketPlaceController, {
    Props
} from "./BuyerMarketPlaceController.web";

import { withStyles, StyleRules } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, Box, Typography, Paper, Tabs, Tab, Button, Avatar, Dialog, TextField, DialogContent, FormGroup, Checkbox, Divider, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import Loader from "../../../components/src/Loader.web";
import { BuyerSideBar, BuyerSideBarDrawer } from "../../../components/src/BuyerSideBar.web";
import { arrowDown, arrowNext, imgFilter, imgGridBtn, liveArchive, liveArchiveBlank, noDataFound } from "./assets";
const baseURL = require("../../../framework/src/config.js").baseURL
import TabPanel from "../../../components/src/TabPanel.web";
import StayTuned from "../../../components/src/StayTuned.web";
import MessageModalWeb from "../../../components/src/MessageModal.web";
import Pagination from "@material-ui/lab/Pagination";
import { withRouter } from 'react-router-dom';
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        display: "flex",
        width: '100%',
        minHeight: '100vh',
        flexWrap: 'nowrap',
        '@media(max-width: 600px)': {
            flexDirection: 'column'
        },
        '@media(min-width: 600px)': {
            marginTop: '100px'
        }
    },
    rightContainer: {
        padding: "0px 32px 32px",
    },
    contentBlockContent: {
        display: "flex",
        alignItems: "center",
        flexWrap: 'wrap',
        gap: '40px',
    },
    contentBlockElement: {
        position: "relative",
        maxWidth: '190px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        animation: 'fadeIn 2s',
        WebkitAnimation: 'fadeIn 2s',
        MozAnimation: 'fadeIn 2s',
        OAnimation: 'fadeIn 2s',
        msAnimation: 'fadeIn 2s',
    },
    noDataImg: {
        width: '100%',
        maxWidth: '300px',
        animation: 'fadeIn 2s',
        MozAnimation: 'fadeIn 2s',
        WebkitAnimation: 'fadeIn 2s',
        OAnimation: 'fadeIn 2s',
        msAnimation: 'fadeIn 2s',
    },
    contentBlockArchive: {
        position: "absolute",
        top: "12px",
        right: "12px",
        borderRadius: "25px",
        background: "#00000099",
        padding: "6px 5px 5px 5px",
        cursor: 'pointer'
    },
    contentBlockDesc: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: '12px'
    },
    contentBlockUser: {
        display: "flex",
        alignItems: "center",
        gap: '8px'
    },
    styleTabs: {
        "& .MuiTabs-flexContainer": {
            display: 'flex',
            justifyContent: 'space-between',
            width: '542px'
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "#F5EA16",
            height: '4px',
        },
        "& .MuiTab-root.Mui-selected": {
            color: '#000000'
        },
        padding: '0px 32px',
        borderBottom: '1px solid #E6E6E6',
        '@media(max-width: 697px)': {
            padding: '0px',
            "& .MuiTabs-flexContainer": {
                width: 'auto',
                justifyContent: 'flex-start',
            },
        },
    },
    styleTab: {
        color: '#00000066',
        fontSize: '18px',
        fontWeight: 500,
        width: '104px',
        whiteSpace: 'nowrap',
        textTransform: 'none'
    },
    buttonContainer: {
        padding: '32px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        position: 'relative'
    },
    filterButton: {
        borderRadius: '50px',
        border: '1px solid #3D3D3D',
        height: '36px',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000000',
        padding: '8px 16px',
        textTransform: 'none'
    },
    addButton: {
        background: '#F5EA16',
        borderRadius: '50px',
        height: '28px',
        fontSize: '14px',
        lineHeight: '21px',
        padding: '8px 16px',
        color: '#000000',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#F5EA16'
        },
    },
    showResultBtn: {
        background: '#F5EA16',
        borderRadius: '50px',
        height: '53px',
        width: '224px',
        fontSize: '18px',
        lineHeight: '28px',
        padding: '8px 16px',
        color: '#000000',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#F5EA16'
        },
    },
    clearButton: {
        borderRadius: '50px',
        border: '0.67px solid #F5EA16',
        height: '53px',
        width: '154px',
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '28px',
        padding: '8px 16px',
        color: '#000000',
        textTransform: 'none',
    },
    filterTitleBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: '24px',
    },
    filterBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    filterBtnBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '44px',
        width: '100%',
        marginTop: '24px',
        // '@media(max-width: 697px)': {
        //     flexWrap: 'wrap',
        // },
    },
    filterOptionBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        cursor: 'pointer'
    },
    filterDialog: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '518px',
        gap: '16px',
        padding: '24px 48px',
        '@media(max-width: 697px)': {
            padding: '16px',
        },
    },
    sortButton: {
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        margin: '0px',
        '& .MuiFormControlLabel-label': {
            fontSize: '21px'
        },
        "& .MuiRadio-colorSecondary.Mui-checked": {
            color: '#000000',
        }
    },
    checkBtn: {
        '& .MuiFormControlLabel-label': {
            fontSize: '21px',
            margin: '0px'
        },
        '& .MuiIconButton-label': {
            color: "#F5EA16",
        },
        "& .MuiCheckbox-root": {
            color: "white",
            background: "white",
            height: "16px",
            width: "16px",
            padding: '6px',
            margin: '12px'
        },
        "& .Mui-checked.MuiCheckbox-root": {
            color: "#F5EA16",
            background: "black"
        }
    },
    inputPriceField: {
        '& ::placeholder': {
            fontFamily: 'Arial',
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: '50px',
            maxWidth: '118px'
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #F5EA16'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #F5EA16'
        },
    },
    dialogBackground: {
        "& .MuiBackdrop-root": {
            background: '#0000004D',
            backdropFilter: "blur(2px)"
        }
    },
    categoryBox: {
        height: '400px',
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        "&::-webkit-scrollbar": {
            width: "6px",
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
            background: "#D9D9D955"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#D9D9D9",
            borderRadius: "10px",
        },
    },
    leftSidebar: {
        '@media(max-width: 600px)': {
            display: "none",
        },
        '@media(min-width: 600px)': {
            display: "block",
            minWidth: "240px"
        },
        minHeight: '100vh',
        borderRight: '5px solid #D9D9D94D',
    },
    description: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        height: '36px'
    }
    // Customizable Area End
}

export class BuyerMarketPlace extends BuyerMarketPlaceController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    colors = ["Blue", "Red", "Orange", "White", "Black", "Grey", "Green", "Yellow"]

    filterDialogBox = () => {
        const { classes } = this.props;
        const { openFilterBox } = this.state;
        return (
            <Dialog
                open={openFilterBox}
                onClose={this.closeFilterDialogBox}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                    },
                }}
                className={classes.dialogBackground}
            >
                <DialogContent className={classes.filterDialog} style={{ height: '743px', justifyContent: 'space-between' }}>
                    <Box className={classes.filterTitleBox} style={{ marginBottom: '32px' }} >
                        <Typography style={{ fontSize: '27px', fontWeight: 500 }}>Filter </Typography>
                        <CloseIcon onClick={this.closeFilterDialogBox} style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box className={classes.filterOptionBox} onClick={this.openSortDialogBox} >
                        <Typography style={{ fontSize: '22px', fontWeight: 500 }}>Sort by</Typography>
                        <img src={arrowNext} style={{ height: '21px', width: '10px' }} />
                    </Box>
                    <Divider />
                    <Box className={classes.filterOptionBox} onClick={this.openCategoryDialogBox}>
                        <Typography style={{ fontSize: '22px', fontWeight: 500 }}>Category</Typography>
                        <img src={arrowNext} style={{ height: '21px', width: '10px' }} />
                    </Box>
                    <Divider />
                    <Box className={classes.filterOptionBox} >
                        <Typography style={{ fontSize: '22px', fontWeight: 500 }}>Ratings</Typography>
                        <img src={arrowNext} style={{ height: '21px', width: '10px' }} />
                    </Box>
                    <Divider />
                    <Box className={classes.filterOptionBox} onClick={this.openPriceDialogBox}>
                        <Typography style={{ fontSize: '22px', fontWeight: 500 }}>Price</Typography>
                        <img src={arrowNext} style={{ height: '21px', width: '10px' }} />
                    </Box>
                    <Divider />
                    <Box className={classes.filterOptionBox} onClick={this.openSizeDialogBox}>
                        <Typography style={{ fontSize: '22px', fontWeight: 500 }}>Size</Typography>
                        <img src={arrowNext} style={{ height: '21px', width: '10px' }} />
                    </Box>
                    <Divider />
                    <Box className={classes.filterOptionBox} onClick={this.openColorDialogBox}>
                        <Typography style={{ fontSize: '22px', fontWeight: 500 }}>Color</Typography>
                        <img src={arrowNext} style={{ height: '21px', width: '10px' }} />
                    </Box>
                    <Divider />
                    <Box className={classes.filterBtnBox}>
                        <Button className={classes.showResultBtn} onClick={this.getFilteredData}>Show results</Button>
                        <Button className={classes.clearButton} onClick={this.handleClearAll}>Clear all</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    sortDialogBox = () => {
        const { classes } = this.props;
        const { openSortFilter, sortBy } = this.state;
        return (
            <Dialog
                open={openSortFilter}
                onClose={this.closeSortDialogBox}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                    },
                }}
                className={classes.dialogBackground}
            >
                <DialogContent className={classes.filterDialog} style={{ height: '595px', justifyContent: 'space-between' }}>
                    <Box className={classes.filterTitleBox} >
                        <Typography style={{ fontSize: '27px', fontWeight: 500 }}>Sort by</Typography>
                        <CloseIcon onClick={this.closeSortDialogBox} style={{ cursor: 'pointer' }} />
                    </Box>
                    <RadioGroup value={sortBy} onChange={this.handleSortByChange} style={{ gap: '8px', height: '350px', justifyContent: 'space-between' }}>
                        <FormControlLabel value="Best Match" label="Best Match" control={<Radio className={classes.sortButton} />} className={classes.sortButton} />
                        <Divider />
                        <FormControlLabel value="Lowest Price" control={<Radio className={classes.sortButton} />} label="Lowest Price" className={classes.sortButton} />
                        <Divider />
                        <FormControlLabel value="Highest Price" control={<Radio className={classes.sortButton} />} label="Highest Price" className={classes.sortButton} />
                        <Divider />
                        <FormControlLabel value="Newly Listed" control={<Radio className={classes.sortButton} />} label="Newly Listed" className={classes.sortButton} />
                    </RadioGroup>
                    <Box className={classes.filterBtnBox} >
                        <Button className={classes.showResultBtn} onClick={this.getFilteredData}>Show results</Button>
                        <Button className={classes.clearButton} onClick={this.handleClearSortBy}>Clear all</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    categoryDialogBox = () => {
        const { classes } = this.props;
        const { openCategoryFilter, categoryData, subCategoryData, showLoader, subCategoryID } = this.state;
        return (
            <Dialog
                open={openCategoryFilter}
                onClose={this.closeCategoryDialogBox}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                    },
                }}
                className={classes.dialogBackground}
            >
                <DialogContent className={classes.filterDialog} style={{ height: '634px', justifyContent: 'space-between' }}>
                    <Box className={classes.filterTitleBox} >
                        <Typography style={{ fontSize: '27px', fontWeight: 500 }}>Category</Typography>
                        <CloseIcon onClick={this.closeCategoryDialogBox} style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box className={classes.categoryBox}>
                        {categoryData?.map((items: any, index: any) => (
                            <Box key={index}>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '24px 0px', cursor: 'pointer' }} onClick={() => this.selectCategory(items.attributes.id, index)}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 500 }}>{items?.attributes?.name}</Typography>
                                    <img src={arrowDown} alt="arrow down" style={{ transform: index === this.state.openItemIndex ? "rotate(180deg)" : "rotate(270deg)" }} />
                                </Box>
                                {index === this.state.openItemIndex &&
                                    <Box style={{ position: 'relative' }}>
                                        {showLoader ? <CircularProgress style={{ color: '#F5EA16' }} />
                                            : <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', paddingBottom: '24px', cursor: 'pointer' }}>
                                                {subCategoryData?.map((item: any,) => (
                                                    <Box style={{ padding: '4px 16px', height: '35px', border: '0.5px solid #292D32', borderRadius: '50px', background: subCategoryID.includes(item.attributes.id) ? "#F5EA16" : "white" }} onClick={() => this.selectSubCategory(item.attributes.id)}>{item.attributes.name}</Box>
                                                ))}
                                            </Box>}
                                    </Box>
                                }
                                <Divider />
                            </Box>
                        ))}
                    </Box>
                    <Box className={classes.filterBtnBox}>
                        <Button className={classes.showResultBtn} onClick={this.getFilteredData}>Show results</Button>
                        <Button className={classes.clearButton} onClick={this.handleClearCategory}>Clear all</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    priceDialogBox = () => {
        const { classes } = this.props;
        const { openPriceFilter, priceFilter } = this.state;
        return (
            <Dialog
                open={openPriceFilter}
                onClose={this.closePriceDialogBox}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                    },
                }}
                className={classes.dialogBackground}
            >
                <DialogContent className={classes.filterDialog} style={{ height: '625px', justifyContent: 'space-between' }}>
                    <Box className={classes.filterTitleBox} style={{ margin: '24px 0px 0px' }} >
                        <Typography style={{ fontSize: '27px', fontWeight: 500 }}>Price</Typography>
                        <CloseIcon onClick={this.closePriceDialogBox} style={{ cursor: 'pointer' }} />
                    </Box>
                    <FormGroup style={{ gap: '8px' }}>
                        <FormControlLabel
                            control={<Checkbox checked={priceFilter === '0-500'} onChange={this.handlePriceChange} value="0-500" className={classes.checkBtn} />}
                            label="0-500"
                            className={classes.checkBtn}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={priceFilter === '501-1000'} onChange={this.handlePriceChange} value="501-1000" className={classes.checkBtn} />}
                            label="501-1000"
                            className={classes.checkBtn}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={priceFilter === '1001-1500'} onChange={this.handlePriceChange} value="1001-1500" className={classes.checkBtn} />}
                            label="1001-1500"
                            className={classes.checkBtn}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={priceFilter === '1501-2000'} onChange={this.handlePriceChange} value="1501-2000" className={classes.checkBtn} />}
                            label="1501-2000"
                            className={classes.checkBtn}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={priceFilter === '2001-2500'} onChange={this.handlePriceChange} value="2001-2500" className={classes.checkBtn} />}
                            label="2001-2500"
                            className={classes.checkBtn}
                        />
                    </FormGroup>
                    <Box>
                        <Typography style={{ fontSize: '21px', fontWeight: 500 }}>Custom</Typography>
                        <Box style={{ display: 'flex', gap: '16px', margin: '16px 0px', flexWrap: 'wrap' }}>
                            <Box style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                                <TextField
                                    variant="outlined"
                                    placeholder="₹ 0 "
                                    onChange={this.handleMinPriceChange}
                                    className={classes.inputPriceField}
                                    type="number"
                                />
                                <Typography style={{ fontSize: '15px', color: '#AAAAAA' }}>Min.</Typography>
                            </Box>
                            <Box style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                                <TextField
                                    variant="outlined"
                                    placeholder="₹ 0 "
                                    onChange={this.handleMaxPriceChange}
                                    className={classes.inputPriceField}
                                    type="number"
                                />
                                <Typography style={{ fontSize: '15px', color: '#AAAAAA' }}>Max.</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.filterBtnBox} style={{ margin: '0px 0px 24px' }}>
                        <Button className={classes.showResultBtn} onClick={this.getFilteredData}>Show results</Button>
                        <Button className={classes.clearButton} onClick={this.handleClearPrice}>Clear all</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    sizeDialogBox = () => {
        const { classes } = this.props;
        const { openSizeFilter, sizeFilter } = this.state;
        return (
            <Dialog
                open={openSizeFilter}
                onClose={this.closeSizeDialogBox}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                    },
                }}
                className={classes.dialogBackground}
            >
                <DialogContent className={classes.filterDialog} style={{ height: '549px', justifyContent: 'space-between' }}>
                    <Box className={classes.filterTitleBox} style={{ margin: '24px 0px 0px' }} >
                        <Typography style={{ fontSize: '27px', fontWeight: 500 }}>Size</Typography>
                        <CloseIcon onClick={this.closeSizeDialogBox} style={{ cursor: 'pointer' }} />
                    </Box>
                    <FormGroup style={{ gap: '8px', height: '300px', justifyContent: 'space-between' }}>
                        <FormControlLabel
                            control={<Checkbox checked={sizeFilter.includes('XS')} onChange={this.handleSizeChange} value="XS" className={classes.checkBtn} />}
                            label="XS"
                            className={classes.checkBtn}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={sizeFilter.includes('S')} onChange={this.handleSizeChange} value="S" className={classes.checkBtn} />}
                            label="S"
                            className={classes.checkBtn}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={sizeFilter.includes('M')} onChange={this.handleSizeChange} value="M" className={classes.checkBtn} />}
                            label="M"
                            className={classes.checkBtn}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={sizeFilter.includes('L')} onChange={this.handleSizeChange} value="L" className={classes.checkBtn} />}
                            label="L"
                            className={classes.checkBtn}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={sizeFilter.includes('XL')} onChange={this.handleSizeChange} value="XL" className={classes.checkBtn} />}
                            label="XL"
                            className={classes.checkBtn}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={sizeFilter.includes('XXL')} onChange={this.handleSizeChange} value="XXL" className={classes.checkBtn} />}
                            label="XXL"
                            className={classes.checkBtn}
                        />
                    </FormGroup>
                    <Box className={classes.filterBtnBox} style={{ margin: '0px 0px 24px' }}>
                        <Button className={classes.showResultBtn} onClick={this.getFilteredData}>Show results</Button>
                        <Button className={classes.clearButton} onClick={this.handleClearSize}>Clear all</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    colorDialogBox = () => {
        const { classes } = this.props;
        const { openColorFilter, colorFilter } = this.state;
        return (
            <Dialog
                open={openColorFilter}
                onClose={this.closeColorDialogBox}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                    },
                }}
                className={classes.dialogBackground}
            >
                <DialogContent className={classes.filterDialog} style={{ height: '549px', justifyContent: 'space-between' }}>
                    <Box className={classes.filterTitleBox} style={{ margin: '24px 0px 0px' }}>
                        <Typography style={{ fontSize: '27px', fontWeight: 500 }}>Colors</Typography>
                        <CloseIcon onClick={this.closeColorDialogBox} style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box className={classes.filterBox} style={{ flexWrap: 'wrap', margin: '16px 0px', justifyContent: 'start', gap: '12px', height: '275px', overflowX: 'hidden' }}>
                        {
                            this.colors.map((item: string, index: number) => (
                                <Box style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center', gap: '12px', height: '62px', padding: '15px 18px', borderRadius: '50px', border: colorFilter.includes(item) ? '1.52px solid #000000' : '1.52px solid #DCDCDC' }} key={index} onClick={() => this.handleColorChange(item)}>
                                    <Box style={{ background: item, height: '26px', width: '26px', borderRadius: '6px', border: '1px solid #E2E2E2' }} />
                                    <Typography style={{ fontSize: '21px', color: '#000000' }}>{item}</Typography>
                                </Box>
                            ))
                        }
                    </Box>
                    <Box className={classes.filterBtnBox} style={{ margin: '0px 0px 24px' }}>
                        <Button className={classes.showResultBtn} onClick={this.getFilteredData}>Show results</Button>
                        <Button className={classes.clearButton} onClick={this.handleClearColor}>Clear all</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        let favProductId = 0
        const { classes, history } = this.props;
        const { selectedTab, filteredData, loading, showLoader, categoryData, subCategoryData, openSubCategory, subCategoryID, openTunedBox, messageModel, message, wishListProduct } = this.state;
        return (
            <Grid container className={classes.root}  data-test-id="mainContainer">
                <div id="myref"></div>
                <Loader loading={loading} />
                <BuyerSideBarDrawer loading={showLoader} categories={categoryData} subCategories={subCategoryData} openSubCategory={openSubCategory} handleClickCategory={this.handleClickCategory} handleClickSubCategory={this.handleClickSubCategory} subCategoryID={subCategoryID} />
                <Box className={classes.leftSidebar}>
                    <BuyerSideBar loading={showLoader} categories={categoryData} subCategories={subCategoryData} openSubCategory={openSubCategory} handleClickCategory={this.handleClickCategory} handleClickSubCategory={this.handleClickSubCategory} subCategoryID={subCategoryID} />
                </Box>
                <Paper style={{ flexGrow: 1, boxShadow: 'none' }}>
                    <Tabs
                        value={selectedTab}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        className={classes.styleTabs}
                    >
                        <Tab label="For You" className={classes.styleTab} />
                        <Tab label="Bargain" className={classes.styleTab} />
                        <Tab label="On-Order" className={classes.styleTab} />
                    </Tabs>
                    <Box className={classes.buttonContainer}>
                        {this.filterDialogBox()}
                        {this.sortDialogBox()}
                        {this.sizeDialogBox()}
                        {this.colorDialogBox()}
                        {this.priceDialogBox()}
                        {this.categoryDialogBox()}
                        <Button className={classes.filterButton} onClick={this.openFilterDialogBox} ><img src={imgFilter} /></Button>
                        <Button className={classes.filterButton} onClick={this.openCategoryDialogBox}>Category<img src={arrowDown} /></Button>
                        <Button className={classes.filterButton} onClick={this.openSortDialogBox}> Sort<img src={arrowDown} /></Button>
                        <div style={{ position: 'absolute', right: 32, display: 'flex' }}>
                            <Typography onClick={this.showALL} data-test-id="show-all" style={{ fontSize: "14px", fontWeight: 400, cursor: 'pointer' }}>{this.state.showingAll ? `View Less >` : `View All >`}</Typography>
                        </div>
                    </Box>
                    <TabPanel value={selectedTab} index={0} >
                        <Box className={classes.rightContainer}>
                            <Box className={classes.contentBlockContent}>
                                {filteredData.length > 0 ? filteredData.map((item: any, index: number) => (
                                    <Box className={classes.contentBlockElement} key={index}>
                                        {
                                            this.checkProductStatus(wishListProduct, parseInt(item.id))
                                                ? (
                                                    <Box 
                                                        data-test-id={`removeWishItem-${index}`}
                                                        className={classes.contentBlockArchive} onClick={() => this.deleteProductItem(wishListProduct, parseInt(item.id))}>
                                                        <img 
                                                        src={liveArchive} alt="wished" />
                                                    </Box>
                                                ) : (
                                                    <Box data-test-id={`addWishItem-${index}`} className={classes.contentBlockArchive} onClick={() => this.updateWishListItem(item.id, item.type)}>
                                                        <img src={liveArchiveBlank} 
                                                        alt="not-wished" />
                                                    </Box>
                                                )
                                        }
                                        <Box data-test-id={`productDescriptionID-${index}`} onClick={() => this.handleProductDescription(item.id)}
                                            style={{ cursor: 'pointer' }}>
                                            <img src={item.attributes.product_images[0].url} style={{ height: '263px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', width: '190px', borderRadius: '10px', objectFit: 'cover' }} />
                                        </Box>
                                        <Box className={classes.contentBlockDesc}>
                                            <Box className={classes.contentBlockUser}>
                                                <Avatar src={item.attributes.host_image} />
                                                <Typography style={{ fontSize: "12px", fontWeight: 500 }} >{item.attributes.host_name}</Typography>
                                            </Box>
                                            <Typography className={classes.description} style={{ fontSize: "12px", fontWeight: 500 }} >{item.attributes.title}</Typography>
                                            <Typography className={classes.description} style={{ fontSize: "12px", color: "#A5A4A4" }} >{item.attributes.description}</Typography>
                                            <Box sx={{ fontSize: '16px', fontWeight: 500, fontFamily: "Arial !important" }} >₹ {item.attributes.selling_price}</Box>
                                            <Button className={classes.addButton} onClick={() => this.addItemInCart(item)}>Add to cart</Button>
                                        </Box>
                                    </Box>
                                ))
                                    : <Box style={{ width: '100%', display: 'flex', height: '50vh', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={noDataFound} className={classes.noDataImg} />
                                    </Box>}
                            </Box>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
                                <Pagination data-test-id="pagination" count={this.state.perPageCount} variant="outlined" shape="rounded" onChange={this.handleChangePagiation} />
                            </div>
                            {filteredData.length > 0 && <Divider style={{ marginTop: '50px' }} />}
                        </Box>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <StayTuned openTunedBox={openTunedBox} closeTunedBox={this.closeTunedBox} />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                        <StayTuned openTunedBox={openTunedBox} closeTunedBox={this.closeTunedBox} />
                    </TabPanel>
                </Paper>
                <MessageModalWeb
                    displayPopup={messageModel}
                    closeErrorPopup={this.closeMessageModal}
                    errorMessage={message} />
            </Grid>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
// @ts-ignore
export default withStyles(styles)(withRouter(BuyerMarketPlace));
// Customizable Area End
