import React from "react";

// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Typography,
  Input,
  styled,
  TextField,
  InputAdornment,
  IconButton,
  Drawer,
  Chip,
  Avatar,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import { tickSvg, addIcon, chatBackground, createGroupIcon, profileIcon, gallaryIcon, hamburgerIcon, newChannelIcon, newMessageIcon, searchIcon, smileyIcon, noChatImg, termsCheckIcon, flixooLoader, infoIcon, offerAccepted, offerDeclined } from "./assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import { Account, Seller, ExtendedUserMessage } from "./ChatController.web";
import data from "@emoji-mart/data";
import Picker from '@emoji-mart/react'; 
import moment from "moment";
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Lottie from "lottie-react";
import SendSharpIcon from '@material-ui/icons/SendSharp';
let config = require('../../../framework/src/config');
// Customizable Area End

import ChatControllerWeb, { configJSON, Props } from "./ChatController.web";

export default class Chat extends ChatControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAppLoader = () =>{
    return (
      <Box className="flexLoaderBox">
        <Box className="innerLoaderBox">
          <Lottie animationData={flixooLoader} loop={true} className="appLoader" />
        </Box>
      </Box>
    )
  };
  highlightText = (text: string, query: string) => {
    if (!query) return text;
  
    const regex = new RegExp(`(${query})`, 'gi');
    const parts = text.split(regex);
  
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} className="highlight">{part}</span>
      ) : (
        part
      )
    );
  };
  renderLeftSideChatList = () => {
    return (
      <Box className="leftBarMain">
        <Box className="chatHeader">
          <Typography className="title">{this.state.isCreateGroup ? "New Messages" : "Messages"}</Typography>
          {(this.state.isSearchbar && this.state.isCreateGroup)&& <Box className="searchBox1">
            <TextField
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} className="searchIcon" onClick={this.handleSearchToggle} />
                  </InputAdornment>
                ),
              }}
              type="text"
              className="searchInputField"
              placeholder="Search"
              // value={this.state.searchQuery}
              onChange={this.handleSearchAll}
            />
          </Box>}
          <Box className="addIconBox">
            {this.state.isCreateGroup === false ? <Box className="iconButton" data-test-id="newGroupToggle" onClick={this.handleCreateGroupToggle}>
              <img src={addIcon} className="addIcon" />
            </Box> :
              <Box style={{display: this.state.isSearchbar? "none":"block"}} className="iconButton" data-test-id="newGroupToggle" onClick={this.handleSearchToggle}> <img src={searchIcon} className="addIcon" /></Box>}
          </Box>
        </Box>
        {this.state.isCreateGroup === false ? this.renderSearchBar() : this.renderCreateGroup()}
        {this.state.isCreateGroup === false ? this.renderChatList() : this.renderContactList() }
      </Box>
    )
  };

  renderSearchBar = () => {
    return (
      <Box className="searchBox">
        <TextField
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <img src={searchIcon} className="searchIcon" />
              </InputAdornment>
            ),
          }}
          type="text"
          className="searchInputField"
          placeholder="Search"
          value={this.state.searchQuery}
          onChange={this.handleSearch}
        />
      </Box>
    )
  };

  renderChatList = () => {
    const { searchQuery, sellerList } = this.state;
    const isEmpty = sellerList.length === 0;
    return (
      <Box className="chatPeopleContainer">
        {this.state.isLoadingchat &&
                <Box className="loaderBox1">
                  <Lottie animationData={flixooLoader} loop={true} className="chatMessagesloader1" />
                </Box>}
        {this.state.sellerList.length > 0 && this.state.sellerList.map((chatListData: Seller, chatIndex: number) => (
          <Box
            key={chatIndex}
            className={`personInfoBox ${this.state.selectedSeller?.sellerId === chatListData?.sellerId && "currentChatBox"}`}
            data-test-id="channelAlllistTestId"
            onClick={() => this.handleClickSetChannelUser(chatListData,chatIndex)}
          >
            <Box className="personDetails">
              <Box className="avatarBox">
                <img src={(chatListData.profileUrl === "" || chatListData.profileUrl === "profile_url") ? profileIcon : chatListData?.profileUrl} className="personAvatar" />
              </Box>
              <Box className="rightInfoBox">
                <Box className="rightInfoTopBox">
                  <Box>
                    <Typography className="personNameTxt">
                    {this.highlightText(chatListData?.sellerName, searchQuery)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className={`dateTxt ${this.state.selectedSeller?.sellerId === chatListData?.sellerId  && "active"}`}>
                      {(chatListData?.lastMessageTime !== null && chatListData.lastMessageTime !== 0) ? moment(chatListData?.lastMessageTime).format('hh:mm') : ""}
                    </Typography>
                  </Box>
                </Box>
                <Box className="righInfoBottomBox">
                  <Typography className="lastMessageTxt">
                   {this.checkLastMessageType(chatListData)}
                  </Typography>
                  {chatListData?.unreadCount !== 0 && <Box className="chipBox">{chatListData.unreadCount}</Box>}
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
        {isEmpty &&(
        <Typography className="noUserFoundTxt">No user found</Typography>
      )}
      </Box>
    )
  };

  renderContactList = () => {
    const { sellerContactList } = this.state;
    const isEmpty = sellerContactList.length === 0;
    return (
      <Box className="contactListBox">
        {this.state.sellerContactList.length > 0 && this.state.sellerContactList.map((sellerData: Account, chatIndex: number) => {
          const highlightedUserName = this.highlightText(sellerData.user_name, this.state.searchQuery);
          const highlightedName = this.highlightText(sellerData.name, this.state.searchQuery);
          return(
          <Box
            key={chatIndex}
            className={`contactInfoBox`}
            onClick={() => this.handleTermsConditionModal(sellerData?.sendbird_credential?.user_id)}
            data-test-id="contactInfoBoxTestId"
          >
            <Box className="personDetails">
              <Box className="avatarBox">
                <img src={(sellerData.profile_photo === null) ? profileIcon : sellerData.profile_photo} className="personAvatar" />
              </Box>
              <Box className="rightInfoBox">
                <Box className="rightInfoTopBox">
                  <Box>
                    <Typography className="personNameTxt">
                    <span>{highlightedName}</span>
                    </Typography>
                  </Box>
                </Box>
                <Box className="righInfoBottomBox">
                  <Typography className="lastMessageTxt">
                  <span>{highlightedUserName}</span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>)}
        )}
        {isEmpty &&(<Typography className="noUserFoundTxt">No user found</Typography>)}
      </Box>
    )
  };

  renderCreateGroup = () => {
    return (
      <Box className="createGroupBox">
        <Box className="groupCreateBox">
          <Box className="newGroupIcon">
            <img src={createGroupIcon} alt="" className="groupIcon" />
          </Box>
          <Box>
            <Typography className="newGrpTxt">
              {configJSON.createNewGroup}
            </Typography>
          </Box>
        </Box>
        <Box className="groupCreateBox">
          <Box className="newGroupIcon">
            <img src={newChannelIcon} alt="" className="groupIcon" />
          </Box>
          <Box>
            <Typography className="newGrpTxt">{configJSON.newChannelTxt}</Typography>
          </Box>
        </Box>
      </Box>
    )
  };

  renderDrawer = () => {
    return (
      <CustomDrawer
        anchor={"left"}
        open={this.state.isDrawerOpen}
        onClose={this.handleChatDrawerToggle}
      >
        <StylesWrapper>{this.renderLeftSideChatList()}</StylesWrapper>
      </CustomDrawer>
    );
  };


  convertLinksToHyperlinks = (userMsgs: string) => {
    const urlRegex = configJSON.mediaHyperlinkRegex;
    return userMsgs.split(urlRegex).map((message, index) => {
      if (urlRegex.test(message)) {
        return (
          <a key={index} className="hyperlinkMsg" href={message} target="_blank" rel="noopener noreferrer">
            {message}
          </a>
        );
      }
      return message;
    });
  };

  leftSideRenderFirstMessage = (chat: ExtendedUserMessage, chatIndex: number) => {
    const isOnlyMessageCell = this.checkMessageType(chat);
    let isOnlyImage = chat.customType === 'image';
    let isOnlyDoc = chat.customType === 'doc' || chat.customType === "docx";
    let isOnlyPdf = chat.customType === 'pdf';
    let isBarginLeft = chat.customType === "bargain";
    let isBargainAcceptRejectLeft = chat.customType === "bargain_accept" || chat.customType === "bargain_declined";
    let isSuggestedProd = chat.customType === "suggested_product";
    if(isOnlyMessageCell){
      return (
        <Box key={chatIndex} className="receivedChats">
          <Box className="receivedMyCharts">
            <Box className="iconChatStyle"></Box>
            <Typography className="messageText">{this.convertLinksToHyperlinks(chat.message)}</Typography>
            <Box className="timeAppear">
              <Typography className="times">{moment(chat.createdAt).format('hh:mm')}</Typography>
            </Box>
          </Box>
        </Box>
      )
    } else if (isOnlyImage) {
      return (
        <Box key={chatIndex} className="receivedChats">
          <Box className="chatImageBox">
            <img data-test-id={`imageViewTestID${chatIndex}`} src={this.getAuthenticatedUrl(String(chat?.plainUrl))} className="chatImageStyle" onClick={()=> this.handleImageViewModalOpen(String(chat?.plainUrl))}/>
            <Box className="timeAppear">
              <Typography className="times">{moment(chat.createdAt).format('hh:mm')}</Typography>
            </Box>
          </Box>
        </Box>
      )
    } else if (isOnlyDoc) {
      return (
        <Box key={chatIndex} className="receivedChats">
          {this.renderMessagesDocument(chat,chatIndex)}
        </Box>
      )
    } else if (isOnlyPdf) {
      return (
        <Box key={chatIndex} className="receivedChats">
          <Box className="documentsBox">
            <Box className="innerDocBox">
                <Box className="docIconBox">
                  <PictureAsPdfIcon className="documentPdfIcon" />
                </Box>
                <Box className="documentInfoBox">
                  <span className="documentLink" data-test-id={`fileOpenTestId${chatIndex}`} onClick={()=> this.handleDocumentFileOpen(this.getAuthenticatedUrl(String(chat?.plainUrl)))}  >{this.truncatedDocumentName(chat?.name as string)}</span>
                </Box>
            </Box>
            <Box className="timeAppear">
              <Typography className="times">{moment(chat.createdAt).format('hh:mm')}</Typography>
            </Box>
          </Box>
        </Box>
      )
    }else if(isBarginLeft){
      return this.renderBuyerBargainLeftView(chat, chatIndex);
    }else if(isBargainAcceptRejectLeft){
      return this.renderAcceptRejectLeftBuyerView(chat);
    }else if(isSuggestedProd){
      return this.renderLeftSideSuggestedProduct(chat, chatIndex);
    }
  };

  renderLeftSideSuggestedProduct = (chat: ExtendedUserMessage, chatIndex: number) => {
    let suggestedProd = JSON.parse(chat.message);
    return (
      <Box key={chatIndex} className="receivedChats suggestion" >
        <Box className="bargainMainLeftContainer suggestion" >
          <Box className="bargainInnerContainer productSuggestionBox" data-test-id={`navigateDescTestId${chatIndex}`} onClick={()=> this.navigateToProductDesc(suggestedProd.productId)}>
          <Typography className="sugHeadingTxt">Similar product suggested by the seller</Typography>
            <Box className="onOrderMsgContainer innerSuggBox">
              <Box className="productImgBox suggestionBox">
                <img src={config.baseURL + suggestedProd.productUrl} alt="" className="productImg" />
              </Box>
              <Box className="rightProductDetailBox suggestionDetailBox">
                <Box className="rightHeaderBox"><Typography className="suggestionTitleTxt">{suggestedProd.title}</Typography></Box>
                <Box><Typography className="prdTitleTxt">{suggestedProd.description}</Typography></Box>
                <Box className="mainVariantBox">
                  <Box>
                    <Typography className="variantTxt">Colors Available :</Typography>
                  </Box>
                  <Box className="variantContainer">
                    {suggestedProd?.colors?.map((color: string) => (
                      <Box className="variantColorBox" style={{background: color}} >
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="timeAppear">
              <Typography className="times">{this.formattedChatDate(chat)}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  };

  renderAcceptRejectLeftBuyerView = (chat: ExtendedUserMessage) => {
    if (chat.customType === 'bargain_accept') {
      return (
        <Box className="receivedChats">
          <Box className="receivedMyCharts">
            <Box className="offerStatusMsgBox">
              <img src={offerAccepted} alt="" className="offerAcceptedImg" />
              <Typography className="messageText">{configJSON.acceptedBySellerTxt}</Typography>
            </Box>
            <Box className="timeAppear">
              <Typography className="times">{this.formattedChatDate(chat)}</Typography>
            </Box>
          </Box>
        </Box>
      )
    } else if (chat.customType === 'bargain_declined') {
      return (
        <Box className="receivedChats">
          <Box className="receivedMyCharts">
            <Box className="offerStatusMsgBox">
              <img src={offerDeclined} alt="" className="offerAcceptedImg" />
              <Typography className="messageText">{configJSON.declinedBySellerTxt}</Typography>
            </Box>
            <Box className="timeAppear">
              <Typography className="times">{this.formattedChatDate(chat)}</Typography>
            </Box>
          </Box>
        </Box>
      )
    }
  };

  renderBuyerBargainLeftView = (chat: ExtendedUserMessage, chatIndex: number) => {
    let bargainData = JSON.parse(chat.message);
    return (
      <Box key={chatIndex} className="receivedChats">
        <Box className="bargainMainLeftContainer">
        <Box className="bargainInnerContainer">
          <Box className="onOrderMsgContainer">
            <Box className="productImgBox">
              <img src={this.getAuthenticatedUrl(String(bargainData?.productImage))} alt="" className="productImg" />
            </Box>
            <Box className="rightProductDetailBox">
              <Box className="rightHeaderBox"><Typography className="prdTitleTxt">{bargainData.productTitle}</Typography> <Typography className="prdIdTxt">#{bargainData.productId}</Typography></Box>
              <Typography className="counterDetailTxt"><b>{this.state.selectedSeller?.sellerName} </b>has made an offer for this product</Typography>
              <Typography className="quantityTxt">Counter offer : <span className="counterPriceBold">{"₹"} {bargainData.askPrice}</span></Typography>
            </Box>
          </Box>
          <Box className="makePaymentBox">
            {bargainData.bargainStatus === "Accepted" ? <Box className="visibleBox"><Typography className="cartTxt">Your product will be delivered by seller. <br/> Kindly click view cart </Typography><button className="goToCartBtn" data-test-id="goToCartBtnTestId" onClick={this.navigateToCart}>View Cart</button></Box> :
              <Box className="acceptChatBargain">
                <Box className="bargainButtonsBox">
                  <Button className="acceptBtn" data-test-id={`acceptBargainTestId${chatIndex}`} disabled={bargainData?.bargainStatus === "pending" ? false : true} onClick={()=> this.handleAcceptBargainOffer(bargainData.bargainId, chat.messageId, bargainData.productId, chat.message, true)} >Accept</Button>
                  <Button className="declineBtn" data-test-id={`declineBargainTestId${chatIndex}`} disabled={bargainData?.bargainStatus === "pending" ? false : true} onClick={()=> this.handleDeclineBargainOffer(bargainData.bargainId, chat.messageId, bargainData.productId, chat.message, false)}>Decline</Button>
                  <Button className="makeOfferBtn" data-test-id={`makeOfferBtnTest${chatIndex}`} onClick={() => this.handleOfferModalOpen(bargainData.bargainId, chat.messageId, bargainData.productId, chat.message, false)} disabled={bargainData?.bargainStatus === "pending" ? false : true} >Make an offer</Button>
                </Box> 
              </Box>
            }
          </Box>
          <Box className="timeAppear">
            <Typography className="times">{this.formattedChatDate(chat)}</Typography>
          </Box>
        </Box>

        
        </Box>
      </Box>
    )
  };

  renderMessagesDocument = (chat: ExtendedUserMessage, chatIndex: number) => {
    return (
      <Box 
       className="documentsBox">
        <Box 
         className="innerDocBox">
          <Box className="docIconBox">
            <DescriptionIcon className="documentIcon" />
          </Box>
          <Box className="documentInfoBox">
            <span className="documentLink" data-test-id={`fileOpenTestId${chatIndex}`} onClick={()=> this.handleDocumentFileOpen(this.getAuthenticatedUrl(String(chat?.plainUrl)))}>{this.truncatedDocumentName(chat?.name as string)}</span>
          </Box>
        </Box>
        <Box 
          className="timeAppear">
          <Typography 
           className="times">
            {moment(chat.createdAt).format('hh:mm')}
          </Typography>
        </Box>
      </Box>
    )
  };

  renderRightMessagesDocument = (chat: ExtendedUserMessage, chatIndex: number) => {
    let chatData = JSON.parse(chat.data);
    return (
      <Box className="documentsBox">
        <Box className="innerDocBox">
          <Box className="docIconBox">
            <DescriptionIcon className="documentIcon" />
          </Box>
          <Box className="documentInfoBox">
            <span className="documentLink" data-test-id={`fileOpenTestId${chatIndex}`} onClick={()=> this.handleDocumentFileOpen(this.getAuthenticatedUrl(String(chat?.plainUrl)))}>{this.truncatedDocumentName(chat?.name as string)}</span>
          </Box>
        </Box>
        <Box className="timeAppear">
          <Typography className="times">{moment(chat.createdAt).format('hh:mm')}</Typography>
          <Box className="tickBox">{this.renderMessageStatus(chatData.read, chatData.delivered)}</Box>
        </Box>
      </Box>
    )
  };

  renderMessageStatus = (isRead: boolean, isDelivered: boolean) => {
    if (isRead) {
      return <img src={tickSvg} className="tickIcon" alt="Read" />;
    } else if (isDelivered) {
      return <DoneAllOutlinedIcon className="tickIcon" />; 
    }
    return null;
  };

  rightSideRenderFirstMessage = (chat: ExtendedUserMessage, chatIndex: number) => {
    const isOnlyMessageCell = this.checkMessageType(chat);
    let isOnlyImage = chat.customType === 'image';
    let isOnlyDoc = chat.customType === 'doc' || chat.customType === "docx" ;
    let isOnlyPdf = chat.customType === 'pdf';
    let chatData = JSON.parse(chat.data);
    let onOrderMsg = chat.customType === "on_order";
    let isBargainMessage = chat.customType === "bargain";
    let isBargainAcceptReject = chat.customType === "bargain_accept" || chat.customType === "bargain_declined";
    if(isOnlyMessageCell){
    return (
      <Box key={chatIndex} className="senderMessage">
        <Box className={`${chat.message == '❤️' ? "receivedMyHeart" : "receivedMyCharts"}`}>
          <Box className="iconChatStyle"></Box>
          <Typography className={`${chat.message == '❤️' ? "storyHeart" : "messageText"}`}>{this.convertLinksToHyperlinks(chat.message)}</Typography>
          <Box className="timeAppear">
            <Typography className="times">{moment(chat.createdAt).format('hh:mm')}</Typography>
            <Box className="tickBox">
               {this.renderMessageStatus(chatData.read, chatData.delivered)}
              </Box>
          </Box>
        </Box>
      </Box>
    )
    } else if (isOnlyImage) {
      return (
        <Box key={chatIndex} className="senderMessage1">
          <Box className="chatImageBox">
            <img data-test-id={`imageViewTestID${chatIndex}`} src={this.getAuthenticatedUrl(String(chat?.plainUrl))} onClick={()=> this.handleImageViewModalOpen(String(chat?.plainUrl))} className="chatImageStyle" />
            <Box className="timeAppear">
              <Typography className="times">{moment(chat.createdAt).format('hh:mm')}</Typography>
              <Box className="tickBox">
                {this.renderMessageStatus(chatData.read, chatData.delivered)}
              </Box>
            </Box>
          </Box>
          
        </Box>
      )
    } else if (isOnlyDoc) {
      return (
        <Box key={chatIndex} className="senderMessage">
          {this.renderRightMessagesDocument(chat,chatIndex)}
        </Box>
      )
    } else if (isOnlyPdf) {
      return (
        <Box key={chatIndex} className="senderMessage">
          <Box 
            className="documentsBox">
            <Box 
              className="innerDocBox">
                <Box 
                  className="docIconBox">
                   <PictureAsPdfIcon className="documentPdfIcon" />
                </Box>
                <Box className="documentInfoBox">
                  <span className="documentLink" data-test-id={`fileOpenTestId${chatIndex}`} onClick={()=> this.handleDocumentFileOpen(this.getAuthenticatedUrl(String(chat?.plainUrl)))} >{this.truncatedDocumentName(chat?.name as string)}</span>
                </Box>
            </Box>
            <Box 
              className="timeAppear">
              <Typography className="times">{moment(chat.createdAt).format('hh:mm')}</Typography>
              <Box className="tickBox">
              {this.renderMessageStatus(chatData.read, chatData.delivered)}
              </Box>
            </Box>
          </Box>
        </Box>
      )
    }else if(onOrderMsg){
      return this.renderOnOrderMessageView(chat, chatIndex); 
    }
    else if(isBargainMessage){
      return this.renderBuyerBargainRightView(chat, chatIndex);
    }
    else if(isBargainAcceptReject){
      return this.renderAcceptRejectBargainView(chat);
    }
  };

  renderAcceptRejectBargainView = (chat: ExtendedUserMessage) => {
    const chatStatus = JSON.parse(chat.data);
    if (chat.customType === 'bargain_accept') {
      return (
        <Box className="senderMessage">
          <Box className="receivedMyCharts">
            <Box className="offerStatusMsgBox">
              <img src={offerAccepted} alt="" className="offerAcceptedImg" />
              <Typography className="messageText">{configJSON.acceptedByYouTxt}</Typography>
            </Box>
            <Box className="timeAppear">
              <Typography className="times">{this.formattedChatDate(chat)}</Typography>
              <Box className="tickBox">
                {this.renderMessageStatus(chatStatus.read, chatStatus.delivered)}
              </Box>
            </Box>
          </Box>
        </Box>
      )
    } else if (chat.customType === 'bargain_declined') {
      return (
        <Box className="senderMessage">
          <Box className="receivedMyCharts">
            <Box className="offerStatusMsgBox">
              <img src={offerDeclined} alt="" className="offerAcceptedImg" />
              <Typography className="messageText">{configJSON.declinedByYouTxt}</Typography>
            </Box>
            <Box className="timeAppear">
              <Typography className="times">{this.formattedChatDate(chat)}</Typography>
              <Box className="tickBox">
                {this.renderMessageStatus(chatStatus.read, chatStatus.delivered)}
              </Box>
            </Box>
          </Box>
        </Box>
      )
    }
  };

  renderBuyerBargainRightView = (chat: ExtendedUserMessage, chatIndex: number) => {
    let bargainData = JSON.parse(chat.message);
    let chatData = JSON.parse(chat.data);
    return (
      <Box key={chatIndex} className="senderMessage">
        <Box className="receivedMyCharts">
          <Box className="onOrderMsgContainer">
            <Box className="productImgBox">
              <img src={this.getAuthenticatedUrl(String(bargainData?.productImage))} alt="" className="productImg" />
            </Box>
            <Box className="rightProductDetailBox">
              <Box className="rightHeaderBox"><Typography className="prdTitleTxt">{bargainData.productTitle}</Typography> <Typography className="prdIdTxt">#{bargainData.productId}</Typography></Box>
              <Typography className="prdDescriptionTxt">{bargainData.productDesc}</Typography>
              <Typography className="quantityTxt">Ask Price : <span className="counterPriceBold">{"₹"} {bargainData.askPrice}</span></Typography>
            </Box>
          </Box>
          <Box className="makePaymentBox">
              {bargainData.bargainStatus === "Accepted" && <Box className="visibleBox"><Typography className="cartTxt">Your product will be delivered by seller. <br /> Kindly click view cart </Typography><button className="goToCartBtn" data-test-id="goToCartBtnTestId" onClick={this.navigateToCart}>View Cart</button></Box>}
            </Box>
          <Box className="timeAppear">
            <Typography className="times">{this.formattedChatDate(chat)}</Typography>
            <Box className="tickBox">
              {this.renderMessageStatus(chatData.read, chatData.delivered)}
            </Box>
          </Box>
          
        </Box>
      </Box>
    )
  };

  renderOnOrderMessageView = (chat: ExtendedUserMessage, chatIndex: number) => {
    let onOrderData = JSON.parse(chat.message);
    let chatData = JSON.parse(chat.data);
    return (
      <Box key={chatIndex} className="senderMessage">
        <Box className="receivedMyCharts">
          <Box className="onOrderMsgContainer">
            <Box className="productImgBox">
              <img src={this.getAuthenticatedUrl(String(onOrderData?.productUrl))} alt="" className="productImg" />
            </Box>
            <Box className="rightProductDetailBox">
              <Box className="rightHeaderBox"><Typography className="prdTitleTxt">{onOrderData.title}</Typography> <Typography className="prdIdTxt">#{onOrderData.productId}</Typography></Box>
              <Typography className="prdDescriptionTxt">{onOrderData.description}</Typography>
              <Typography className="quantityTxt">Quantity :- {onOrderData.quantity}</Typography>
              <Typography className="expectedDateTxt">Expected Date :- {moment(onOrderData.expectedDate).format('DD/MM/YYYY')}</Typography>
            </Box> 
          </Box>
          <Box className="makePaymentBox">
            {onOrderData.onOrderStatus === "Accepted" && <Box className="visibleBox"><button className="goToCartBtnReq" data-test-id="goToCartBtnTestId" onClick={this.navigateToCart}>View Cart</button></Box>}
          </Box>
          <Box className="timeAppear">
            <Typography className="times">{moment(chat.createdAt).format('hh:mm')}</Typography>
            <Box className="tickBox">
              {this.renderMessageStatus(chatData.read, chatData.delivered)}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  };

  renderTermsModal = () => {
    return (
      <CustomModal
        open={this.state.isTermsModal}
        onClose={this.handleTermsModalToggle}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className="mainModalContainer">
          <Box className="modalHeader">
            <Typography className="modalTitleText">{configJSON.forYourSafety}</Typography>
            <Typography className="modalBodyText privacyTxt">
              Please agree our{" "}
              <span className="linkText"> privacy policy </span> &{" "}
              <span className="linkText"> terms & condition </span>to continue
            </Typography>
          </Box>
          <Box className="modalMiddleBox">
            <Box className="modalInnerBottomBox">
              <Box className="checkIconBox"><img src={termsCheckIcon} className="termsCheckIcon"/></Box>
              <Box className="modalBodyText">
                {configJSON.privacyText1}
              </Box>
            </Box>
            <Box className="modalInnerBottomBox">
              <Box className="checkIconBox"><img src={termsCheckIcon} className="termsCheckIcon"/></Box>
              <Box className="modalBodyText">
              {configJSON.privacyText2}
              </Box>
            </Box>
            <Box className="modalInnerBottomBox">
              <Box className="checkIconBox"><img src={termsCheckIcon} className="termsCheckIcon"/></Box>
              <Box className="modalBodyText">
              {configJSON.privacyText3}
              </Box>
            </Box>
          </Box>
          <Box className="ModalBottomBtnBox">
            <Button className="agreeBtn" onClick={this.handleAgree} data-test-id="agreeButtontestId"><Typography className="termsBtnTxt">I Agree</Typography></Button>
            <Button className="goBackBtn" onClick={this.handleTermsModalToggle} data-test-id="termsModalTestId"><Typography className="termsBtnTxt">Go Back</Typography></Button>
          </Box>
        </Box>
      </CustomModal>
    );
  };

  renderNoMessagesScreen = () => {
    return (
      <Box className="newContactScreen">
        <img src={newMessageIcon} alt="" className="newMessagesIcon" />
        <Typography className="sendReceiveMsg">Send and receive messages anytime</Typography>
      </Box>
    )
  };

  renderNoChatScreen = () => {
    return (
      <Box className="newContactScreen">
        <img src={noChatImg} alt="" className="noChatScreen" />
        <Typography className="sendReceiveMsg">Currently no Messages</Typography>
      </Box>
    )
  };

  renderChatMessagesScreen = () => {
    const {selectedSeller} = this.state;
    return (
      <Box>
        <Box className="chatViewHeader headerGap">
          <Box className="activeChatInfoHeader">
            <Box className="profileImgBox">
              <img
                src={(selectedSeller?.profileUrl === "profile_url" || selectedSeller?.profileUrl === "")
                  ? profileIcon
                  : selectedSeller?.profileUrl}
                className="personAvatar"
              />
            </Box>
            <Box className="infoHeaderRight">
              <Typography className="personNameTxt">
                {selectedSeller?.sellerName}
              </Typography>
              <Typography className="lastSeenTxt">
                {this.state.isSellerOnline ? "Online" : "Offline"}
              </Typography>
            </Box>
          </Box>
          <Box className="headerSearchIconBox">
            <img src={searchIcon} className="searchIcon" />
          </Box>
        </Box>
        <Box className="todayBox">
          <Box className="timeStampMsg">
            <Typography className="todayHeading">{this.state.dateLabel}</Typography>
          </Box>
        </Box>
        <Box className="rightChatContent">
          <ChatContainerWrapper>
            <div className="mainChatContainer" ref={this.chatContainerRef} >
              {this.state.isLoading &&
                <Box className="loaderBox">
                  <Lottie animationData={flixooLoader} loop={true} className="chatMessagesloader" />
                </Box>}
              {this.state.messages &&
                this.state.messages.map(
                  (chat: ExtendedUserMessage, chatIndex: number) => {
                    const isCurrentUser = this.state.currentUserID === chat?.sender?.userId;
                    return (
                      <span key={chatIndex}>
                        {isCurrentUser
                          ? this.rightSideRenderFirstMessage(chat, chatIndex)
                          : this.leftSideRenderFirstMessage(chat, chatIndex)
                        }
                      </span>
                    )
                  }
                )}
            </div>
          </ChatContainerWrapper>
        </Box>
        <Box className="inputMainContainer">
         {this.state.fileModal ? this.renderImageModal() : null}
          <Box className="emojiPicker">
            {this.state.isEmoji && (
              <div ref={this.pickerRef}>
                <Picker
                  previewPosition="none"
                  data={data}
                  data-testId="emojiPickerChangeId"
                  onEmojiSelect={this.handleEmojiSelect}
                />
              </div>
            )}
          </Box>
          <Box className="inputInnerContainer">
            <input type="file" hidden accept="image/*,.doc,.docx,.pdf" data-test-id="fileInputTestId" className="fileInput" ref={this.fileInputRef} onChange={this.handleInputFileChange} />
            <TextField
              fullWidth
              placeholder="Type a Message"
              className="messageInputField"
              data-test-id="inputMessageTestId"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                  
                    <IconButton className="textFieldEndIcons" data-test-id="gallaryTestId" onClick={this.handleFileChange} >
                      <img src={gallaryIcon} className="endIcon" />
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={this.handleEmojiPicker} data-test-id="emojiToggleTestId">
                      <img src={smileyIcon} />
                    </IconButton>
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              onChange={this.handleMessageChange}
              onKeyPress={this.handleEnterKeyPress} 
              value={this.state.newMessage}
            />
            <Button className="sendButton" data-test-id="sendButtonTestId" onClick={this.handleKeyPress}>
              <SendSharpIcon/>
            </Button>
          </Box>
        </Box>
      </Box>
    )
  };

  renderScreenForChat = () =>{
    const { isCreateGroup, sellerList } = this.state;
    let content;
    if (!isCreateGroup && sellerList.length > 0) {
      content = this.renderChatMessagesScreen();
    } else if (isCreateGroup) {
      content = this.renderNoMessagesScreen();
    } else {
      content = this.renderNoChatScreen();
    }
    return content;
  };

  renderImageModal = () => {
    return (
        <Chip className="imageData"
          avatar={<Avatar alt="File" src={this.state.fileUrl} className="avatarFile" />}
          label={this.truncatedDocumentName(this.state.imageName)}
        />
    )
  };

  renderImageViewOpenModal = () => {
    return (
      <ImageViewCustomModal
        open={this.state.isImageViewModal}
        onClose={this.handleImageModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className="imageViewMainContainer">
          <img src={this.getAuthenticatedUrl(String(this.state.imageViewUrl))} alt="" className="imageView" />
        </Box>
      </ImageViewCustomModal>
    )
  };

  renderCounterOfferModal = () => {
    return (
      <OfferModalWrapper
        open={this.state.isCounterModalOpen}
        onClose={this.handleToggleCounterModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        data-test-id="counterModalTestId"
      >
        <Box className="mainContainer">
          <Box className="innerBox">
            <Typography className="headingCounter">Custom pricing</Typography>
            <Typography className="reasonTxt"> <img src={infoIcon} className="infoIcon"/>In this section, enter the customised pricing you agreed upon.</Typography>
            <Box className="inputFieldBox">
              <TextField
                className="textInputField"
                type="text"
                id="outlined-textarea"
                data-test-id="customPriceTestId"
                label="Custom Price"
                variant="outlined"
                placeholder="₹ 0"
                onChange={this.handleCounterValueChange}
                value={this.state.counterValue}
                InputLabelProps={{
                  shrink: true,
                }}
              />
             <Typography className="errTxt">{this.state.counterValueError}</Typography>
            </Box>    
            <Button className="sendButton" data-test-id="sendCounterOfferTestId" onClick={this.handleCounterOfferSubmit}>Send</Button>
          </Box>
        </Box>
      </OfferModalWrapper>
    )
  };

  renderDeclineOnOrderModal = () => {
    return (
      <BuyerDeclineModal
        open={this.state.isDeclineModalOpen}
        onClose={this.handleToggleDeclineModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className="rejectModalContainer">
          <Typography className="rejectModalTitle">Reason for decline</Typography>
          <Typography className="informationBox"> <img src={infoIcon} className="infoIcon" />{configJSON.reasonSubtitleTxt}</Typography>
          <RadioGroup aria-label="declineBargain" name="declineBargain" data-test-id="rejectOrderRadioTestId" value={this.state.rejectionReason} onChange={this.handleRejectReasonChange} >
            <Box className="radioButtonBox">
              <RadioFormControlLabel value="Overly Complex Requests" control={<Radio />} label="Overly Complex Requests" />
            </Box>
            <Box className="radioButtonBox">
              <RadioFormControlLabel value="Unrealistic Deadlines" control={<Radio />} label="Unrealistic Deadlines" />
            </Box>
            <Box className="radioButtonBox">
              <RadioFormControlLabel value="Mismatch with Brand or Style" control={<Radio />} label="Mismatch with Brand or Style" />
            </Box>
            <Box className="radioButtonBox">
              <RadioFormControlLabel value="Cost Ineffectiveness" control={<Radio />} label="Cost Ineffectiveness" />
            </Box>
            <Box className="radioButtonBox">
              <RadioFormControlLabel value="Other" control={<Radio />} label="Other" />
            </Box>
          </RadioGroup>
          {this.state.rejectionReason === "Other" && <TextField data-test-id="reasonOtherInputTestId" fullWidth multiline minRows={3} maxRows={3} placeholder="Write max 100 words" variant="outlined" value={this.state.rejectionOtherReason} onChange={this.handleOtherReasonChange} className="rejectReasonInputField" label="Reason*" />}
          {this.state.rejectionReasonError && <Typography className="errTxt">{configJSON.pleaseEnterTxt}</Typography>}
          <Button className="submitDeclineOrderBtn" data-test-id="submitRejectTestId" onClick={this.postDeclineOrderApiCall}>Submit</Button>
        </Box>
      </BuyerDeclineModal>
    )
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StylesWrapper>
        {this.state.isAppLoading && this.renderAppLoader()}
        <Box className="buyerChatHeader">
          <Box className="buyerInnerHeaderBox">
            <Box className="headerMessageBox">
              <Typography className="messagesHeadingTxt">{configJSON.messageTxt}</Typography>
            </Box>
            <Box className="hamburgerBox">
              <IconButton className="hamburgerIcon" data-test-id="chatListDrawerBtn" onClick={this.handleChatDrawerToggle}>
                <img src={hamburgerIcon} />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box className="mainContainer">
          <Box className="leftSideBar">
            {this.renderLeftSideChatList()}
            {this.renderDrawer()}
            {this.renderTermsModal()}
            {this.renderImageViewOpenModal()}
            {this.renderCounterOfferModal()}
            {this.renderDeclineOnOrderModal()}
          </Box>
          <Box className="rightPart">
             {this.renderScreenForChat()}
          </Box>
        </Box>
      </StylesWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StylesWrapper = styled(Box)({
  "& .mainContainer": {
    display: "flex",
    marginTop: "100px",
    height:"calc(100vh - 100px)",
    "@media(max-width:1150px)": {
      marginTop: "65px",
    },
    "@media(max-width:1000px)": {
      marginTop: "0px",
    },
  },
  "& .leftSideBar": {
    background: "#FFFFFF",
    width: "30%",
    height: "calc(100vh - 100px)",
    borderRight: "1px solid #55555533",
    "@media(max-width:1000px)": {
      display: "none",
    },
  },
  "& .leftBarMain": {
    "@media(max-width:1000px)": {
      display: "block",
      background: "#FFFFFF",
      width: "100%",
      borderRight: "1px solid #55555533",
    },
  },
  "& .rightPart": {
    backgroundImage: `url(${chatBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    position: "relative",
    "@media(max-width:1000px)": {
       margin:"10px",
       border: "2px solid #55555533",
       borderRadius:"12px"
    },
  },
  "& .newContactScreen":{
    backgroundImage: `url(${chatBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    position: "relative",
    "@media(max-width:1000px)": {
       margin:"10px",
       border: "2px solid #55555533",
       borderRadius:"12px"
    },
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    height:"100%",
    gap:"10px"
  },
  "& .newMessagesIcon":{
    width:"166px",
    height:"67px",
  },
  "& .noChatScreen":{
    width:"481px",
    height:"296px",
    "@media(max-width:600px)":{
      width:"85%",
      height:"290px"
    }
  },
  "& .sendReceiveMsg":{
      fontSize:"20px",
      fontWeight:400,
      color:"#000000",
      "@media(max-width:600px)":{
        fontSize:"16px",
      }
  },
  "& .rightChatContent":{
    overflowY: "auto",
    scrollbarWidth:"none",
  },
  "& .chatHeader": {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #CCCCCC",
    alignItems: "center",
    height: "70px",
    padding: "0px 20px",
    backgroundColor: "white",
    "@media(max-width:1000px)": {
      border: "2px solid #55555533",
    },
  },
  "& .chatViewHeader": {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #CCCCCC",
    alignItems: "center",
    height: "70px",
    padding: "0px 20px",
    backgroundColor: "white",
  },
  "& .title": {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "24px",
    width: "100%"
  },
  "& .addIcon": {
    width: "24px",
    height: "24px",
  },
  "& .flexLoaderBox":{
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 2,
  },
  "& .innerLoaderBox":{
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  "& .appLoader":{
    width:"250px",
    height:"250px"
  },
  "& .highlight" :{
    backgroundColor: "yellow",
    fontWeight: "bold"
  },
  "& .searchInputField": {
    width: "100%",
    border: "1px solid #DCDCDC",
    borderRadius: "39px",
    padding: "8px 20px",
    "@media(max-width:600px)":{
      width:"250px",
    },
  },
  "& .searchInputField:placeholder": {
    color: "#00000080",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  "& .searchBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 20px",
  },
  "& .searchBox1": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0",
  },
  "& .searchIcon": {
    marginRight: "10px",
    color: "#00000080",
    width: "20px",
    height: "20px",
  },
  "& .personInfoBox": {
    display: "flex",
    marginTop: "20px",
    marginLeft: "1px",
    paddingLeft: "22px",
    padding: "10px",
  },
  "& .contactInfoBox": {
    display: "flex",
    marginTop: "20px",
    marginLeft: "1px",
    paddingLeft: "22px",
    padding: "10px",
    cursor:"pointer"
  },
  "& .personDetails": {
    width: "100%",
    height: "48px",
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .rightInfoBox": {
    width: "100%",
  },
  "& .personAvatar": {
    width: "48px",
    height: "48px",
    borderRadius: "24px",
    objectFit: "cover",
  },
  "& .rightInfoTopBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  "& .dateTxt": {
    color: "#B4B4B4",
    lineHeight: "18px",
    fontWeight: 500,
    fontSize: "12px",
  },
  "& .personNameTxt": {
    color: "#000000",
    lineHeight: "21px",
    fontWeight: 500,
    marginRight:"6px",
  },
  "& .chatPeopleContainer": {
    height: "calc(100vh - 260px)",
    overflowY: "scroll",
    scrollbarWidth:"none",
  },
  "& .chatPeopleContainer::-webkit-scrollbar ": {
    display: "none",
  },
  "& .chatMessagesloader1":{
    width:"100px",
    height:"100px"
  },
  "& .loaderBox1":{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  },
  "& .contactListBox":{
    height: "calc(100vh - 320px)",
    overflowY: "scroll",
    scrollbarWidth:"none",
  },
  "& .currentChatBox": {
    borderLeft: "7px solid #F5EA16",
    paddingLeft:"12px",
  },
  "& .righInfoBottomBox": {
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  "& .lastMessageTxt": {
    color: "#000000",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
  },
  "& .noUserFoundTxt" : {
    color: "#b6b6b6",
    textAlign: "center",
    marginTop: "15px"
  },
  "& .attachmentLastMessage": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .mediaIcons":{
    color:"grey",
    marginRight:"5px"
  },
  "& .chipBox": {
    height: "20px",
    width: "20px",
    borderRadius: "50px",
    backgroundColor: "#F5EA16",
    fontSize: "12px",
    textAlign: "center",
  },
  "& .active": {
    color: "#F5EA16",
  },
  "& .activeChatInfoHeader": {
    display: "flex",
    gap: "15px",
  },
  "& .lastSeenTxt": {
    fontWeight: 400,
    fontSize: "12px",
    color: "#000000",
    lineHeight: "18px",
    marginTop: "5px",
  },
  "& .infoHeaderRight": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  "& .headerGap": {
    padding: "10px 50px",
    borderTopRightRadius:"12px",
    borderTopLeftRadius:"12px",
  },
  "& .inputMainContainer": {
    bottom: 0,
    width: "100%",
    position: "absolute",
  },
  "& .imageData": {
    position: "fixed",
    bottom: "80px",
    marginLeft: "35px",
    height: "max-content",
    background:"#F5EA1666",
    padding:"4px",
    "& .MuiAvatar-img":{
      objectFit:"contain"
    },
    "@media(max-width:1150px)":{
      bottom: "115px",
    },
    "@media(max-width:1000px)":{
      bottom: "46px",
    },
  },
  "& .avatarFile":{
    background:"black",
    objectFit:"contain"
  },
  "& .inputInnerContainer": {
    width: "100%",
    display: "flex",
    padding: "1px 20px",
    justifyContent: "center",
    alignItems:"center",
    paddingBottom:"10px",
    gap:"10px"
  },
  "& .sendButton":{
    outline:"none",
    border:"1px solid #55555533",
    borderRadius:"100%",
    padding:"20px",
    backgroundColor:"#F5EA16"
  },
  "& .messageInputField": {
    width: "100%",
    height: "55px",
    borderRadius: "34px",
    border: "2px solid rgba(0, 0, 0, 0.13)",
    background: "#FFF",
    padding: "10px 12px",
  },
  "& .textFieldEndIcons":{
    minWidth:"0px",
    width:"40px",
    height:"30px",
  },
  "& .endIcon":{
    width:"24px",
    height:"24px",
  },
  "& .createGroupBox": {
    borderBottom: "1px solid #CCCCCC",
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    padding: "14px 20px",
  },
  "& .newGroupIcon": {
    width: "48px",
    height: "48px",
    backgroundColor: "#F5EA16",
    borderRadius: "50px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  "& .groupCreateBox": {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    cursor:"pointer",
  },
  "& .newGrpTxt": {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
  },
  "& .groupIcon": {
    width:"24px",
    height:"24px"
  },
  "& .hamburgerBox": {
    border: "1px solid var(--0, #FFF)",
    borderRadius: "10px",
    boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.10)",
  },
  "& .buyerChatHeader": {
    display: "none",
    "@media(max-width:1000px)": {
      display: "block",
    },
    "@media(max-width:1050px)": {
      marginTop: "65px"
    },
  },
  "& .buyerInnerHeaderBox":{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    padding:"10px 20px",
    paddingTop:"20px",
  },
  "& .todayBox":{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop:"10px",
    background:"transparent"
  },
  "& .timeStampMsg":{
    backgroundColor: "#F5EA1666",
    borderRadius: "4px",
    padding:"2px 8px"
  },
  "& .todayHeading": {
    color:"#000",
    fontWeight:500,
    textAlign:"center",
    fontSize:"16px"
  },
  "& .emojiPicker":{
    position: "fixed",
    bottom: "70px",
    marginLeft: "2%",
    marginBottom:"1%"
  }
});
const CustomDrawer = styled(Drawer)({
  width: "min-content",
});
const ChatContainerWrapper = styled(Box)({
  "& .timing": {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px"
  },
  "& .time": {
      backgroundColor: "yellow",
      padding: "5px 10px",
      borderRadius: "7px"
  },
  "& .sender": {
      border: "1px solid black"
  },
  "& .text": {
      display: "flex"
  },
  "& .receivedChats": {
      margin: "16px 20px",
  },
  "& .messageText": {
      marginRight: "60px",
      fontSize:"16px",
      fontWeight:500,
      marginLeft:"12px"
  },
  "& .hyperlinkMsg": {
      color: "#0057ebdb",
  },
  "& .storyHeart": {
      fontSize:"30px",
      fontWeight:500,
      color: "red"
  },
  "& .receivedMyCharts": {
      width: "fit-content",
      maxWidth: "50%",
      borderRadius: "10px",
      padding: "1px 6px",
      backgroundColor:"#FFFFFF",
      border:"1px solid #E6E6E6",
      wordBreak:"break-all",
  },
  "& .receivedMyHeart": {
      width: "fit-content",
      maxWidth: "50%",
      borderRadius: "10px",
      padding: "1px 6px",
      backgroundColor:"#FFFFFF",
      wordBreak:"break-all",
  },
  "& .chatImageBox":{
      width: "fit-content",
      maxWidth: "50%",
      backgroundColor:"transparent",
      border:"1px solid #E6E6E6",
      borderRadius: "10px",
      padding:"6px"
  },
  "& .chatImageStyle":{
      width: '100%',
      height: 'auto',
      maxWidth: '190px',
      maxHeight: '200px',
      objectFit: "contain",
      padding:"4px",
      marginBottom:"6px",
      borderRadius:"8px"
  },
  "& .documentsBox": {
     width: "fit-content",
     maxWidth: "50%",
     backgroundColor: "#FFFFFF",
     border: "1px solid #E6E6E6",
     borderRadius: "10px",
     padding: "6px 10px",
     wordBreak:"break-word",
  },
  "& .innerDocBox":{
     display:"flex",
     justifyContent:"center",
     alignItems:"center",
     gap:"10px",
     
  },
  "& .documentIcon":{
     width:"30px",
     height:"40px"
  },
  "& .documentPdfIcon":{
     width:"30px",
     height:"40px",
     color:"red"
  },
  "& .documentLink":{
    fontSize:"14px",
    color:"#000",
    textDecoration:"none",
    cursor:"pointer"
  },
  "& .documentLink:hover":{
    cursor:"pointer"
  },
  "& .timeAppear": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      height: "5px",
      marginBottom:"5px"
  },
  "& .cartTxt": {
    fontWeight: 400,
    fontSize: "12px"
  },
  "& .visibleBox": {
    paddingLeft:"12px"
  },
  "& .bargainMainLeftContainer": {
    maxWidth: "50%",
    width: "fit-content",
    padding: "1px 6px",
    borderRadius: "10px",
    wordBreak: "break-all",
    backgroundColor: "transparent",
  },
  "& .bargainInnerContainer": {
    borderRadius: "10px",
    width: "100%",
    backgroundColor: "#FFFFFF",
    padding: "1px 6px",
    wordBreak: "break-all",
    border: "1px solid #E6E6E6",
  },
  "& .productSuggestionBox":{
    cursor:"pointer",
    "&:hover":{
      background: "#eaea5c36",
    },
  },
  "& .bargainButtonsBox": {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    gap:"10px",
    borderRadius: "10px",
    marginTop: "10px",
    marginBottom:"10px",
    flexWrap:"wrap",
    marginLeft:"10px"
  },
  "& .acceptChatBargain": {
    borderRadius: "10px",
    width: "100%",
    backgroundColor: "#FFFFFF",
    wordBreak: "break-word",
  },
  "& .acceptBtn": {
    color: "#FFFFFF",
    textTransform: "none",
    fontWeight: 500,
    width: "100%",
    fontSize: "12px",
    background:"#4BB543",
    borderRadius:"50px",
    maxWidth:"114px",
    padding:"11px",
    "&.MuiButton-root.Mui-disabled": {
      background: "grey",
    },
  },
  "& .declineBtn": {
    color: "#FFFFFF",
    textTransform: "none",
    fontWeight: 500,
    width: "100%",
    fontSize: "12px",
    background:"#E46262",
    borderRadius:"50px",
    maxWidth:"114px",
    padding:"11px",
    "&.MuiButton-root.Mui-disabled": {
      background: "grey",
    },
  },
  "& .makeOfferBtn": {
    textTransform: "none",
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: 500,
    width: "100%",
    background:"#66645d",
    borderRadius:"50px",
    maxWidth:"114px",
    padding:"11px",
    "&.MuiButton-root.Mui-disabled": {
      background: "grey",
      color:"white"
    },
  },
  "& .goToCartBtn": {
    outline: "none",
    border:"none",
    background: "#4BB543",
    color: "#FFFFFF",
    borderRadius: "50px",
    fontSize: "12px",
    padding: "11px 16px",
    marginBottom: "4px",
    marginTop:"14px"
  },
  "& .goToCartBtnReq": {
    outline: "none",
    border:"none",
    background: "#4BB543",
    color: "#FFFFFF",
    borderRadius: "50px",
    fontSize: "12px",
    padding: "11px 16px",
    marginBottom: "4px"
  },
  "& .iconChatStyle": {
      display: "flex",
      justifyContent: "flex-end",
      height: "5px"
  },
  "& .times": {
      fontSize: "10px",
      color: "#B4B4B4",
      marginRight:"4px"
  },
  "& .mainChatContainer": {
      height: "calc(100vh - 280px)",
      overflowY: "scroll",
      scrollbarWidth:"none",
      scrollbarColor: "#888 #f1f1f1",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      marginRight:"6px"
  },
  "& .senderMessage": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      margin: " 5px 20px 16px 20px",
  },
  "& .senderMessage1": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      margin: "5px 20px 0px 20px",
  },
  "& .offerAcceptedImg": {
    width: "22px",
    height: "22px",
  },
  "& .offerStatusMsgBox":{
      display:"flex",
      paddingTop:"8px",
      alignItems:"center"
  },
  "& .loaderBox":{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  },
  "& .chatMessagesloader":{
    width:"100px",
    height:"100px"
  },
  "& .tickIcon":{
    width:"13px",
    height:"12px",
    marginBottom:"1px",
    color:"grey"
  },
  "& .imageData": {
    position: "fixed",
    bottom: "80px",
    marginLeft: "50px",
    height: "50px"
  },
  "& .onOrderMsgContainer":{
    display:"flex",
    gap:"10px",
    padding:"10px",
    flexWrap:"wrap",
  },
  "& .innerSuggBox":{
    gap:"10px",
    display:"flex",
    flexWrap:"wrap",
    padding:"10px",
    paddingTop:"0px",
  },
  "& .productImgBox":{
    width:"100px",
    height:"100px",
    minWidth:"100px"
  },
  "& .productImg":{
    width:"100%",
    height:"100%",
    objectFit:"contain"
  },
  "& .rightProductDetailBox":{
    display:"flex",
    flexDirection:"column",
    gap:"4px"
  },
  "& .suggestionDetailBox":{
    display:"flex",
    justifyContent:"center",
  },
  "& .rightHeaderBox":{
    display:"flex",
    alignItems:"center",
    gap:"22px",
  },
  "& .prdTitleTxt": {
    fontSize: "14px",
    fontWeight: 500,
    color: "#444444",
  },
  "& .suggestionTitleTxt": {
    fontSize: "14px",
    fontWeight: 600,
    color: "#444444",
  },
  "& .sugHeadingTxt":{
    fontWeight: 600,
    paddingTop:"6px",
    color: "#444444",
    paddingLeft:"10px",
    fontSize: "14px",
  },
  "& .prdIdTxt": {
    fontSize: "12px",
    fontWeight: 400,
    color: "#55555580"

  },
  "& .variantColorBox":{
    width:"25px",
    border:"1px solid grey",
    borderRadius:"100px",
    height:"25px",
  },
  "& .mainVariantBox":{
    flexWrap:"wrap",
    display:"flex",
    alignItems:"center"
  },
  "& .variantContainer":{
    flexWrap:"wrap",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    marginLeft:"6px",
    gap:"6px",
  },
  "& .variantTxt":{
    fontWeight:500,
    fontSize:"12px",
    height:"14px"
  },
  "& .prdDescriptionTxt": {
    fontSize: "10px",
    fontWeight: 400,
    color: "#444444CC"
  },
  "& .counterDetailTxt": {
    fontSize: "12px",
    fontWeight: 500,
    color: "#444444CC"
  },
  "& .quantityTxt": {
    fontWeight: 500,
    fontSize: "12px"
  },
  "& .counterPriceBold":{
    fontWeight:600,
  },
  "& .expectedDateTxt": {
    fontWeight: 500,
    fontSize: "12px"
  },
});
const CustomModal = styled(Modal)({
  "& .mainModalContainer": {
    position: "absolute",
    top: "50%",
    left: "50%",
    background: "white",
    border: "none",
    height: "517px",
    width: "375px",
    alignItems: "center",
    transform: "translate(-50%, -50%)",
    borderRadius: "14px",
    padding: "30px 40px",
  },
  "& .checkIconBox": {
    width: "40px",
    hight: "40px",
    backgroundColor: "black",
    borderRadius: "100px",
    flexShrink: 0,
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  "& .modalBodyText": {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letteSpacing: "0.862px",
  },
  "& .linkText": {
    color: "#0753FC",
    textDecoration: "underline",
  },
  "& .modalTitleText": {
    color: "#000",
    fontSize: "20px",
    fontWeight: 600,
    letterSpacing: "0.862px",
  },
  "& .ModalBottomBtnBox": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "14px",
    marginTop: "4rem",
  },
  "& .agreeBtn": {
    width: "214px",
    height: "38px",
    backgroundColor: "#F5EA16",
    borderRadius: "50px",
  },
  "& .goBackBtn": {
    width: "214px",
    height: "38px",
    border: "1px solid #F5EA16",
    borderRadius: "50px",
  },
  "& .termsBtnTxt":{
    fontWeight:700,
    fontSize:"12px"
  },
  "& .modalInnerBottomBox": {
    display: "flex",
    height: "40px",
    gap: "15px",
    margin: "20px 0px",
  },
  "& .modalMiddleBox": {
    marginTop: "26px",
  },
  "& .modalHeader": {
    marginLeft: "18px",
    marginTop: "20px",
  },
  "& .privacyTxt": {
    marginTop: "6px",
  },
});

const ImageViewCustomModal = styled(Modal)({
  "& .imageViewMainContainer": {
    position: "absolute",
    top: "50%",
    left: "50%",
    background: "transparent",
    border: "none",
    maxWidth: "50vw",  
    width: "30%",  
    transform: "translate(-50%, -50%)",
    padding: "10px",
    "@media(max-width:600px)":{
      width:"50%",
    },
    maxHeight:"550px",
    display:"flex",
  },
  "& .imageView":{
    width:"100%",

    objectFit:"contain"
  }
});

const OfferModalWrapper = styled(Modal)({
  "& .mainContainer": {
    top: "50%",
    position: "absolute",
    border: "none",
    left: "50%",
    maxWidth: "482px",
    width: "100%",
    background: "white",
    padding: "24px",
    transform: "translate(-50%, -50%)",
    "@media(max-width:600px)": {
      width: "50%",
    },
    minHeight: "308px",
    height: "100%",
    borderRadius: "20px",
    maxHeight: "310px",
  },
  "& .textInputField": {
    borderRadius: "10px",
    width: "100%",
    minHeight:"56px",
    marginTop: "26px",
    "& .Mui-focused":  {
      "& fieldset": {
        border: "1px solid black"
      }
    }
  },
  "& .headingCounter": {
    fontSize: "18px",
    color: "#444444",
    fontWeight:500,
  },
  "& .innerBox": {
    flexDirection: "column",
    display: "flex",
  },
  "& .errTxt":{
    color:"red",
    fontSize:"12px",
    marginTop:"2px"
  },
  "& .sendButton": {
    color: "#555555",
    fontWeight: 500,
    fontSize: "14px",
    textTransform: "capitalize",
    background: "#F5EA16",
    borderRadius: "20px",
    width: "100%",
    minHeight: "47px",
    marginTop: "20px",
    marginBottom: "12px",
  },
  '& .inputFieldBox': {
    '& .MuiFormLabel-root': {
      fontFamily: 'Poppins-Regular !important',
      fontSize: '16px',
      lineHeight: '18px',
      color:"#44444480"
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#ffffff',
      opacity: 0.5,
    },
    '& .MuiInputBase-input::focus': {
      border:"1px solid grey"
    },
    '& .BusinessLable.textWhite': {
      fontSize: '16px',
      lineHeight: '15px',
      color: "#ffffff"
    },
    "& .MuiInputBase-input": {
      borderRadius: "20px"
    }
  },
  "& .reasonTxt": {
    fontSize: "16px",
    fontWeight: 400,
    color: "#555555B2",
    marginTop: "14px",
    marginBottom: "10px"
  },
  "& .infoIcon": {
    marginRight: "4px"
  }
});

const BuyerDeclineModal = styled(Modal)({
  "& .rejectModalContainer": {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "100%",
    border: "none",
    minxHeight: "620px",
    maxWidth: "482px",
    transform: "translate(-50%, -50%)",
    padding: "10px 20px",
    borderRadius: "8px",
    background: "white",
  },
  "& .radioButtonBox": {
    borderRadius: "10px",
    border: "1px solid #4444444D",
    marginBottom: "10px",
    padding: "8px",
  },
  "& .rejectModalTitle": {
    color: "#444444",
    fontSize: "18px",
    marginTop: "12px",
    fontWeight: 500,
  },
  "& .informationBox": {
    fontWeight: 400,
    fontSize: "18px",
    marginTop: "14px",
    color: "#555555B2",
    marginBottom: "10px"
  },
  "& .submitDeclineOrderBtn": {
    fontWeight: 500,
    color: "#555555",
    background: "#F5EA16",
    fontSize: "14px",
    textTransform: "capitalize",
    width:"100%",
    borderRadius: "20px",
    marginTop:"20px",
    marginBottom:"12px",
    minHeight:"47px",
  },
  "& .errTxt":{
    fontSize: "12px",
    marginTop: "6px",
    color: "red",
  },
  "& .rejectReasonInputField":{
    fontSize:"14px",
    borderRadius:"10px",
    marginTop:"6px",
    color: "#44444480",
  },
  "& .infoIcon":{
    marginRight:"4px"
  }
});

const RadioFormControlLabel = styled(FormControlLabel)({
  fontSize:"14px",
  color :"#444444",
  fontWeight: 500,
  "& .MuiRadio-root": {
    color: "#F5EA16", 
  },
  "& .Mui-checked": {
    color: "#F5EA16", 
  },
});
// Customizable Area End
