import React from "react";
// Customizable Area Start
import {
    Box,
    styled,
    IconButton,
    Typography,
    Avatar,
    Button,
    CircularProgress,
    TextField,
    Modal,
    Snackbar,
} from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import { instagramLogo } from "./assets";
import { AddGrayIc } from "../../CustomisableUserProfiles/src/assets";
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import PostCard from "../../../components/src/PostCard.web";
import SellerFeedController, { configJSON, Props, PostDataPayloadType } from "./SellerFeedController.web";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import { crossIcon, hamburgerIcon, searchModalIcon } from "../../LiveStreaming/src/assets";
import { calendarIc, cancelIcon, notificationIc, profileImg } from "../../landingpage/src/assets";
import { conditionalRenderer } from "../../../components/src/utils";
import StoryListing from "../../../components/src/CustomStoryListing.web";
import {  
    FacebookShareButton,
    WhatsappShareButton,
    FacebookIcon,
    WhatsappIcon,
    TelegramIcon,
    TelegramShareButton
 } from "react-share";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Alert from '@material-ui/lab/Alert';
import ContentLoader from "react-content-loader";
import Pagination from '@material-ui/lab/Pagination';
import Lottie from "lottie-react";
import { flixooLoader } from "./assets";
// Customizable Area End

export default class SellerFeed extends SellerFeedController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderSuggeSectionSeller = () => {
        return (
            <>
                <Box className="suggestionMainBoxContainer">
                    <Typography className="suggestionHeading">Suggested for you</Typography>
                    <Pagination siblingCount={0} data-test-id="paginationTestID" count={this.state.suggetionTotalPage} onChange={this.handlePageSellerChange} />
                    <Box className="suggestionMainContainer">
                        {this.state.suggetionSeller.map((items, itemIndex) => (
                            <Box className="suggUserInfoBox" key={itemIndex}>
                                <Box className="sugLeftBox">
                                    <Box className="avatarSuggestionBox">
                                        <Avatar src={items.attributes.account.profile_url} className="suggestionAvatar" />
                                    </Box>
                                    <Box className="suggestionInfoBox">
                                        <Typography className="userNameText">{items.attributes.account.user_name}</Typography>
                                        <Typography className="userNameFull">{items.attributes.account.full_name}</Typography>
                                    </Box>
                                </Box>
                                <Box className="followBox">
                                    <Button data-test-id="suggetionFollowTestId" onClick={() => this.handleFollow(items.attributes.account.id)} className={items.attributes.account.is_following ? "followBtn followBtnBack"  : "followBtn"}>{items.attributes.account.is_following ? "Following" : "Follow"}</Button>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </>
        )
    }

    parseAndHighlightMentions = (comment: string ) => {
        const mentionRegex = /(@\w+)/g;
        const parts = (comment || '').split(mentionRegex);
    
        return parts.map((part, index) => {
          if (part.startsWith('@')) {
            const username = part.slice(1);
              return (
                <span key={index} style={{color: 'rgb(0, 55, 107)' }} className="mention" data-username={username}>
                  {part}
                </span>
              );
          }
          // Return as plain text
          return <span key={index}>{part}</span>;
        });
      };

    renderSuggestions() {
        const { isSuggestionVisible, suggestionsTagList } = this.state;
    
        if (!isSuggestionVisible) return null;
    
        return (
            <div
                className={`suggestion-box ${this.state.suggestionPosition === 'above' ? 'above-input' : 'below-input'
                    }`}
            >
                {suggestionsTagList.map((suggestion,index) => (
                    <div
                        data-test-id="tagItemTestID"
                        key={index}
                        onClick={() => this.handleTagSelection(suggestion.user_name)}
                        className="suggestion-item"
                    >
                        <Avatar
                            alt="User Avatar"
                            className="avatar"
                            src={suggestion.profile_photo}
                        />
                        <p>
                            {suggestion.user_name}
                        </p>
                    </div>
                ))}
            </div>
        );
    }
    
    renderStory = () => {
        return (
            <div className="storyMainContainer" ref={this.storyMainContainerRef}>
                {this.state.showLeftScrollBtn && <IconButton data-test-id="prevBtnTestID" onClick={this.scrollStoryBoxLeft} className="arrowPrev">
                    <ArrowBackIos />
                </IconButton>}
                <Box className="feedButton" data-test-id={`openAddStoryTestID`} onClick={() => this.handleAddStory()}>
                    <Box className="storyBorderSeen StoryBoxBorder">
                        <img className="story" data-test-id={`imgUserTestID`} src={AddGrayIc} />
                    </Box>
                    Add Story
                </Box>
                
            {this.state.allStoryHeader.map((item, userIndex) => (
                <>
                    {
                        this.state.loader ?
                            <ContentLoader
                                height={100}
                                width={100}
                                speed={1}
                                viewBox="0 0 90 90"
                            >
                                <circle cx="40" cy="40" r="40" />
                            </ContentLoader>
                            :
                            <Box className="feedButton" data-test-id={`openStoryTestID${userIndex}`} key={userIndex} onClick={() => this.handleClickOpen(userIndex)}>
                                <Box className={item.attributes.seen ? "storyBorderSeen StoryBoxBorder" : "StoryBoxBorder"}>
                                    <img className="story" data-test-id={`imgUserTestID${userIndex}`} src={item.attributes.creator_details.profile_url ? item.attributes.creator_details.profile_url : profileImg} />
                                </Box>
                            </Box>
                    }
                </>
               
            ))}
                {this.state.showRightScrollBtn && <IconButton
                    onClick={this.scrollStoryBoxRight}
                data-test-id="nextBtnTestID"
                className="arrowNext"
            >
                <ArrowForwardIos />
                </IconButton>}
            </div>
        );
    };

    renderStoryModal = () => {
        return (
            <StoryListing
                data-test-id="storyModalTestID"
                allStoryHeader={this.state.allStoryHeader}
                storyMuted={this.state.storyMuted}
                toggleMute={this.toggleMute}
                calculateHours={this.calculateHours}
                likeStory={this.likeStory}
                setSwiperRef={this.setSwiperRef}
                currentStoryIndex={this.state.currentStory}
                userStory={this.state.userStory}
                showNextButton={this.showNextButton}
                showPrevButton={this.showPreviousButton}
                changeStory={this.changeStory}
                instaModal={this.state.instaModal}
                instaModalIndex={this.state.instaModalIndex}
                handleClose={this.handleClose}
                handleNext={this.handleNext}
                handlePrev={this.handlePrev}
                handlePrevNext={this.handlePrevNext}
                userID={this.state.BuyerDataId} 
                storyId={this.handleStoryId}
                commentMessage={this.handleMessage}
                isMessageSent={this.state.channelUrl}
            />
        );
    };
    
    renderShareSellerModalView = () => {
        const { shareUrl, isShareModal } = this.state;
        return (
            <ShareModal open={isShareModal} onClose={this.toggleShareModal}>
                <Box className="mainShareModalContainer">
                    <Box className="shareModalBox">
                        <Typography className="headingTxt">{configJSON.shareTxt}</Typography>
                        <Box className="cancelIconBox">
                            <IconButton onClick={this.toggleShareModal}><img src={cancelIcon} /></IconButton>
                        </Box>
                    </Box>
                    <Box className="shareContainer">
                        <Box className="shareBtnBox">
                            <FacebookShareButton className="socialBtn" url={shareUrl} quote="Check out this Flixoo Live Post!!">
                                <FacebookIcon size={50} round={true} />
                            </FacebookShareButton>
                            <Typography className="socialBtnLabel">{configJSON.facebookTxt}</Typography>
                        </Box>

                        <Box className="shareBtnBox">
                            <WhatsappShareButton className="socialBtn" url={shareUrl} title="Check out this Flixoo Live Post!!">
                                <WhatsappIcon size={50} round={true} />
                            </WhatsappShareButton>
                            <Typography className="socialBtnLabel">{configJSON.whatsappTxt}</Typography>
                        </Box>

                        <Box className="shareBtnBox">
                            <IconButton className="customSocialButton">
                                <img
                                    src={instagramLogo}
                                    alt="Instagram"
                                    className="instagramIcon"
                                />
                            </IconButton>
                            <Typography className="socialBtnLabel">{configJSON.instagramTxt}</Typography>
                        </Box>

                        <Box className="shareBtnBox">
                            <TelegramShareButton className="socialBtn" url={shareUrl} title="Check out this Flixoo Live Post!!">
                                <TelegramIcon size={50} round={true} />
                            </TelegramShareButton>
                            <Typography className="socialBtnLabel">{configJSON.telegramTxt}</Typography>
                        </Box>

                        <Box className="shareBtnBox">
                            <IconButton className="customSocialButton" onClick={this.handleCopyShareLink}>
                                <FileCopyOutlinedIcon className="copyIcon" />
                            </IconButton>
                            <Typography className="socialBtnLabel">{configJSON.copyLink}</Typography>
                        </Box>
                    </Box>
                </Box>
            </ShareModal>
        )
    };

    renderCommentContainer = () => {
        return (
            <CommentContainer>
                <Avatar
                    alt="User Avatar"
                    src={this.state.profileImage}
                    className="avatar"
                />
                <div style={{ position: "relative" }}>
                    <TextField
                        className="input"
                        onKeyDown={this.handleKeyDown}
                        onChange={this.handleInputChange}
                        fullWidth
                        value={this.state.addComment}
                        data-test-id="changeValueTestId"
                        variant="outlined"
                        placeholder="Add a Comment"
                    />
                    {this.renderSuggestions()}
                </div>
                <Button
                    onClick={this.state.setReplyId === "" ? this.postCommentApiCall : this.postCommentOnCommentApiCall}
                    variant="outlined"
                    className={this.state.addComment.length === 0 || this.state.addComment.trim() === '' || this.state.addComment.trim() === '@' ? "desibleButton" : "postButton"}
                    disabled={!this.state.addComment || this.state.addComment.trim() === '' || this.state.addComment.trim() === '@'}
                >
                    POST
                </Button>
            </CommentContainer>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <SellerFeedWrapper>
                <Loader loading={this.state.isAppLoader} />
                <Box className="mainContainer">
                    <Box>
                        <div className="dashboard-outer">
                            <div className="dashboard-inner">
                                <SellerSidebar
                                    navigation={""}
                                    id={""}
                                    classes={undefined}
                                    isOpen={this.state.isSideBar}
                                    data-test-id="sellerSidebar"
                                    activetabName="feed"
                                    closeModal={this.toggleSideBar}
                                />
                            </div>
                        </div>
                    </Box>
                    <Box className="mainRightContainer">
                    {this.renderShareSellerModalView()}
                    <Snackbar data-test-id="snackbarCompTestId" open={this.state.isSnackBarOpen} autoHideDuration={6000} onClose={this.handleSnackbarToggles} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} >
                        <Alert severity={this.state.snackSeverity} onClose={this.handleSnackbarToggles}>
                            {this.state.snackMessage}
                        </Alert>
                    </Snackbar>
                        <Box>
                            <Box className={"spaceBetween"}>
                                <Box className={"backBtnNavigation"} m={0}>
                                    <Typography className={"mainPageTitle"} data-test-id="inventoryTxtId">{configJSON.feedTxt}</Typography>
                                </Box>
                                <Box component='span' className={"flexContainer"}>
                                    <ModalSearch className="inventorySearch">
                                        <img src={searchModalIcon} alt="search" />
                                        <input type="text" placeholder={"Search for users..."} data-test-id="inventorySearchTestID" />
                                    </ModalSearch>
                                    <Box component="span" className={"headerUpIcons"}><img src={calendarIc} alt="calendarIcon" /></Box>
                                    <Box component="span" className={"headerUpIcons"}><img src={notificationIc} alt="bellIcon" /></Box>
                                    <Box className="hideSideBarHamburgerSection headerUpIcons">
                                        <IconButton data-test-id="hamburger-btn" onClick={() => this.toggleSideBar()}>
                                            <img src={hamburgerIcon} alt="hamburger" />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="postSuggestionFlex">
                            <Box className="postMiddleContainer">
                                {this.renderStory()}
                                
                            <Box>
                                {this.renderStoryModal()}
                                {this.state.isPostloads && <Typography style={{ textAlign: "center", color: "#444444", fontSize: "16px", marginTop:"40px" }}>"Oops! There are no posts to display at the moment."</Typography>}
                                <PostListContainer>
                                    <div className="postMainBox" ref={this.sellerPostContainerRef}>
                                        <Box className="postInnerContainer">
                                            {this.state.sellerPostData.map((postData: PostDataPayloadType, postIndex: number) => {
                                                return (
                                                    <PostCard
                                                        item={postData}
                                                        postKeyId={postIndex}
                                                        postId={postData.id}
                                                        handleToggleComment={this.handleToggleComment}
                                                        handleToggleLikePost={this.handlePostLikeUnlike}
                                                        data-test-id={`postCardComponentTestId${postIndex}`}
                                                        clickFollowButton={this.handleCreatorFollow}
                                                        handlePostShareToggle={this.handlePostShareToggle}
                                                        clickTageListButton = {this.clickTageListButton}
                                                        closeTagUserList = {this.closeTagUserList}
                                                        varientProductClick = {this.handleClickOfEachProductVarient}
                                                    />
                                                )
                                            })}
                                            {(this.state.isFeedLoader && this.state.hasMorePosts && !this.state.noMorePosts) ? <div className="circularContainer"><Lottie animationData={flixooLoader} loop={true} /></div> : ""}
                                            {(this.state.noMorePosts) && <Typography style={{color:"#444444"}}>{this.state.textPost}</Typography>}
                                        </Box>
                                    </div>
                                </PostListContainer>
                                </Box>
                                
                            </Box>
                            {
                                this.state.showCommentBox === "commentbox" ?
                                    <Box data-test-id='test1' className="suggestionCommentBox">
                                        <Box className="heade-txt-comment" >
                                            <Typography className="suggestionForTxt">Comments</Typography>
                                            <img
                                                style={{ cursor: "pointer" }}
                                                src={crossIcon}
                                                alt="Close"
                                                onClick={this.closeCommentSeller}
                                            />
                                        </Box>
                                        <Box data-test-id='test3' className="suggestionMainCommentContainer">
                                            {this.state.listCommentData.data.map((item, itemIndex) => (
                                                <Box id="test4" className="suggestedUserBox" key={itemIndex}>
                                                    <Box className="suggestionLeftBoxForComment">
                                                        <Box data-test-id="test5" className="avatarSuggestionBox">
                                                            <Avatar src={item?.attributes?.details?.profile_photo} className="suggestionAvatar" />
                                                        </Box>
                                                        <Box id="test6" className="suggestionInfoBox">
                                                            <Typography className="userNameTxt foruser">{item?.attributes?.details?.full_name}</Typography>
                                                            <Typography data-test-id="test7" className="userFullNameTxt">
                                                                {this.parseAndHighlightMentions(
                                                                    String(item?.attributes?.details?.comment || '')
                                                                )}
                                                            </Typography>
                                                            <Box className="suggestionLinkComment changesclass">
                                                                <Typography id="test8" className="userNameTxt">{item?.attributes?.details?.created_at}</Typography>
                                                                <Typography onClick={() => this.likeCommentApiCall(item.id)}
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        color: conditionalRenderer((item.attributes.details.other_like), 'rgb(0, 55, 107)', '#44444480')
                                                                    }}
                                                                    data-test-id="test9"
                                                                    className="userFullNameTxt">
                                                                    {item?.attributes?.details?.like_count} Likes
                                                                </Typography>
                                                                <Typography className="userFullNameTxt testcases" style={{ cursor: 'pointer', color: item.id === this.state.setReplyId ? "rgb(245, 234, 22)" : "" }} data-test-id="oneTapReply" onClick={() => this.clickOnReplyForGetId(item.id)}>Reply</Typography>
                                                            </Box>

                                                            {item?.attributes?.details?.replies_count > 0 &&
                                                                <ReplyContainer>
                                                                    <div id="test10" className="blankLine"></div>
                                                                    <Typography  data-test-id='clickReply' className="text" variant="body2" onClick={() => this.viewDetilsExpandable(parseInt(item?.id))}>
                                                                        {!this.state.clickReply.includes(parseInt(item.id)) ? "View" : "Hide"} {item?.attributes?.details?.replies_count} replies
                                                                    </Typography>
                                                                </ReplyContainer>}

                                                            {
                                                                this.state.clickReply.includes(parseInt(item.id)) &&
                                                                item.attributes.details.sub_post_comments.map((value, itemIndex) => (
                                                                    <Box className="suggestionLeftBoxForComment">
                                                                        <Box className="avatarSuggestionBox">
                                                                            <Avatar src={value.profile_photo} className="suggestionAvatar" />
                                                                        </Box>
                                                                        <Box className="suggestionInfoBox">
                                                                            <Typography data-test-id='test21' className="userNameTxt">{value.full_name}</Typography>
                                                                            <Typography id='test22' className="userFullNameTxt">
                                                                                {this.parseAndHighlightMentions(
                                                                                    String(value.comment || '')
                                                                                )}
                                                                            </Typography>

                                                                            <Box className="suggestionLinkComment adddedclass">
                                                                                <Typography data-test-id="test23" className="userNameTxt" style={{ marginTop: '4px' }}>{value.created_at}</Typography>
                                                                                <Typography
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                        color: conditionalRenderer((value.other_like), 'rgb(0, 55, 107)', '#44444480')
                                                                                    }}
                                                                                    data-test-id="sallerReplyId" onClick={() => this.likeReplyCommentApiCall(value?.sub_comment_id)}
                                                                                    id="test24" className="userFullNameTxt">{value.like_count} Likes
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                ))
                                                            }
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                        {this.renderCommentContainer()}
                                    </Box> :
                                    this.renderSuggeSectionSeller()
                            }
                        </Box>
                    </Box>
                </Box>
            </SellerFeedWrapper>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const SellerFeedWrapper = styled(Box)({
    marginTop: "104px",
    "& .suggestion-box": {
        position: "absolute",
        background: "white",
        border: "1px solid #ddd",
        borderRadius: "4px",
        zIndex: 1000,
        width: "100%",
        bottom: "100%",
        marginBottom: "4px",
    },
    "& .suggestion-box.below-input": {
        top: "100%",
        marginTop: "8px",
    },
    "& .suggestion-box.above-input": {
        bottom: "100%",
        marginBottom: "8px",
    },
    "& .suggestion-item": {
        padding: "8px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        gap: "8px"
    },
    "& .suggestion-item:hover": {
        backgroundColor: "#f0f0f0",
    },
    "@media(max-width: 1150px)": {
        marginTop: "71px",
    },
    "& .mainContainer": {
        display: "flex",
        width:"100%"
    },
    "& .mainRightContainer":{
        width:"100%",
        display:"flex",
        flexDirection:"column",
        padding:"24px"
    },
    "& .postSuggestionFlex":{
        display:"flex",
        width:"100%"
    },
    "& .postMiddleContainer": {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflowX: "hidden",
    },
    "& .suggestionMainBoxContainer": {
        width: "100%",
        borderLeft: "1px solid #44444433",
        marginTop: "30px",
        maxWidth: "410px",
        paddingRight: "60px",
        paddingLeft: "24px",
        "@media(max-width:992px)": {
            display: "none"
        },
    },
    "& .storyMainContainer": {
        width: "100%",
        display: "flex",
        overflowX: "scroll",
        padding: "0 20px",
        boxSizing: "border-box",
        gap: "24px",
        marginTop: "40px",
        scrollBehavior: "smooth",
        scrollbarWidth:"none",
        paddingBottom: "12px",
        marginBottom: "20px",
        "&:hover": {
            "& .arrowPrev, & .arrowNext": {
                display: "flex"
            }
        },
        "&::-webkit-scrollbar": {
            height: "4px",
        },
        "&::-webkit-scrollbar-track": {
            background: "#D9D9D955",
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            background: "#D9D9D9",
        },
    },
    "& .feedButton": {
        cursor: "pointer"
    },
    "& .StoryBoxBorder": {
        height: "74px",
        width: "74px",
        display: "flex",
        borderRadius: "60px",
        background: "linear-gradient(130.56deg, #EB3AA7 9.42%, #6649D9 87%)",
        padding: "2.46px",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .storyBorderSeen": {
        background: "grey !important"
    },
    "& .story": {
        padding: "4.07px",
        borderRadius: "60px",
        width: "70px",
        height: "70px",
        background: "#FFFFFF",
    },
    "& .arrowPrev, & .arrowNext": {
        position: "absolute",
        display: "none",
        top: "75px",
        transform: "translateY(-50%)",
        zIndex: 1,
        backgroundColor: "#FFFFFF",
        borderRadius: "50%",
        width: "28px",
        height: "28px",
        alignItems: "center",
        justifyContent: "center",
        padding: "4px"
    },
    "& .arrowPrev": {
        left: 10
    },
    "& .arrowNext": {
        right: 10
    },
    "& .suggestionHeading": {
        fontSize: "16px",
        color: "#000000",
        fontWeight: 500,
    },
    "& .userNameText": {
        fontWeight: 500,
        fontSize: "14px",
        color: "#444444",
    },
    "& .userNameFull": {
        fontWeight: 400,
        color: "#44444480",
        marginTop: "4px",
        fontSize: "14px",
    },
    "& .suggUserInfoBox": {
        display: "flex",
        alignItems: "center",
        marginTop: "16px",
        justifyContent: "space-between",
    },
    "& .sugLeftBox": {
        gap: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .followBtn": {
        border: "1px solid #F5EA16",
        textTransform: "none",
        padding: "6px 16px",
        borderRadius: "50px",
    },
    "& .followBtnBack": {
        background:"#f5ea16"
    },
    "& .hideSideBarHamburgerSection": {
        display: "none",
        "@media(max-width: 991px)": {
            display: "block",
        }
    },
    "& .headerUpIcons": {
        width: '48px',
        borderRadius: '10px',
        height: '48px',
        background: 'var(--0, #FFF)',
        border: '1px solid var(--0, #FFF)',
        display: "flex",
        boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.10)',
        justifyContent: "center",
        alignItems: "center",
        flexShrink: 0,
        margin: "0em 0.5em",
    },
    "& .mainPageTitle": {
        fontSize: '20px',
        color: 'rgba(68, 68, 68, 0.80)',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontStyle: 'normal',
        marginLeft: "0.5em",
        lineHeight: 'normal',
    },
    "& .spaceBetween": {
        justifyContent: "space-between",
        display: "flex",
    },
    "& .backBtnNavigation": {
        alignItems: "center",
        display: "flex",
    },
    "& .flexContainer": {
        display: "flex"
    },
    "& .heade-txt-comment": {
        justifyContent:"space-between",
        alignItems: "center",
        display:"flex",
    },
    "& .suggestionCommentBox": {
        borderLeft: "1px solid #44444433",
        width: "100%",
        maxWidth:"440px",
        marginTop: "30px",
        paddingLeft: "24px",
        paddingRight: "60px",
        "@media(max-width:800px)": {
            display:"none"
        },
    },
    "& .suggestedUserBox":{
        margin: '10px 0px'
    },
    "& .suggestionMainCommentContainer":{
        overflowY: 'scroll',
        marginTop: '10px'
    },
    "& .suggestionLeftBoxForComment":{
        display: "flex",
        gap: "12px",
        // justifyContent: "center",
        alignItems: "start",
        margin: '10px 0px'
    },
    "& .suggestionLinkComment":{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        gap: '15px'
    }
});
const PostListContainer = styled(Box)({
    "& .postMainBox": {
        overflowY: "auto",
        scrollBehavior: "smooth",
        height: "calc(100vh - 152px)",
        width:"100%",
    },
    "& .postInnerContainer": {
        width: "100%",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    "& .circularContainer": {
        height: "80px",
        width: "80px",
        position: "absolute",
        left: "50%",
        top: "93%",
        transform: "translate(-50%, -50%)",
      },
});

const ModalSearch = styled(Box)({
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "12px",
    gap: "12px",
    borderRadius: "12px",
    boxShadow: "0 0 25px rgba(0,0,0,0.2)",
    "&.inventorySearch": {
        margin: "0 0.5em",
        width: "482px",
        height: "48px",
        "@media(max-width: 1200px)": {
            width: "100%",
            maxWidth: "460px",
        }
    },
    "& img": {
        cursor: "pointer"
    },
    "& input": {
        border: "none",
        outline: "none",
        flex: 1
    },
    "& .inventoryFilter": {
        borderLeft: "1px solid rgba(68, 68, 68, 0.1)",
        paddingLeft: "12px"
    }
});

const CommentContainer = styled(Box)({
    marginRight: '10px',
    marginTop: '20px',
    border: '1px solid black',
    borderRadius: '50px',
    maxWidth: '500px',
    alignItems: 'center',
    display: 'flex',
    "& .avatar":{
        marginLeft: '10px',
    },
    "& .input": {
        flex: 1,
        '& .MuiOutlinedInput-root': {
            borderColor: 'transparent',
            borderRadius: '30px',
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        },
    },
    "& .postButton": {
        borderRadius: '30px',
        border: 'none',
        fontWeight: 'bold',
        padding: '6px 16px',
        marginLeft: '10px',
        color: "blue"
      },
    "& .desibleButton": {
        borderRadius: '30px',
        border: 'none',
        fontWeight: 'bold',
        padding: '6px 16px',
        marginLeft: '10px',
        color: "default"
    }
})

const ReplyContainer = styled(Box)({
    alignItems: 'center',
    marginTop: '5px',
    display: 'flex',
    '& .blankLine': {
        marginRight: '10px',
        height: '3px',
        background: '#B5B3A76E',
        width: '50px',
    },
    '& .text': {
        cursor: 'pointer', 
        color: '#1E1A00',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
})

const ShareModal = styled(Modal)({
    "& .mainShareModalContainer": {
        position: "absolute",
        top: "50%",
        left: "50%",
        border: "none",
        transform: "translate(-50%, -50%)",
        padding: "10px 20px",
        borderRadius: "24px",
        background: "white",
        width: "100%",
        maxWidth: "fit-content",
    },
    "& .shareBtnBox": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .shareContainer": {
        display: "flex",
        gap: "25px",
        padding: "30px",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        paddingTop:"14px"
    },
    "& .shareModalBox": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 2px",
        marginTop: "10px"
    },
    "& .headingTxt": {
        fontSize: "18px",
        fontWeight: 600
    },
    "& .socialBtn": {
        padding: "12px !important"
    },
    "& .customSocialButton":{
        border: 'none', 
        outline: "none", 
        background: "transparent" 
    },
    "& .copyIcon":{
        width:"50px",
        height:"50px"
    },
    "& .instagramIcon":{
        width: '50px', 
        height: '50px', 
        borderRadius: '50%' 
    },
    "& .socialBtnLabel":{
        color:"grey",
        fontWeight:600,
        fontSize:"16px"
    }
});
// Customizable Area End
