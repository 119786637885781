Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.exampleToken = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTE0OSwiZXhwIjoxNzAxMTQ1OTc0LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.4aiusX_ghapUzcEu6qvLsXKwFrTFMsAODYtOSJeio796wKzM9r6ZkpypLJJhiuN3_1lC5d7SKK0n0JCo3ccVGg"
exports.exampleBuyerToken = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTE0OSwiZXhwIjoxNzAxMTQ1OTc0LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.4aiusX_ghapUzcEu6qvLsXKwFrTFMsAODYtOSJeio796wKzM9r6ZkpypLJJhiuN3_1lC5d7SKK0n0JCo3ccVGg"
exports.exampleSellerEmail = 'seller_28@yopmail.com'
exports.exampleSellerPassword = 'password123'
exports.exampleBuyerEmail = 'gopal82@yopmail.com'
exports.exampleBuyerPassword = 'password123'
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.httpDeleteMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProductDescription";
exports.labelBodyText = "ProductDescription Body";
exports.baseAPIEnpoint = "https://whatnotinlikeappphase1-285840-ruby.b285840.dev.eastus.az.svc.builder.cafe";
exports.apiEndPointGetProductDescription = "https://whatnotinlikeappphase1-285840-ruby.b285840.dev.eastus.az.svc.builder.cafe/catalogue/catalogues";
exports.apiEndPointGetRelatedCatalogue = "https://whatnotinlikeappphase1-285840-ruby.b285840.dev.eastus.az.svc.builder.cafe/bx_block_catalogue/catalogues/related_catalogue"
exports.btnExampleTitle = "CLICK ME";
exports.favouriteItemEndPoint = "bx_block_favourite/favourites";
exports.getTopSearchEndPoint = 'bx_block_advanced_search/search?page=1&per_page=10&type=top&'
exports.sizeChart=  "Size Chart"
exports.inRegular = "IN REGULAR"
exports.pincodeRegx = /^[0-9\b]+$/
exports.makeOfferTxt = "Make offer";
exports.sendMessageText = "Send Message";
exports.requestMessageTxt = "Send Request";
exports.percent1 = "5% less";
exports.percent2 = "10% less";
exports.percent3 = "15% less";
exports.basePriceTxt = "Base price:";
exports.makeOfferApiEndPoint = "bx_block_productdescription/bargain_orders";
exports.offerValueRegex = /^(?!0\d)\d*(\.\d{0,4})?$/;
exports.followSellar= "bx_block_followers/follows";
exports.createOnOrderEndPoint="bx_block_productdescription/on_order_requests";
exports.orderDetailsTxt = "Order details";
exports.selectedItemTxt = "Selected Item";
exports.onOrderTagText="On order";
exports.detailsTxt = "Details*";
exports.attachmentTxt = "Attachments";
exports.uploadImgTxt = "upload image";
exports.maxMbTxt = "max 5 mb";
exports.expectedDatetxt = "Expected delivery date";
exports.requestSuccessTxt="Request successful";
exports.modalBodyTxt="You will be notified  on your registered email when request will be accepted";
exports.goToChatTxt="Go to chat";
exports.submitTxt = "Submit";
// Customizable Area End