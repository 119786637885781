import { StoryMedia } from "../../blocks/postcreation/src/SellerFeedController.web";
import { UserMessageCreateParams, FileMessageCreateParams } from "@sendbird/chat/message";
import { GroupChannel, GroupChannelFilter, GroupChannelListOrder } from "@sendbird/chat/groupChannel";
type Alignment = "left" | "right" | "center" | "justify";

export const getFileFromUrl = async (url: string): Promise<File> => {
    const response = await fetch(url);
    const blob = await response.blob();
    const fileName = url.split('/').pop() || 'defaultFileName';
    return new File([blob], fileName);
};

export const loadChannels = async (instance: any, channelURL: string, textMessage: string, media: StoryMedia) => {
    const groupChannelFilter = new GroupChannelFilter();

    groupChannelFilter.includeEmpty = true;
    const collection = instance.sendBird?.groupChannel.createGroupChannelCollection({
        filter: groupChannelFilter,
        order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    });
    const channels = await collection?.loadMore();
    if (channels && channels.length > 0) {
        let channelIndex = channels.findIndex((channel: GroupChannel) => channel.url === channelURL);
        sendStoryImage(textMessage, channels[channelIndex], media);
    }
};

export const sendStoryImage = async (textMessage: string, channel: GroupChannel, media: StoryMedia) => {
    if (!channel) return;

    if (!media) return;

    let fileUrl = media.url;

    if (!fileUrl) return;
    if (media.type === 'video') {
        fileUrl = media.thumbnail || media.url;
    }
    try {
        const file = await getFileFromUrl(fileUrl);
        const fileMessageParams: FileMessageCreateParams = {
            file: file,
            customType: "image",
            data: JSON.stringify({ delivered: false, read: false, message: "" }),
        };

        channel?.sendFileMessage(fileMessageParams).onSucceeded(async (sentMessage) => {
            if (sentMessage) {
                const params = {
                    customType: 'image',
                    data: JSON.stringify({ delivered: true, read: false, message: "" }),
                };

                try {
                    await channel.updateFileMessage(sentMessage.messageId, params);
                    sendMessageSendBird(textMessage, channel);
                } catch (error) {
                    console.error(error);
                }

                sentMessage['data'] = JSON.stringify({ delivered: true, read: false, message: "" });
            }
        });
    } catch (error) {
        console.error("Error fetching the file:", error);
    }
};

export const sendMessageSendBird = async (textMessage: string, channel: GroupChannel) => {
    if (textMessage.trim() === '') return;
    try {
        const msgParams: UserMessageCreateParams = {
            message: textMessage,
            data: JSON.stringify({ delivered: false, read: false }),
        };
        channel?.sendUserMessage(msgParams).onSucceeded(async (sentMessage) => {
            if (sentMessage) {
                const sendbirdParams = {
                    customType: 'custom',
                    data: JSON.stringify({ delivered: true, read: false }),
                };
                try {
                    await channel.updateUserMessage(sentMessage.messageId, sendbirdParams);
                } catch (error) {
                }
                sentMessage['data'] = JSON.stringify({ delivered: true, read: false })
                // this.setState({ commentStoryId: {} as StoryAttributes })
            }
        });
    } catch (error) {
    }
};

export const generateImageWithText = ( 
    previewUrl: string,
    textInStory: {
        text: string;
        textSize: string;
        x: number;
        y: number;
        isDragging: boolean;
        offsetX: number;
        offsetY: number;
        textColor: string;
        textFamily: string;
    }[],
    storyTxtAlignment: Alignment,
    setStateCallback: (newState: { finalImage: File }) => void
) => {
    const image = new Image();
    image.src = previewUrl;

    image.onload = () => {
        const containerWidth = 300;
        const containerHeight = 540;

        const aspectRatio = image.width / image.height;

        let scaledWidth = containerWidth;
        let scaledHeight = containerWidth / aspectRatio;

        if (scaledHeight > containerHeight) {
            scaledHeight = containerHeight;
            scaledWidth = containerHeight * aspectRatio;
        }

        const canvas = document.createElement('canvas');
        canvas.width = containerWidth;
        canvas.height = containerHeight;

        const context = canvas.getContext('2d');
        if (context) {
            context.fillStyle = 'black';
            context.fillRect(0, 0, canvas.width, canvas.height);

            const xOffset = (containerWidth - scaledWidth) / 2;
            const yOffset = (containerHeight - scaledHeight) / 2;

            context.drawImage(image, xOffset, yOffset, scaledWidth, scaledHeight);

            textInStory.forEach((textItem) => {
                const scaledTextSize = parseInt(textItem.textSize);
                context.font = `${scaledTextSize}px ${textItem.textFamily}`;
                context.fillStyle = textItem.textColor;

                switch (storyTxtAlignment) {
                    case 'left':
                        context.textAlign = 'left';
                        break;
                    case 'center':
                        context.textAlign = 'center';
                        break;
                    case 'right':
                        context.textAlign = 'right';
                        break;
                    default:
                        context.textAlign = 'left';
                }

                let adjustedX = textItem.x;
                if (context.textAlign === 'center') {
                    adjustedX = containerWidth / 2;
                } else if (context.textAlign === 'right') {
                    adjustedX = containerWidth - textItem.x;
                }

                const metrics = context.measureText(textItem.text);
                const textHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
                const adjustedY = textItem.y + textHeight / 2;

                context.fillText(textItem.text, adjustedX, adjustedY);
            });

            canvas.toBlob((blob) => {
                if (blob) {
                    const file = new File([blob], 'generated-image.png', {
                        type: 'image/png',
                    });
                    setStateCallback({ finalImage: file });
                }
            }, 'image/png');
        }
    };
};
