Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleUpdateMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "StoreLocator";
exports.labelBodyText = "StoreLocator Body";
exports.getVendingMachinesEndpoint = "/bx_block_storelocator/store_locators"
exports.searchVendingMachinesEndpoint = "/bx_block_storelocator/store_locators/search?search="
exports.btnExampleTitle = "CLICK ME";
exports.goHere = "Go here";
exports.GoogleMapText = 'Google Maps';
exports.mapsText =  'Maps' ;
exports.openWithText = 'Open with';
exports.RememberMyChoice = 'Remember my choice';
exports.CancelText = 'Cancel';
exports.backgroundColorRed = 'red';
exports.WorkingText = 'Working';
exports.notWorkingText = 'Not working';

exports.backgroundColorGreen = '#09A946';
exports.borderBlueColor = '#17B6E0';
exports.blackColor = '#000000';
exports.textGreyColor = '#6B7280';
exports.buttonBlueColor = '#0073A4';
exports.storeAddress   = "Store Address"
exports.helperTextForstoreAddress = "It looks like you don't have a store address added yet. Let's add one now." 
exports.addStoreAddress = "Add Store address"
exports.detectLocation = "Detect my current location"
exports.addButton = "Add"
exports.addressLineOne = "Address line one"
exports.addressLineTwo = "Address line two"
exports.pincodeLabel = "Pin Code"

exports.countryList = "bx_block_address/get_country_list"
exports.stateList = "bx_block_address/get_state_list"
exports.cityList = "bx_block_address/get_city_list"

exports.cityErrorMsg = "Please Enter Valid City";
exports.stateErrorMsg = "Please Enter Valid State";
exports.countryErrorMsg = "Please Enter Valid Country";


exports.longitudinal = "Longitudinal (optional)";
exports.latitudinal = "Latitudinal (optional)";
exports.storeFranchise = "Please check the box if this store is a franchise."

exports.linkAddress = "Link Address"
exports.linkStoresModalTitle = "Add Link Stores Address"
exports.searchSeller = "Search seller"
exports.email = "Email"
exports.comment = "Comment"
exports.pendingTxt = "Your Link address is pending, Please active your address."
exports.statusModalTitle = "Link Stores Address Requests"

exports.getSellersEndPoint = "bx_block_address/get_accounts"
exports.postLinkStoreEndPoint = "bx_block_address/link_address"
exports.getAllStoreEndPoint = "bx_block_address/store_addresses"
exports.deleteLinkedStoreEndPoint =  "bx_block_address/unlink_address?id="
exports.getPendingLinkedAddressEndPoint = "bx_block_address/get_pending_addresses"
exports.postAcceptRequestEndPoint = "bx_block_address/accept_pending_addresses"

exports.deleteMethod = "DELETE"
exports.getCity = "https://nominatim.openstreetmap.org/reverse?"
exports.getLatLong = "https://nominatim.openstreetmap.org/search?q="
exports.noSellerFound = "Please enter seller name."
exports.requests = "Requests"
exports.deleteModalTitle = "Delete your link store address ?"
exports.deleteAddressTitle = "Delete your store address ?"
exports.deleteModalDesc = "Please confirm if you would like to remove your link store address. Removing your address will require updating your link store information."
exports.deleteAddressDesc = "Please confirm if you would like to remove your store address. Removing your address will require updating your store information."
exports.cancel = "Cancel"
exports.delete = "Delete"
exports.comment = "Comment"

// Customizable Area End