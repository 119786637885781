import React, { Component } from 'react';
import { heartButton, redHeartButton } from '../../blocks/landingpage/src/assets';
import { StoryAttributes, StoryUploaders } from './CustomStoryListing.web';

interface StoryListingProps {
  likeStory: (storyID: StoryAttributes) => void;
  currentStoryIndex: number;
  userStory: Array<StoryUploaders>;
}

interface State {
  isLiked: boolean;
}

class LikeStoryComponent extends Component<StoryListingProps, State> {
  constructor(props: StoryListingProps) {
    super(props);

    this.state = {
      isLiked: this.props.userStory[this.props.currentStoryIndex]?.attributes?.liked
    };
  }

  componentDidMount(): void {
    this.setState({ isLiked: this.props.userStory[this.props.currentStoryIndex]?.attributes?.liked });
  }

  componentDidUpdate(prevProps: StoryListingProps) {
    if (prevProps.currentStoryIndex !== this.props.currentStoryIndex) {
      this.setState({
        isLiked: this.props.userStory[this.props.currentStoryIndex]?.attributes?.liked,
      });
    }
  }

  handleLikeToggle = (attr: StoryAttributes) => {
    const newLikedStatus = !this.state.isLiked;
    this.setState({ isLiked: newLikedStatus });
    this.props.likeStory(attr);
  };

  render() {
    return (
      <div data-test-id="likeBtn" onClick={() => this.handleLikeToggle(this.props.userStory[this.props.currentStoryIndex]?.attributes)}>
        {this.state.isLiked ? (
          <img src={redHeartButton} alt="heart_button" />
        ) : (
          <img src={heartButton} alt="heart_button" />
        )}
      </div>
    );
  }
}

export default LikeStoryComponent;
