// Customizable Area Start
import { createRef } from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles{
    navigation: any;
    id: string;
    // Customizable Area Start
   
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    activeStep:number
    isOpen:boolean
    isEdit: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AddNewProductController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    rootNewProductRef: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.rootNewProductRef = createRef();
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            activeStep:0,
            isOpen:true,
            isEdit: false
            // Customizable Area End
        };

    }

    // Customizable Area Start

    async componentDidUpdate() {
        this.rootNewProductRef.current?.scrollIntoView(0,0)
    }
    async componentWillUnmount(){
        localStorage.removeItem('activeStep');
    }
    
    async componentDidMount() {
        const storedStep = localStorage.getItem('activeStep');
        const currentStep = storedStep ? parseInt(storedStep, 10) : 0;
        this.setState({ activeStep: currentStep });

        const pathName = window.location.pathname.split("/")[1];

        this.setState({
            isEdit: pathName === "EditProduct"
        })
        let isLocalStep = await getStorageData('activeStepVariant');
        if (isLocalStep) {
            this.setState({ activeStep: 4 });
            setTimeout(() => {
                removeStorageData("activeStepVariant");
            }, 4000);
        }
    }

    handleActiveStep=(step:number)=>{
        this.setState({activeStep:step})
        localStorage.setItem('activeStep', step.toString());
    }

    handleBackNavigation=async()=>{
        const  {activeStep} = this.state
         const currentStep = await getStorageData("currentStep")
         const updateActiveStep = currentStep-1
        if(activeStep===0){
            this.props.navigation.navigate("InventoryManagement")
        }
        else{
            localStorage.setItem("activeStep", updateActiveStep.toString());
            this.setState({activeStep: updateActiveStep});
        }
       
    }
    toggleSideDrawer = () => {
        this.setState({ isOpen: !this.state.isOpen })
      }
    
    // Customizable Area End
}