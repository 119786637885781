import React from "react";
// Customizable Area Start
import BuyerOrderDetailsController, {
    Props
} from "./BuyerOrderDetailsController.web";

import { withStyles, StyleRules,createStyles,Theme } from "@material-ui/core/styles";
import { Paper, Grid, Box, Typography, Button, Divider, Avatar, Stepper, StepContent, Step, StepLabel,CircularProgress, styled } from '@material-ui/core';
import Loader from "../../../components/src/Loader.web";
import { liveArchive, liveArchiveBlank, liveRadar, noDataFound } from "../../landingpage/src/assets";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import MessageModalWeb from "../../../components/src/MessageModal.web";
import { Link } from 'react-router-dom';
const baseURL = require("../../../framework/src/config.js").baseURL
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { sucessImageIcon } from "./assets";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        minHeight: "100vh",
        marginTop: '100px',
        boxShadow: 'none',
        '@media(max-width: 1156px)': {
            marginTop: '70px',
        },
    },
    cartContainer: {
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    cartBox: {
        maxWidth: '1296px',
        width: '100%',
        borderBottom: '1px solid #0000004D',
    },
    leftCartBox: {
        padding: '0px 64px 64px',
        borderRight: '1px solid #0000004D',
        alignItems: 'start',
        display: 'flex',
        gap: '16px',
        flexDirection: 'column',
        '@media(max-width: 956px)': {
            padding: '32px',
            alignItems: 'center',
        },
    },
    itemBox: {
        justifyContent: 'space-between',
        display: 'flex',
        width: '100%',
        maxWidth: '626px',
        borderRadius: '6px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        minHeight: '190px',
        padding: '24px',
        gap: '24px',
        '@media(max-width: 575px)': {
            justifyContent: 'center',
            flexWrap: 'wrap',
        },
    },
    quantityBox: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        '@media(max-width: 492px)': {
            gap: '12px',
            flexWrap: 'wrap',
            justifyContent: 'center'
        },
    },
    stockOutTxt: {
        background: '#FFEBEB',
        fontSize: '14px',
        color: '#FF5656',
        minHeight: '37px',
        width: '166px',
        margin: '8px -24px 0px 0px',
        borderRadius: '6px 0px 0px 6px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    checkoutBtn: {
        borderRadius: '50px',
        background: '#F5EA16',
        fontSize: '20px',
        fontWeight: 600,
        textTransform: 'none',
        marginTop: '16px',
        '&:hover': {
            backgroundColor: '#F5EA16'
        },
        width:"100%"
    },
    cancelBtn: {
        background: 'transparent',
        borderRadius: '50px',
        fontWeight: 600,
        fontSize: '20px',
        marginTop: '16px',
        textTransform: 'none',
        width:"100%",
        border: '2px solid #000',
    },    
    rightCartBox: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '64px'
    },
    totalBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        maxWidth: '332px',
        width: '100%'
    },
    priceBox: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        alignItems: 'center'
    },
    priceText: {
        fontSize: '14px',
        color: '#6E6E6E'
    },
    SuggestionContainer: {
        '& .slick-slide': {
            maxWidth: '340px',
            height: "auto",
            width: '100%'
        },
        '& .slick-next': {
            display: 'none !important'
        },
        '& .slick-track': {
            display: 'flex !important',
            alignItems: 'center',
            gap: '16px',
            height: "384px",
        },
        '& .slick-prev': {
            display: 'none !important'
        }
    },
    suggestionBox: {
        position: "relative",
        maxWidth: '340px',
        flexDirection: 'column',
        display: 'flex !important',
        cursor: 'pointer',
        gap: '16px',
    },
    archiveBox: {
        right: "24px",
        position: "absolute",
        height: '31px',
        top: "24px",
        borderRadius: "31px",
        width: '32px',
        justifyContent: 'center',
        display: 'flex',
        background: "#00000099",
        alignItems: 'center',
    },
    proBox: {
        display: 'flex',
        gap: '24px',
        alignItems: 'center',
    },
    deliveryBox: {
        borderRadius: '50px',
        border: '1px solid #000000',
        fontSize: '16px',
        padding: '16px',
        fontWeight: 600
    },
    proPicture: {
        display: "flex",
        gap: '8px',
        alignItems: "center",
    },
    proTxt: {
        WebkitLineClamp: 1,
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        whiteSpace: 'normal',
        fontSize: '14px',
        textOverflow: 'ellipsis',
    },
    liveBox: {
        alignItems: "center",
        display: "flex",
        position: "absolute",
        justifyContent: 'space-around',
        left: "24px",
        top: "24px",
        background: "#FF1F7140",
        borderRadius: "15px",
        height: '28px',
        padding: "4px 10px",
        width: '80px'
    },
    liveCount: {
        fontWeight: 500,
        fontSize: "16px",
        color: "#FFFFFF",
    },
    keyTxt: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#787878",
    },
    valueTxt: {
        fontSize: "14px",
        fontWeight: 400,
    },
    infoBox: {
        border: '1px solid #E6E6E6',
        maxWidth: '613px',
        width: '100%',
        padding: '24px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
    },
    keyText: {
        fontSize: "14px",
        fontWeight: 500,
        color: '#6E6E6E'
    },
    valueText: {
        fontSize: "14px",
        fontWeight: 400,
        maxWidth: '150px',
        width: '100%',
        textAlign: 'start'
    },
    orderInfoBox: {
        border: '1px solid #E6E6E6',
        maxWidth: '334px',
        width: '100%',
        padding: '24px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
    },
    textBox: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    statusStepper: {
        padding: '24px 16px',
        '& .MuiStepIcon-root': {
            color: '#D9D9D9',
        },
        '& .MuiStepLabel-label.MuiStepLabel-active': {
            fontWeight: 600
        },
        '& .MuiStepLabel-label': {
            fontSize: '18px'
        },
        '& .MuiStepIcon-root.MuiStepIcon-completed': {
            color: '#F5EA16',
        },
        '& .MuiStepIcon-root.MuiStepIcon-active': {
            color: '#F5EA16',
        },
        '& .MuiStepConnector-vertical': {
            padding: '0px',
        },
        '& .MuiStepConnector-lineVertical': {
            borderLeftWidth: '4px',
            borderColor: '#E3E3E3',
            borderLeftStyle: 'dashed',
            minHeight: '104px',
        },
        '& .MuiStepContent-root': {
            marginTop: '0px',
            paddingRight: '0px',
            borderLeft: '4px solid #F5EA16'
        },
        '& .MuiStepConnector-active': {
            borderLeft: '4px solid #F5EA16 !important',
            '& .MuiStepConnector-lineVertical': {
                border: 'none',
                minHeight: '104px',
            },
        },
        '& .MuiStepIcon-text': {
            fill: 'transparent'
        }
    },
    noDataImage: {
        width: '100%',
        animation: 'fadeIn 2s',
        maxWidth: '300px',
        WebkitAnimation: 'fadeIn 2s',
        MozAnimation: 'fadeIn 2s',
        msAnimation: 'fadeIn 2s',
        OAnimation: 'fadeIn 2s',
    },
    successTitle: {
        textAlign: 'center' as const,
        fontWeight: 400,
        color: '#555555',
        marginTop:"10px"
    },
    successImage:{
        marginLeft:"130px"
    }
    // Customizable Area End
}

export class BuyerOrderDetails extends BuyerOrderDetailsController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    sliderProps = {
        className: "slider variable-width",
        dots: true,
        infinite: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true
    };

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, navigation } = this.props;
        const { loading, tracking, recommendationData, activeStep, orderDetails, wishListItem, notifyModel, message } = this.state;

        const date = new Date(orderDetails.placed_at);
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dayOfWeekIndex = date.getDay();
        const dayOfWeek = daysOfWeek[dayOfWeekIndex];
        const day = date.getDate().toString().padStart(2, '0');
        const monthsOfYear = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const monthIndex = date.getMonth();
        const monthName = monthsOfYear[monthIndex];
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        const formattedDate = `${day}-${month}-${year}`;

        const steps = [
            {
                label: `Order ${dayOfWeek}, ${day} ${monthName}`,
                description: '',
            },
            {
                label: 'Shipped Sunday, 24 May',
                description: 'Package arrived at the final delivery station at 2:55 pm',
            },
            {
                label: 'Out of delivery',
                description: '',
            },
            {
                label: 'Arriving Wednesday',
                description: '',
            },
        ];

        const dateNew = new Date();

        dateNew.setDate(dateNew.getDate() + 7);

        const deliveryData = dateNew.toLocaleDateString('en-US', {
            month: 'short',
            day: '2-digit',
            year: 'numeric'
        });


        return (
            <Paper className={classes.root}>
                <Loader loading={loading} />
                <Box style={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '40px' }}>
                    <KeyboardBackspaceIcon onClick={this.goBackOnOrderList} data-testid='backImg' style={{ cursor: 'pointer' }} />
                    <Typography style={{ fontSize: '24px', fontWeight: 600, color: '#000000CC' }}>Order Details</Typography>
                </Box>
                <Grid container className={classes.cartContainer}>
                    <Grid container className={classes.cartBox}>
                        <Grid item xs={12} md={7} className={classes.leftCartBox}>
                            <Box style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', width: '100%', gap: '24px', justifyContent: 'center' }}>
                                <Link style={{ maxWidth: '239px', maxHeight: '239px', width: '100%', height: '100%', borderRadius: '8px', objectFit: 'cover' }} to={`ProductDescription?id=${orderDetails.catalogue_id}`}>
                                    <img src={`${baseURL}${orderDetails.catalogue?.attributes.product_images[0].url}`} style={{ maxWidth: '239px', maxHeight: '239px', width: '100%', height: '100%', borderRadius: '8px', objectFit: 'cover', border: '1px solid #E6E6E6' }} />
                                </Link>
                                <Box className={classes.orderInfoBox}>
                                    <Box className={classes.textBox}>
                                        <Typography className={classes.keyText}>Order Id</Typography>
                                        <Typography className={classes.valueText}>{orderDetails.id}</Typography>
                                    </Box>
                                    <Box className={classes.textBox}>
                                        <Typography className={classes.keyText}>Order placed on </Typography>
                                        <Typography className={classes.valueText}>{formattedDate}</Typography>
                                    </Box>
                                    <Box className={classes.textBox}>
                                        <Typography className={classes.keyText}>Product name</Typography>
                                        <Typography className={classes.valueText}>{orderDetails.catalogue?.attributes.title}</Typography>
                                    </Box>
                                    <Box className={classes.textBox}>
                                        <Typography className={classes.keyText}>Colour</Typography>
                                        <Typography className={classes.valueText}>{orderDetails.catalogue_variant?.attributes.catalogue_variant_color?.name}</Typography>
                                    </Box>
                                    <Box className={classes.textBox}>
                                        <Typography className={classes.keyText}>Order Number</Typography>
                                        <Typography className={classes.valueText}>{orderDetails.order_number}</Typography>
                                    </Box>
                                    <Box className={classes.textBox}>
                                        <Typography className={classes.keyText}>Order total</Typography>
                                        <Box sx={{ fontSize: '14px', fontFamily: "Arial !important", maxWidth: '150px', width: '100%', textAlign: 'start' }}>₹ {orderDetails.total_price}</Box>
                                    </Box>
                                    <Box className={classes.textBox}>
                                        <Typography className={classes.keyText}>QTY.</Typography>
                                        <Typography className={classes.valueText}>{orderDetails.quantity}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                            {orderDetails.shipment_details &&
                                <>
                                    <Typography style={{ width: '100%', fontSize: '16px', fontWeight: 600, paddingBottom:"10px" }}>Shipment Details</Typography>
                                    <Box className={classes.infoBox}>
                                        <Box>
                                            <Typography className={classes.keyTxt}>Name</Typography>
                                            <Typography className={classes.valueTxt}>{orderDetails.shipment_details?.name}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography className={classes.keyTxt}>Email</Typography>
                                            <Typography className={classes.valueTxt}>{orderDetails.shipment_details?.email}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography className={classes.keyTxt}>Phone number</Typography>
                                            <Typography className={classes.valueTxt}>{orderDetails.shipment_details?.mobile}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography className={classes.keyTxt}>Ship to</Typography>
                                            <Typography className={classes.valueTxt}>{orderDetails.shipment_details ? `${orderDetails.shipment_details?.name}, ${orderDetails.shipment_details?.flat_no}, ${orderDetails.shipment_details?.city}, ${orderDetails.shipment_details?.state}, ${orderDetails.shipment_details?.country}, ${orderDetails.shipment_details?.pin_code}` : ''}</Typography>
                                        </Box>
                                        {orderDetails.status !== "declined" &&
                                            <Box>
                                                <Typography className={classes.keyTxt}>Item delivered on {deliveryData}</Typography>
                                            </Box>
                                        }
                                    </Box>
                                </>
                            }</Box>
                            {this.state.deliveryType==="self_pickup" &&
                            <Box>
                                <Typography 
                                style={{ fontSize: '16px', 
                                fontWeight: 600, 
                                padding: "15px 0" }}>
                                    Seller Details</Typography>
                                <Box 
                                style={{ border: "1px solid #E6E6E6", 
                                height: "150px", 
                                width: "630px", 
                                borderRadius: "10px", 
                                padding: "15px" }}>
                                    <Box 
                                    style={{ padding: "10px 0" }}>
                                        <Typography 
                                        style={{ fontSize: '14px', 
                                        fontWeight: 600, 
                                        color: "#787878" }}>Name</Typography>
                                        <Typography 
                                        style={{ fontSize: '14px' }}>
                                            {this.state.currentSellerName}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography 
                                        style={{ fontSize: '14px', 
                                        fontWeight: 600, 
                                        color: "#787878" }}>UPI ID
                                        </Typography>
                                        <Typography 
                                        style={{ fontSize: '14px' }}>
                                            {this.state.currentSellerUpi}</Typography>
                                    </Box>
                                </Box>
                            </Box>}
                        </Grid>
                        <Grid item xs={12} md={5} className={classes.rightCartBox}>
                            {tracking ?
                                <Box className={classes.totalBox}>
                                    <StyledBox className="backButtonBox">
                                        <KeyboardBackspaceIcon onClick={this.goBackToOrderSection} data-testid='backImg' className="backButtonIcon" />
                                        <Typography className="backTitle">Back</Typography>
                                    </StyledBox>
                                    <Box className={classes.deliveryBox} >Deliver to {orderDetails.shipment_details?.name} - {orderDetails.shipment_details?.city} {orderDetails.shipment_details?.pin_code}</Box>
                                    <Typography style={{ fontSize: '16px', fontWeight: 600 }}>Arriving Wednesday</Typography>
                                    <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px', border: '1px solid #E6E6E6', borderRadius: '8px' }}>
                                        <Stepper activeStep={activeStep} orientation="vertical" className={classes.statusStepper}>
                                            {steps.map((step) => (
                                                <Step key={step.label}>
                                                    <StepLabel>
                                                        {step.label}
                                                    </StepLabel>
                                                    <StepContent>
                                                        <Typography style={{ color: '#00000080', fontSize: '14px', fontWeight: 500 }}>{step.description}</Typography>
                                                        <Typography style={{ fontSize: '14px', fontWeight: 500 }}>See all updates</Typography>
                                                    </StepContent>
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </Box>

                                </Box> :
                                <Box className={classes.totalBox}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 600 }}>Payment Details</Typography>
                                    <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px', border: '1px solid #E6E6E6', borderRadius: '8px', padding: '24px' }}>
                                        <Box className={classes.priceBox}>
                                            <Typography className={classes.priceText}>Item cost</Typography>
                                            <Box sx={{ fontSize: '14px', fontWeight: 500, fontFamily: "Arial !important", maxWidth: '100px', width: '100%', textAlign: 'start' }}>₹ {orderDetails.total_price}</Box>
                                        </Box>
                                        <Box className={classes.priceBox}>
                                            <Typography className={classes.priceText}>Packaging</Typography>
                                            <Box sx={{ fontSize: '14px', fontWeight: 500, fontFamily: "Arial !important", maxWidth: '100px', width: '100%', textAlign: 'start' }}>₹ 0</Box>
                                        </Box>
                                        <Box className={classes.priceBox}>
                                            <Typography className={classes.priceText}>Tax</Typography>
                                            <Box sx={{ fontSize: '14px', fontWeight: 500, fontFamily: "Arial !important", maxWidth: '100px', width: '100%', textAlign: 'start' }}>₹ {orderDetails.tax}</Box>
                                        </Box>
                                        <Box className={classes.priceBox}>
                                            <Typography className={classes.priceText}>(-) Offer discount</Typography>
                                            <Box sx={{ fontSize: '14px', fontWeight: 500, fontFamily: "Arial !important", maxWidth: '100px', width: '100%', textAlign: 'start' }}>₹ {orderDetails.discount}</Box>
                                        </Box>
                                        <Divider />
                                        <Box className={classes.priceBox}>
                                            <Typography className={classes.priceText}>Total</Typography>
                                            <Box sx={{ fontSize: '14px', fontWeight: 500, fontFamily: "Arial !important", maxWidth: '100px', width: '100%', textAlign: 'start' }}>₹ {orderDetails.grand_total}</Box>
                                        </Box>
                                        {/* <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
                                        Paid via visa card ending with 4444
                                    </Typography> */}
                                    </Box>
                                    {this.state.buyerStatus==="delivered"?
                                    <Box>
                                        <img src={sucessImageIcon} alt="Success Vector" className={classes.successImage} />
                                        <Typography variant="h6" className={classes.successTitle}>Your order has been successfully picked up! Thank you for choosing self-pickup</Typography>
                                    </Box>
                                    :<Box>
                                    {this.state.deliveryType==="self_pickup"?
                            <Box style={{border:"1px solid #E6E6E6", height:"240px",width:"350px", borderRadius:"10px"}}>
                                {this.state.regenerateOtp?
                                <Box data-testid='otp-expire' style={{fontSize:"12px", fontWeight:600, marginTop:"30px", display:"flex", justifyContent:"center"}}>Your OTP has expired. To receive a new OTP, please click on the 'Regenerate OTP' button.</Box>:
                                <Box>
                                            <Box style={{display:"flex", padding:"22px", justifyContent:"center"}}>
                                                <Box
                                                            position="relative"
                                                            display="inline-flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                        >
                                                            <StyledCircularProgress
                                                                variant="determinate"
                                                                value={100}
                                                                size={80}
                                                                thickness={5}
                                                                style={{ color: '#979797', position: 'absolute' }}
                                                            />
                                                            <StyledCircularProgress
                                                                variant="determinate"
                                                                value={this.state.percentage}
                                                                size={80}
                                                                thickness={5}
                                                                style={{ color: '#F5EA16' }}
                                                            />
                                                            <Box
                                                                position="absolute"
                                                                display="flex"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                <Typography color="textPrimary" style={{fontSize:"14px"}}>
                                                                {this.formatTimes(this.state.remainingTime)}
                                                                </Typography>
                                                            </Box>
                                                </Box>
                                                <Box display="flex" alignItems="center" width="270px" marginLeft="30px">
                                                                <InfoOutlinedIcon style={{ color: '#757575', marginRight: 8 }} />
                                                                <Typography variant="body2" style={{ color: '#424242' }}>
                                                                    Note: Self-pickup request will expire within 24 hours.
                                                                </Typography>
                                                </Box>
                                            </Box>
                                            <Typography style={{fontFamily:"Poppins",fontSize:"80px", fontWeight:800, color:"#F5EA16", letterSpacing:"10px", display:"flex", justifyContent:"center"}}>{this.state.Otp}</Typography>
                                </Box>}        
                                        </Box>:""}
                                    <Button className={classes.checkoutBtn} data-testid='trackOrder' onClick={this.handleTracking}>Track your Order</Button>
                                    <Button disabled={!this.state.regenerateOtp} 
                                    style={{
                                         width:"100%",
                                        borderRadius: '50px',
                                        background: this.state.regenerateOtp?'#F5EA16':"#d9d9d9",
                                        fontSize: '20px',
                                        fontWeight: 600,
                                        textTransform: 'none',
                                        marginTop: '16px',
                                        display: this.state.deliveryType==="self_pickup"?"block":"none"
                                       }
                                    }
                                     data-testid='trackOrder' onClick={this.regerateOtpApi}>Regenerate your Otp</Button>
                                    <Button className={classes.cancelBtn} data-testid='cancelOrder' onClick={this.cancelOrderApi}>Cancel your Order</Button>
                                    </Box>}
                                </Box>}
                                
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '40px 40px 0px', flexWrap: 'wrap', alignItems: 'center' }}>
                        <Typography style={{ fontWeight: 500, fontSize: '24px' }}>You May Also Like</Typography>
                        <Typography style={{ fontSize: '16px', cursor: 'pointer' }} data-testid='seeAll' onClick={this.navigateToSeeAll}>{'See all >'}</Typography>
                    </Box>
                    <Grid className={classes.SuggestionContainer}>
                        {recommendationData.length > 0 ?
                            <Slider {...this.sliderProps}>
                                {recommendationData.map((data: any, index: number) => (
                                    <Box
                                        className={classes.suggestionBox}
                                        key={index}
                                        data-testid='suggestionBox'
                                        onClick={() => this.navigateToProductDesc(data.id)}
                                    >
                                        <Box className={classes.liveBox}>
                                            <img src={liveRadar} />
                                            <Typography className={classes.liveCount}>24</Typography>
                                        </Box>
                                        <Box className={classes.archiveBox}>
                                            <img
                                                src={this.checkWatchlistStatus(wishListItem, parseInt(data.id)) ? liveArchive : liveArchiveBlank}
                                                style={{ height: '16px', width: '16px' }}
                                                onClick={(event: any) => this.handleWatchlistItem(event, data.id, wishListItem, data.type)}
                                            />
                                        </Box>
                                        <img src={`${baseURL}${data.attributes.product_images[0].url}`} style={{ height: '256px', maxWidth: '340px', width: '100%', borderRadius: '16px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',objectFit: 'cover' }} />
                                        <Box className={classes.proBox}>
                                            <Box className={classes.proPicture}>
                                                <Avatar src={data.attributes.host_image} style={{ height: '40px', width: '40px' }} />
                                            </Box>
                                            <Box>
                                                <Typography className={classes.proTxt} style={{ fontWeight: 500 }} >{data.attributes.host_name}</Typography>
                                                <Typography className={classes.proTxt} style={{ color: "#A5A4A4" }} >{data.attributes.description}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                ))}
                            </Slider> :
                            <Box style={{ width: '100%', display: 'flex', height: '50vh', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={noDataFound} className={classes.noDataImage} />
                            </Box>}
                    </Grid>
                </Grid>
                <MessageModalWeb
                    displayPopup={notifyModel}
                    closeErrorPopup={this.closeNotifyModel}
                    errorMessage={message} />
            </Paper>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledBox = styled(Box)({
    gap: "10px",
    display: "flex",
    "& .backButtonIcon": {
        cursor: "pointer",
    },
    "& .backTitle":{
        fontSize:"16px",
        fontWeight:500
    }
});

export const StyledCircularProgress = withStyles((theme: Theme) =>
    createStyles({
      root: {
        position: 'relative',
      },
      circle: {
        strokeLinecap: 'round',
      },
    })
  )(CircularProgress);
export default withStyles(styles)(BuyerOrderDetails);
// Customizable Area End