// Customizable Area Start
import React from "react";

import {
    Button,
    InputAdornment,
    IconButton,
    // Customizable Area Start
    Tabs,
    Tab,
    RadioGroup,
    FormControlLabel,
    Radio,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    Badge,
    Tooltip,
    styled
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Avatar from '@material-ui/core/Avatar';
import '../assets/css/styleSeller.css'
import TextField from '@material-ui/core/TextField';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Switch from '@material-ui/core/Switch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OtpInputField from "../../../components/src/OtpInputField";
import SellerNotificationModal from "../../../components/src/SellerNotificationModal.web";
import StoreLocator from "../../storelocator/src/StoreLocator.web"
import { withStyles, Theme } from "@material-ui/core/styles";
import {
    calendarIc,
    notificationIcon,
    notificationRedDotIc,
    hamburgerIc,
    arrowLeftIc,
    EditGraySquareIc,
    fileSucessIc,
    AddGraySquareIc,
    CloseIc,
    PasswordShowIc,
    PasswordHideIc,
    SuccessIc,
    CloseDocumentIc,
    GalleryIc,
    EditYellowIc,
    EditWhitec,
    AddYellowIc,
    AddWhiteIc,
    bannerImageBlankSeller,
    addUPIPlusIcon,
    qrLogo,
    copy,
    flxooTextLogo,
    shareIcon,
    downloadIcon,
} from "./assets";

import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

import { QRCode } from 'react-qrcode-logo';
let frameworkConfigJSON = require('../../../framework/src/config')

const truthyValue = (key: any) => {
    if (key !== "" || key !== null || !key.length) {
        return key
    } else {
        return ""
    }     
}

export const webStyles = (theme: Theme) => ({
    upiContainer:{
        marginBottom:"1em"
    },
    upiTitle:{
        color: '#555555',
        fontFamily: 'Poppins !important',
        fontSize: '1.2em',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '27px',

    },
    addUPIContainer:{
        display:"flex",
        flexDirection:"column" as const,
        justifyContent:"center",
        alignItems:"center",
        height:"19em",
        boxShadow:"0px 4px 20px 0px rgba(0, 0, 0, 0.10)"
    },
    addUpiHelperText:{
        color: '#4E4E4E',
        fontFamily: 'Poppins !important',
        fontSize: '0.9em',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '21px',
    },
    editButtonContainer:{
        display:"flex",
        justifyContent:"flex-end",
        pointer:"cursor",
    },
    upiDetailsContainer:{
        height:"19em",
        boxShadow:"0px 4px 20px 0px rgba(0, 0, 0, 0.10)",
        marginBottom:"1em"
    },
    upiDetailsInnerContainer:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    divider:{
        margin:"0em 1.5em"
    },
    upiIdText:{
        color: '#555555',
        fontFamily: 'Poppins',
        fontSize: '1em',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
    },
    sellerUpiID:{
        color: '#444444',
        fontFamily: 'Poppins !important',
        fontSize: '1.2em',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        margin:"1em 0em"
    },
    upiActionsContainer:{
        display:"flex",
        color: '#444444',
        fontFamily: 'Poppins !important',
        fontSize: '0.9em',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '21px',
    },
    upiActionsBox:{
        display:"flex",
        alignItems:"center",
        justifyContent:'space-between',
        marginRight:"1em",
        pointer:"cursor",

    },
    upiActionsIcon:{
        marginRight:"0.45em",
        pointer:"cursor",
    },
    addUPIDailog:{
        width:"36em",
        height:"19em",  
        padding:"0em 2em",
        borderRadius:"25px"
    },
    addUPITitleContainer:{
        display:"flex",
        justifyContent:"space-between",
        margin:"2em 0em"
    },
    addUPITitle:{
        color:"#2E2E2E",
        fontFamily: 'Poppins !important',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '27px',
    },
    verifyAndSaveButton:{
        width:"100%",
        height: '3em',
        background: '#F5EA16',
        borderRadius:"25px",
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '24px',
        pointer:"cursor",
        textTransform:"none" as const,
        "&:hover":{
            background: '#F5EA16',
        }

    },
    upiTextField:{
        width:"100%",
        borderRadius:"25px"
    },
    verifyText:{
        color:"#2E2E2E",
        fontFamily: 'Poppins !important',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '32px',
    },
    saveAndVerifyButtonContainer:{
        display:"flex",
        alignItems:"center",
        margin:"1em 0em"
    },
    upiError:{
        color:"#EC2024",
        fontFamily: 'Poppins !important',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
    },
    profileQrCode:{
        height:"5em",
        boxShadow:"0px 4px 20px 0px rgba(0, 0, 0, 0.10)",
        margin:"2em 0 0 1.5em",
        display:"flex",
        alignItems:"center",
        borderRadius:"20px"
    },
    profileQrCodeInner:{
        display:"flex",
        justifyContent:"space-between",
        width:"100%",
        padding:"0em 1em"
    },
    profileQrCodeTxt:{
        color:"#555555",
        fontFamily: 'Poppins !important',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
    },
    generateTxt:{
        color:"#555555",
        fontFamily: 'Poppins !important',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px',
        textDecoration:"underline",
        pointer:"cursor"
    },
     profileQrModal:{
        width:"37em",
        height:"28em",  
        padding:"1em 2em",
        borderRadius:"25px",
        display:"none"
    },
    profileQrModalClose:{
        display:"flex",
        justifyContent:"flex-end"
    },
    profileQRcodeBox:{
        display:"flex",
        alignItems:"center",
        flexDirection:"column" as const
    },
    flixooImage:{
        marginTop:"0.5em",
        height:"30px",
        width:"75px"
    },
    buttonContainer:{
        display:"flex",
        alignItems:"center",
        flexDirection:"column" as const,
        marginTop:"2em",
    },
    downloadButton:{
        width:"100%",
        height: '3em',
        background: '#F5EA16',
        borderRadius:"25px",
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '24px',
        pointer:"cursor",
        textTransform:"none" as const,
        "&:hover":{
            background: '#F5EA16',
        }
    },
    copyButton:{
        marginTop:"1em",
        width: '100%',
        height: '2.7em',
        background: 'none',
        border:'1px solid black',
        borderRadius:"22px",
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        pointer:"cursor",
        textTransform:"none" as const,
        "&:hover":{
            background: 'none'
        }
    }
});

export const YellowCheckbox = withStyles({
    root: {
        '&$checked': {
            color: "yellow",
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
import { locationBlackIcon } from "../../cfdigitalmall/src/assets";
import { SnackBarAlert } from "../../../components/src/SnackBarAlert.web";
// Customizable Area End

import CustomisableSellerProfilesControllerWeb, {
    Props, configJSON,
} from "./CustomisableSellerProfilesControllerWeb";
import StayTuned from "../../../components/src/StayTuned.web";
import MessageModalWeb from "../../../components/src/MessageModal.web";
import ProfileQRCode from "../../qrcodes/src/ProfileQrCode.web";

export class SellerUserBusinessEdit extends CustomisableSellerProfilesControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    emailError() {
        return (this.state.emailError !== "" && (
            <div className="red-text">{this.state.emailError}</div>
        ))
    }

    otpError() {
        return (this.state.otpError !== "" && (
            <div className="red-text">{this.state.otpError}</div>
        ))
    }

    phoneError() {
        return (this.state.phoneError !== "" && (
            <div className="red-text">{this.state.phoneError}</div>
        ))
    }

    currentPwdError() {
        return (this.state.currentPwdError !== "" && (
            <div className="red-text">{this.state.currentPwdError}</div>
        ))
    }

    newPwdError() {
        return (this.state.newPwdError !== "" && (
            <div className="red-text">{this.state.newPwdError}</div>
        ))
    }

    reNewPasswordError() {
        return (this.state.reNewPasswordError !== "" && (
            <div className="red-text">{this.state.reNewPasswordError}</div>
        ))
    }

    pwdError() {
        return (this.state.pwdError !== "" && (
            <div className="red-text">{this.state.pwdError}</div>
        ))
    }

    bankAccNumError() {
        return (this.state.bankAccNumError !== "" && (
            <div className="red-text">{this.state.bankAccNumError}</div>
        ))
    }

    bankAccHoldNameError() {
        return (this.state.bankAccHoldNameError !== "" && (
            <div className="red-text">{this.state.bankAccHoldNameError}</div>
        ))
    }

    bankNameError() {
        return (this.state.bankNameError !== "" && (
            <div className="red-text">{this.state.bankNameError}</div>
        ))
    }

    bankAccountIfscCodeError() {
        return (this.state.bankAccountIfscCodeError !== "" && (
            <div className="red-text" data-test-id="bankAccountIfscCodeError">{this.state.bankAccountIfscCodeError}</div>
        ))
    }

    pickUpaddressline1Error() {
        return (this.state.pickUpaddressline1Error !== "" && (
            <div className="red-text">{this.state.pickUpaddressline1Error}</div>
        ))
    }

    pickUpaddressline2Error() {
        return (this.state.pickUpaddressline2Error !== "" && (
            <div className="red-text">{this.state.pickUpaddressline2Error}</div>
        ))
    }

    pickUppincodeError() {
        return (this.state.pickUppincodeError !== "" && (
            <div className="red-text">{this.state.pickUppincodeError}</div>
        ))
    }

    pickUpcityError() {
        return (this.state.pickUpcityError !== "" && (
            <div className="red-text">{this.state.pickUpcityError}</div>
        ))
    }

    pickUpstateError() {
        return (this.state.pickUpstateError !== "" && (
            <div className="red-text">{this.state.pickUpstateError}</div>
        ))
    }

    pickUpcountryError() {
        return (this.state.pickUpcountryError !== "" && (
            <div className="red-text">{this.state.pickUpcountryError}</div>
        ))
    }

    dropOffaddressline1Error() {
        return (this.state.dropOffaddressline1Error !== "" && (
            <div className="red-text">{this.state.dropOffaddressline1Error}</div>
        ))
    }

    dropOffaddressline2Error() {
        return (this.state.dropOffaddressline2Error !== "" && (
            <div className="red-text">{this.state.dropOffaddressline2Error}</div>
        ))
    }

    dropOffpincodeError() {
        return (this.state.dropOffpincodeError !== "" && (
            <div className="red-text">{this.state.dropOffpincodeError}</div>
        ))
    }

    dropOffcityError() {
        return (this.state.dropOffcityError !== "" && (
            <div className="red-text">{this.state.dropOffcityError}</div>
        ))
    }

    dropOffstateError() {
        return (this.state.dropOffstateError !== "" && (
            <div className="red-text">{this.state.dropOffstateError}</div>
        ))
    }

    dropOffcountryError() {
        return (this.state.dropOffcountryError !== "" && (
            <div className="red-text">{this.state.dropOffcountryError}</div>
        ))
    }

    businessLegalGstPanError() {
        return (this.state.businessLegalGstPanError !== "" && (
            <div className="red-text">{this.state.businessLegalGstPanError}</div>
        ))
    }

    businessLegalEmailError() {
        return (this.state.businessLegalEmailError !== "" && (
            <div className="red-text">{this.state.businessLegalEmailError}</div>
        ))
    }

    businessLegalBusinessLicenceError() {
        return (this.state.businessLegalBusinessLicenceError !== "" && (
            <div className="red-text">{this.state.businessLegalBusinessLicenceError}</div>
        ))
    }

    businessLegalDocumentError() {
        return (this.state.businessLegalDocumentError !== "" && (
            <div className="red-text">{this.state.businessLegalDocumentError}</div>
        ))
    }

    profileBioError() {
        return (this.state.profileBioError !== "" && (
            <div className="red-text">{this.state.profileBioError}</div>
        ))
    }

    abstractSellerUserData = () => {
        let userName = ""
        let email = ""
        let mobileNumber = ""
        let bio = ""
        if (this.state.sellerProfileData) {
            userName = truthyValue(this.state.sellerProfileData.attributes.user_name)
            email = truthyValue(this.state.sellerProfileData.attributes.email)
            mobileNumber = truthyValue(this.state.sellerProfileData.attributes.full_phone_number)
            bio = truthyValue(this.state.sellerProfileData.attributes.my_bio)
        }
        return { userName, email, mobileNumber, bio }
    }

    abstractSellerBankingData = () => {
        let accountNumber = ""
        let accounHolderName = ""
        let bankName = ""
        let ifscCode = ""
        let bankList = []
        let bankNameSelect = ""
        bankList = this.state.bankList
        bankNameSelect = truthyValue(this.state.bankName)
        if (this.state.sellerBankDetail) {
            accountNumber = truthyValue(this.state.sellerBankDetail.bank_account_number)
            accounHolderName = truthyValue(this.state.sellerBankDetail.account_holder_name)
            bankName = truthyValue(this.state.sellerBankDetail.bank_name)
            ifscCode = truthyValue(this.state.sellerBankDetail.ifsc_code)
        }
        return { accountNumber, accounHolderName, bankName, ifscCode, bankList, bankNameSelect }
    }

    abstractSellerBusinessData = () => {
        let primaryCategory = ""
        let subCategory = ""
        let gstPanNumber = ""
        let businessLicenceNumber = ""
        let businessType = ""
        let businessLegalDocumentFileName = []
        const formatCategories = (categories:any) => {
            return categories.filter(Boolean).join(', ');
          };
        if (this.state.sellerBusinessDetail) {
            primaryCategory = formatCategories(this.state.sellerBusinessDetail.attributes.category.map((cat:any)=>cat.name))
            subCategory = formatCategories(this.state.sellerBusinessDetail.attributes.sub_category.map((sbcat:any)=>sbcat.name))
            gstPanNumber = truthyValue(this.state.sellerBusinessDetail.attributes.pan_number)
            businessLicenceNumber = truthyValue(this.state.sellerBusinessDetail.attributes.gst_number)
            businessType = truthyValue(this.state.sellerProfileData.attributes.business_type)
            businessLegalDocumentFileName = truthyValue(this.state.sellerBusinessDetail.attributes.attachments)
        }
        return { primaryCategory, subCategory, gstPanNumber, businessLicenceNumber, businessType, businessLegalDocumentFileName }
    }

    returnBuyerAdderss = () => {
        let fullAddress = "";
        if (this.state.sellerAddress?.attributes) {
            const addressLine1 = this.state.sellerAddress.attributes.address_line_1 ? this.state.sellerAddress.attributes.address_line_1 : ""
            const addressLine2 = this.state.sellerAddress.attributes.address_line_2 ? this.state.sellerAddress.attributes.address_line_2 : ""
            const pinCode = this.state.sellerAddress.attributes.pin_code ? this.state.sellerAddress.attributes.pin_code : ""
            const city = this.state.sellerAddress.attributes.city ? this.state.sellerAddress.attributes.city : ""
            const stateName = this.state.sellerAddress.attributes.state ? this.state.sellerAddress.attributes.state : ""
            const countryName = this.state.sellerAddress.attributes.country ? this.state.sellerAddress.attributes.country : ""

            fullAddress = `${addressLine1}, ${addressLine2}, ${pinCode}, ${city}, ${stateName}, ${countryName}`
        }
        return fullAddress
    }

    returnBuyerDropOffAdderss = () => {
        let fullAddress = "";
        if (this.state.sellerDropOffAddress?.attributes) {
            const addressLine1 = this.state.sellerDropOffAddress.attributes.address_line_1 ? this.state.sellerDropOffAddress.attributes.address_line_1 : ""
            const addressLine2 = this.state.sellerDropOffAddress.attributes.address_line_2 ? this.state.sellerDropOffAddress.attributes.address_line_2 : ""
            const pinCode = this.state.sellerDropOffAddress.attributes.pin_code ? this.state.sellerDropOffAddress.attributes.pin_code : ""
            const city = this.state.sellerDropOffAddress.attributes.city ? this.state.sellerDropOffAddress.attributes.city : ""
            const stateName = this.state.sellerDropOffAddress.attributes.state ? this.state.sellerDropOffAddress.attributes.state : ""
            const countryName = this.state.sellerDropOffAddress.attributes.country ? this.state.sellerDropOffAddress.attributes.country : ""

            fullAddress = `${addressLine1}, ${addressLine2}, ${pinCode}, ${city}, ${stateName}, ${countryName}`
        }
        return fullAddress
    }

    abstractSellerData = () => {
        let businessName = ""
        let profilephoto = ""
        let backgroundphoto = ""
        let role = ""
        if (this.state.sellerProfileData) {
            businessName = truthyValue(this.state.sellerProfileData.attributes.business_name)
            profilephoto = truthyValue(this.state.sellerProfileData.attributes.profile_photo)
            backgroundphoto = truthyValue(this.state.sellerProfileData.attributes.background_photo)
            role = truthyValue(this.state.sellerProfileData.attributes.role.name)
        }
        return { businessName, profilephoto, backgroundphoto, role }
    }
    renderDocument = (name:any, index:number) => {
        return (
          <div
            key={index}
            onClick={() => this.handleClickDocument(name.url)}
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              color: '#555',
              userSelect: 'none',
              listStyleType: 'none',
              display: 'flex',
              alignItems: 'center',
              margin: '7px 0',
            }}
          >
            <img
              style={{ width: '18px', height: '18px', marginRight: '7px' }}
              src={fileSucessIc}
              alt="file success"
            />
            {name.filename}
          </div>
        );
      };
    
      renderBusinessDocs = () => {
        return (
          <div className="file-success-wrap" style={{ display: 'block' }}>
            {this.state.businessDocuments.map((name, index) => this.renderDocument(name, index))}
          </div>
        );
      };
    renderProfileSection = () => {
        const { businessName, profilephoto, backgroundphoto } = this.abstractSellerData()
        return (
            this.state.tabValueSeller === 0 && (
                <section className="banner-section-seller">
                    {backgroundphoto ?
                        <div className="banner-img-wrap-seller">
                            <img src={backgroundphoto}
                                alt="banner Img" />
                        </div>
                        :
                        <div className="banner-img-wrap-seller no-bg">
                            <img className="no-bg" src={bannerImageBlankSeller} alt="banner Img" />
                        </div>
                    }
                    <div className="profile-detail-seller">
                        <div className="profile-detail-inner-seller">
                            <div className="profile-photo-wrap-seller">
                                <Avatar src={profilephoto} className="profile-photo-seller" />
                                <IconButton className="edit-icon">
                                    <img src={profilephoto ? EditYellowIc : AddYellowIc} alt="Edit" />
                                    <input
                                        accept="image/*"
                                        id="upload-avatar-pic"
                                        type="file"
                                        className="upload"
                                        data-test-id="uploadFile"
                                        onChange={this.handleSellerProfileImage}
                                    />
                                </IconButton>
                            </div>
                            <div className="profile-description-wrap">
                                <div className="name-wrap">
                                    <span
                                        className="name">{businessName}</span>
                                    <div className="edit-icon-right">
                                        <IconButton className="edit-icon-transparant">
                                            <img src={backgroundphoto ? EditWhitec : AddWhiteIc} alt="Edit" />
                                            <input
                                                accept="image/*"
                                                id="upload-avatar-pic"
                                                type="file"
                                                className="upload"
                                                data-test-id="backgroundUploadFile"
                                                onChange={this.handleSellerBackgroundImage}
                                            />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        )
    }

    renderPasswordCurrentFields = () => {
        return (
            <div className="custom-form-group">
                <TextField
                    label="Current password"
                    variant="outlined"
                    className="custom-outline-form-control"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type={this.state.showCurrenPassword ? 'text' : 'password'}
                    data-test-id="currentPassowrdText"
                    onChange={this.handleCurrentPasswordChangePassword}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton edge="end" onClick={this.handleCurrenPasswordShow}>
                                    {this.state.showCurrenPassword ?  <img src={PasswordShowIc} alt="Password Hide" /> : <img src={PasswordHideIc} alt="Password Show" />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {this.currentPwdError()}
            </div>
        )
    }

    renderPasswordNewFields = () => {
        return (
            <div className="custom-form-group">
                <TextField
                    label="New password"
                    variant="outlined"
                    className="custom-outline-form-control"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type={this.state.showNewPassword ? 'text' : 'password'}
                    data-test-id="newPassowrdText"
                    onChange={this.handleNewPasswordChangePassword}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton edge="end" onClick={this.handleNewPasswordShow}>
                                    {this.state.showNewPassword ? <img src={PasswordShowIc} alt="Password Hide" /> : <img src={PasswordHideIc} alt="Password Show" />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {this.newPwdError()}
            </div>
        )
    }

    renderPasswordReNewFields = () => {
        return (
            <div className="custom-form-group">
                <TextField
                    label="Re-enter new password"
                    variant="outlined"
                    className="custom-outline-form-control"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type={this.state.showReNewPassword ? 'text' : 'password'}
                    data-test-id="renewPassowrdText"
                    onChange={this.handleReEnterNewPasswordChangePassword}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton edge="end" onClick={this.handleReNewPasswordShow}>
                                    {this.state.showReNewPassword ? <img src={PasswordShowIc} alt="Password Hide" /> : <img src={PasswordHideIc} alt="Password Show" />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {this.reNewPasswordError()}
            </div>
        )
    }

    renderQrGenerateSection=()=>{
        const {classes} = this.props
        return (
            <Box className={classes.profileQrCode}>
                <ProfileQRCode navigation={undefined} id={""} onQrButtonClick={this.handleProfileQRGenerationMOdal}/>
            </Box>
        )
    }

    renderLatLongView = () => {
        return (
            <LatLongViewWrapper>
                <Box className="titleTxt">
                    Latitude & Longitude
                </Box>
                <Box className="paperBox">
                    <Box className="currentLocBox" onClick={this.getUserGpsLatitude}>
                        <img src={locationBlackIcon} className="currentLocIcon" />
                        <Typography className="currentLocTxt">Use Current Location</Typography>
                    </Box>
                    {this.state.loading ? 'Loading.....' : ''}
                    <Box className="inputContainer">
                        <TextField
                            label="Latitude*"
                            variant="outlined"
                            className="custom-outline-form-control"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type="text"
                            value={this.state.latitude}
                            fullWidth
                            onChange={this.handleChangeLat}
                            data-test-id='latTestId'
                        />
                        <Box>
                            <TextField
                                label="Longitude*"
                                variant="outlined"
                                className="custom-outline-form-control"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                type="text"
                                value={this.state.longitude}
                                fullWidth
                                onChange={this.handleChangeLong}
                                 data-test-id='longTestId'
                            />
                            <Typography className="infoTxt">*Latitude & Longitude can not be empty</Typography>
                        </Box>
                        <Button className="submitButton" disabled={this.state.latitude.trim() === '' || this.state.longitude.trim() === ''} onClick={this.checkLatLongValuesSubmit} >Save</Button>
                    </Box>
                </Box>
            </LatLongViewWrapper>
        )
    };

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { userName, email, mobileNumber, bio } = this.abstractSellerUserData();
        const { accountNumber, accounHolderName, bankName, ifscCode, bankList, bankNameSelect } = this.abstractSellerBankingData()
        const { primaryCategory, subCategory, gstPanNumber, businessLicenceNumber, businessType, businessLegalDocumentFileName } = this.abstractSellerBusinessData()
        const { businessName, profilephoto, role } = this.abstractSellerData()
        const { sellerBankDetail, countryList, pickUpStateList, pickUpCityList, pickUpcountry, pickUpstate, pickUpcity, dropOffStateList, dropOffCityList, dropOffstate, dropOffcity, dropOffcountry ,sellerProfileData} = this.state
        const isAllRead = this.state.allNotifications.every(notification => notification.attributes.is_read);
        const {classes} = this.props
        const upiId = sellerBankDetail?.upi_id
        const qrCodeUrl = `upi://pay?pa=${upiId}` 
        const profileUrl =  frameworkConfigJSON.baseURL  + "/visit_store/" + sellerProfileData?.id
        // Customizable Area End
        return (
            // Customizable Area Start
            <div className="main-div" data-test-id="sellerProfileEditMainContainer">
                <div className="dashboard-outer">
                    <div className="dashboard-inner">
                    <SnackBarAlert data-test-id="snackBarCloseTestId" snackBarProps={this.state.snackBarStates} handleCloseSnack={this.handleCloseSnack} />
                        <SellerSidebar
                            navigation={undefined}
                            id={""}
                            isOpen={this.state.isOpen}
                            data-test-id="sellerSidebar"
                            closeModal={() => this.toggleDrawer()}
                            classes={"profile"}
                            />
                        <div className="right-outer" data-test-id="mainContainer">
                            <div className="right-inner">
                                <div className="title-wrap">
                                    <div className="left-wrap title">
                                        <IconButton className="icon-btn" component={Link} to={'/SellerProfile'}>
                                            <img src={arrowLeftIc} alt="left arrow" />
                                        </IconButton>Edit Profile
                                    </div>
                                    <div className="right-wrap">
                                        <div className="btn-wrap">
                                            <div className="account-list" onClick={this.OpenAccountList}>
                                                <div className="account-title">{userName}</div>
                                                <div className="account-list-icon">{this.state.accountListOpen ? <ExpandLess /> : <ExpandMore />}</div>
                                            </div>
                                            <Popover
                                                onClose={() => this.CloseAccountList()}
                                                className="account-list-popover"
                                                open={this.state.accountListOpen}
                                                anchorEl={this.state.accountListAnchorEl}
                                                transformOrigin={{
                                                    horizontal: 'right',
                                                    vertical: 'top',
                                                }}
                                                anchorOrigin={{
                                                    horizontal: 'right',
                                                    vertical: 'bottom',
                                                }}
                                            >
                                                <div className="account-list-details">
                                                    <div className="person-detail-wrap">
                                                        <div 
                                                            className="img-wrap">
                                                            <Avatar 
                                                                src={profilephoto} 
                                                                alt="Profile" />
                                                        </div>
                                                        <div 
                                                            className="detail">
                                                            <span 
                                                                className="title">{businessName}</span>
                                                            <span className="name">{(role == 'seller' ? "Seller’s account" : 'Buyer’s account')}</span>
                                                        </div>
                                                        <div className="account-select">
                                                            <img src={fileSucessIc} alt="file success" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popover>
                                            <IconButton className="icon-btn">
                                                <img src={calendarIc} alt="calendar" />
                                            </IconButton>
                                            <IconButton className="icon-btn"  data-test-id="notificationIcon" onClick={this.handleNotificationBoxToggle}>
                                            <Badge  variant={ !isAllRead? "dot" : "standard"} color="secondary">
                                                <img src={notificationIcon} alt="notificationIcon" />
                                             </Badge>
                                            </IconButton>
                                            <IconButton className="icon-btn hamburger-btn"
                                                data-test-id="hamburger-btn"
                                                onClick={() => this.toggleDrawer()}
                                            >
                                                <img 
                                                    src={hamburgerIc} alt="hamburger" />
                                            </IconButton>
                                        </div>
                                             <>
                                                {this.state.isNotificationBoxOpen && (
                                                    <SellerNotificationModal closeNotificationBox={this.handleNotificationBoxToggle}
                                                     allNotifications={this.state.allNotifications}
                                                     selectedTab={this.state.selectedTab}
                                                     handleTabChange={this.handleTabChange} />
                                                )}
                                            </>
                                    </div>
                                </div>
                                {this.renderProfileSection()}
                                <section className="tab-user-business-section">
                                    <Tabs
                                        value={this.state.tabValueSeller}
                                        onChange={this.handleChangeSeller}
                                        className="seller-tabs-list"
                                        data-test-id="business"
                                    >
                                        <Tab label="User" disableRipple />
                                        <Tab label="Business" disableRipple />
                                    </Tabs>
                                    {this.state.tabValueSeller === 0 && (
                                        <div className="tabs-content">
                                            <div className="user-bio-wrap">
                                                <div className="user-list">
                                                    <div className="inner-wrap">
                                                        <div className="editable-form-group">
                                                            <label className="editable-label">
                                                                Username
                                                            </label>
                                                            <TextField
                                                                className="editable-control no-underline small"
                                                                value={userName}
                                                            />
                                                        </div>
                                                        <div className="editable-form-group">
                                                            <label className="editable-label seller-edit">
                                                                Email
                                                                <IconButton className="edit-icon">
                                                                    <img src={EditGraySquareIc} alt="Edit" data-test-id="changeEmailModal" onClick={() => this.handleOpenModalChangeEmail()} />
                                                                </IconButton>
                                                            </label>
                                                            <TextField
                                                                className="editable-control no-underline medium"
                                                                value={email}
                                                            />
                                                        </div>
                                                        <div className="editable-form-group">
                                                            <label className="editable-label seller-edit">
                                                                Mobile Number
                                                                <IconButton className="edit-icon" >
                                                                    <img src={EditGraySquareIc} alt="Edit" data-test-id="mobileModal" onClick={() => this.handleOpenModalMobile()} />
                                                                </IconButton>
                                                            </label>
                                                            <TextField
                                                                className="editable-control no-underline medium"
                                                                value={mobileNumber}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bio-list">
                                                    <div className="inner-wrap">
                                                        <div className="editable-form-group">
                                                            <label className="editable-label large seller-edit">
                                                                Bio
                                                                <IconButton className="edit-icon">
                                                                    <img src={EditGraySquareIc} alt="Edit" data-test-id="bioModal" onClick={() => this.handleOpenModalBio()} />
                                                                </IconButton>
                                                            </label>
                                                            <TextField
                                                                className="editable-control no-underline small"
                                                                value={bio}
                                                                multiline
                                                                maxRows={10}
                                                                InputProps={{readOnly: true}}
                                                            />
                                                        </div>
                                                    </div>
                                                   {this.renderQrGenerateSection()}
                                                </div>
                                            </div>
                                            <div className="seller-user-password">
                                                <div className="editable-form-group column">
                                                    <label className="editable-label large">
                                                        Password
                                                    </label>
                                                    <span className="passowrd-content">Remember, a strong password includes a mix of letters, numbers, and special characters. Keep your account safe and sound! </span>
                                                    <div className="btn-wrap">
                                                        <Button className="custom-button primary-yellow large" data-test-id="changePasswordModal" onClick={() => this.handleOpenModalEnterChangePassword()}>Change Password</Button>
                                                    </div>
                                                </div>
                                                <div className="seller-line"></div>
                                            </div>
                                            <div className="seller-account-removal">
                                                <div className="editable-form-group column">
                                                    <label className="editable-label large">
                                                        Account Removal
                                                    </label>
                                                    <span className="account-removal-content">Please note that this action is irreversible, and all your data will be lost. If you change your mind, you'll need to create a new account or If you'd like to take a break, you can temporarily disable your account without losing any data.</span>
                                                    <div className="btn-wrap">
                                                        <Button className="custom-button primary-red large" data-test-id="disableAccountModal" onClick={() => this.openStayTunedBox()}>Disable Account</Button>
                                                        <Button className="custom-button outline-red large" data-test-id="deleteAccountModal" onClick={() => this.openStayTunedBox()}>Delete Account</Button>
                                                    </div>
                                                </div>
                                                <div className="seller-line"></div>
                                            </div>
                                        </div>
                                    )}
                                    
                                    {this.state.tabValueSeller === 1 && (
                                        <div className="tabs-content">
                                            <div className="seller-business-banking-wrap">
                                                <div className="seller-business-banking">
                                                    <div className="banking-legal-wrap">
                                                        <div className="left-side-wrap">

                                                       { !sellerProfileData?.attributes?.upi_id   && <>
                                                                <Box className={classes.upiContainer}>
                                                                    <Typography className={classes.upiTitle}>
                                                                        {configJSON.upi}
                                                                    </Typography>

                                                                    <Box className={classes.addUPIContainer}>
                                                                        <img src={addUPIPlusIcon} alt="addUPIPlusIcon" onClick={this.handleAddUPIModal}/>
                                                                        <Typography className={classes.addUpiHelperText}>
                                                                            {configJSON.addUpiHelperText}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                        </>}
                                                        { !!sellerBankDetail?.upi_id   &&
                                                        <>
                                                                <Box className={classes.upiDetailsContainer}>
                                                                    <Box className={classes.editButtonContainer}>
                                                                        <IconButton>
                                                                            <img  data-test-id="editPaymentQr" src={EditGraySquareIc} alt="Edit" onClick={() => this.handleAddUPIModal()} />
                                                                        </IconButton>
                                                                    </Box>
                                                                    <Box className={classes.upiDetailsInnerContainer}>
                                                                        <Box >
                                                                        <QRCode value={qrCodeUrl} logoImage={qrLogo} size={150}/>
                                                                        </Box>
                                                                        <Divider orientation="vertical" flexItem className={classes.divider}/>
                                                                        <Box>
                                                                        <Typography className={classes.upiIdText}>{configJSON.upiID}</Typography>
                                                                        <Typography className={classes.sellerUpiID} ref={this.upiIdText}>{upiId}</Typography>
                                                                        <Box className={classes.upiActionsContainer}>
                                                                        <Typography  data-test-id="copyUPIId"  onClick={this.handleCopyUPI}  className={classes.upiActionsBox}><img className={classes.upiActionsIcon} src={copy} alt="copy"/>{configJSON.copyId}</Typography>
                                                                        </Box>
                                                                        </Box>

                                                                    </Box>
                                                                </Box>
                                                        </>}
                                                        
                                                        <div className="editable-form-group column">
                                                        <label className="editable-label large">
                                                            Banking Details
                                                        </label>
                                                    </div>
                                                            <div className="banking-list-wrap">
                                                                <div className="banking-list">
                                                                    <div className="editable-form-group">
                                                                        <label className="editable-label seller-edit" htmlFor="accountNumber">
                                                                            Account number
                                                                            <div className="plus-edit-icon">
                                                                                <IconButton className="edit-icon">
                                                                                    <img src={this.state.sellerBankDetail ? EditGraySquareIc : AddGraySquareIc} data-test-id="bankingDetailModal" alt="Edit" onClick={() => this.handleOpenModalBankingDetail()}/>
                                                                                </IconButton>
                                                                            </div>
                                                                        </label>
                                                                        <span className="field-detail">{accountNumber}</span>
                                                                    </div>
                                                                    <div className="editable-form-group">
                                                                        <label className="editable-label">
                                                                            Account holder name
                                                                        </label>
                                                                        <span className="field-detail">{accounHolderName}</span>
                                                                    </div>
                                                                    <div className="editable-form-group">
                                                                        <label className="editable-label">
                                                                            Bank name
                                                                        </label>
                                                                        <span className="field-detail">{bankName}</span>
                                                                    </div>
                                                                    <div className="editable-form-group">
                                                                        <label className="editable-label">
                                                                            IFCS Code
                                                                        </label>
                                                                        <span className="field-detail">{ifscCode}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="seller-business-legal-wrap">
                                                                <div className="seller-business-legal">
                                                                    <div className="editable-form-group column">
                                                                        <label className="editable-label large">
                                                                            Business & Legal
                                                                        </label>
                                                                    </div>
                                                                    <div className="business-legal-wrap">
                                                                        <div className="left-inner">
                                                                            <div className="editable-form-group">
                                                                                <label className="editable-label category seller-edit">
                                                                                    Primary Category
                                                                                    <IconButton className="edit-icon" >
                                                                                        <img src={this.state.sellerBusinessDetail ? EditGraySquareIc : AddGraySquareIc} data-test-id="businessLegalModal" alt="Edit" onClick={() => this.handleOpenModalBusinessLegal()}/>
                                                                                    </IconButton>
                                                                                </label>
                                                                                <span className="field-detail">{primaryCategory}</span>
                                                                            </div>
                                                                            <div className="editable-form-group">
                                                                                <label className="editable-label">
                                                                                    Sub Category
                                                                                </label>
                                                                                <span className="field-detail">{subCategory}</span>
                                                                            </div>
                                                                            <div className="editable-form-group">
                                                                                <label className="editable-label">
                                                                                    Pan Number
                                                                                </label>
                                                                                <span className="field-detail">{gstPanNumber}</span>
                                                                            </div>
                                                                            <div className="editable-form-group">
                                                                                <label className="editable-label">
                                                                                    GSTIN Number
                                                                                </label>
                                                                                <span className="field-detail">{businessLicenceNumber}</span>
                                                                            </div>
                                                                            <div className="editable-form-group">
                                                                                <label className="editable-label">
                                                                                    Business Type
                                                                                </label>
                                                                                <span className="field-detail">{businessType}</span>
                                                                            </div>
                                                                            <div className="editable-form-group">
                                                                                <label className="editable-label">
                                                                                    Business Documents
                                                                                </label>
                                                                                {this.renderBusinessDocs()}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="shipping-details">
                                                            <div className="ship-provider">
                                                                <div className="editable-form-group column">
                                                                    <label className="editable-label large">
                                                                        Shipping Provider
                                                                    </label>
                                                                </div>
                                                                <div className="ship-radio-input">
                                                                    <RadioGroup aria-label="gender" name="shipping" data-test-id="changeShipping" value={this.state.shipProvider} onChange={this.handleShipProvider}>
                                                                        <FormControlLabel value="ship_through_flixoo_live" control={<Radio />} label="Ship through Flixoo Live" />
                                                                        <FormControlLabel value="self_ship" control={<Radio />} label="Self ship" />
                                                                    </RadioGroup>
                                                                </div>
                                                            </div>
                                                            <div className="shipping-address">
                                                                <div className="editable-form-group column">
                                                                    <label className="editable-label large">
                                                                        Shipping Address
                                                                    </label>
                                                                </div>
                                                                <div className="ship-address-content">
                                                                    <span>
                                                                        Turn this on if you want the same address for pickup and drop-off.
                                                                    </span>
                                                                    <Switch
                                                                        name="customSwitch"
                                                                        className="io-switch-switchBase"
                                                                        checked={this.state.sameDropOff}
                                                                        data-test-id="sameDropOff"
                                                                        onChange={this.handleDroppOffSwitch}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="pickup-address">
                                                                <div className="right-wrap">
                                                                    <div className="editable-form-group">
                                                                        <label className="editable-label seller-edit large">
                                                                            Pickup Address
                                                                            <IconButton className="edit-icon">
                                                                                <img src={EditGraySquareIc} alt="Edit" data-test-id="pickupAddressModal" onClick={() => this.handleOpenModalPickUpAddress()} />
                                                                            </IconButton>
                                                                        </label>
                                                                        <TextField
                                                                            className="editable-control no-underline small"
                                                                            value={this.returnBuyerAdderss()}
                                                                            multiline
                                                                            maxRows={10}
                                                                        />
                                                                    </div>
                                                                    <div className="editable-form-group">
                                                                        <label className="editable-label large">
                                                                            Drop- off address
                                                                        </label>
                                                                        <TextField
                                                                            className="editable-control no-underline small"
                                                                            value={this.returnBuyerDropOffAdderss()}
                                                                            multiline
                                                                            maxRows={10}
                                                                        />
                                                                    </div>
                                                                    <div className="editable-form-group">
                                                                        <div className="pickup-address-switch">
                                                                            <span className="switch-title">Enable Cash on delivery</span>
                                                                            <Switch
                                                                                name="customSwitch"
                                                                                className="io-switch-switchBase"
                                                                            />
                                                                        </div>
                                                                        <div className="pickup-address-switch second">
                                                                            <span className="switch-title">Enable Pre paid order</span>
                                                                            <Switch
                                                                                name="customSwitch"
                                                                                className="io-switch-switchBase"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                <StoreLocator navigation={this.props.navigation} id={""}/>
                                                            }                                                           {this.renderLatLongView()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    open={this.state.openModalChangeEmail}
                    data-test-id="changeEmailClose"
                    className="custom-dialog other-dialog"
                    onClose={() => this.handleCloseModalChangeEmail()}>
                    <DialogTitle className="title-wrap gray-text">
                        <span>Change email Id</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseChangeEmail"
                            onClick={() => this.handleCloseModalChangeEmail()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">
                                Enter your email Id to verify
                            </p>
                            <div 
                                className="custom-form-group">
                                <TextField
                                    label="Email id"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    data-test-id="changeEmailText"
                                    onChange={this.handleChangeEmail}
                                />
                                {this.emailError()}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="dialogChangeEmailOtpBtn"
                            onClick={() => this.checkValidationModalEmail()}
                            className="custom-button primary-yellow large full-width"
                        >
                            Send OTP
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    className="custom-dialog other-dialog"
                    open={this.state.openModalEmailOtp}
                    data-test-id="emailOtpClose"
                    onClose={() => this.handleCloseModalEmailOtp()}
                    >
                    <DialogTitle className="title-wrap gray-text">
                        <span>Verify email</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseEmailOtp"
                            onClick={() => this.handleCloseModalEmailOtp()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">
                                Enter the OTP that was sent to your email address.
                            </p>
                            <div className="custom-form-group">
                                <OtpInputField value={this.state.otpStateEmail} valueLength={6} data-test-id="emailOtp" onChange={this.handleOTPStateEmail} />
                                {this.otpError()}
                            </div>
                            <OuterWrapper>
                                <span onClick={()=>this.updateSellerEmail()} className="custom-primary-link">Send the code again</span>
                                {this.state.isOtpSent && <OtpSpan>OTP Sent Successfully</OtpSpan>}
                            </OuterWrapper>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="dialogEmailOtpVeifyBtn"
                            onClick={() => this.checkValidationOtpEmail()}
                            className="custom-button primary-yellow large full-width"
                        >
                            Verify
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.isEmailUpdate}
                    className="custom-dialog other-dialog"
                    data-test-id="emailCloseSuccessModal"
                    onClose={() => this.closeEmailSuccessModal()}>
                    <DialogTitle className="title-wrap gray-text">
                        <span>Email Updated Successfully</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseEmailSuccess"
                            onClick={() => this.closeEmailSuccessModal()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                </Dialog>
                <Dialog
                    open={this.state.openModalMobile}
                    className="custom-dialog other-dialog"
                    data-test-id="mobileClose"
                        onClose={() => this.handleCloseModalMobile()}>
                    <DialogTitle className="title-wrap gray-text">
                        <span>Change Mobile number</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseMobile"
                            onClick={() => this.handleCloseModalMobile()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">
                                Enter your 10 digit mobile number to get OTP to your register mobile number
                            </p>
                            <div className="custom-form-group">
                                <div className="country-code-wrap">
                                    <div className="country-wrapper">
                                        <FormControl variant="outlined" className="custom-outline-form-control">
                                            <InputLabel>Code</InputLabel>
                                            <Select
                                                label="Code"
                                                data-test-id="countryChange"
                                                onChange={this.handleChangeCountry}
                                                value={this.state.countrySelectedValue.value}
                                            >
                                              {this.state.dataSource.map((item, index: number) => (
                                                    <MenuItem value={item.attributes.country_code} data-test-id={`countryValue${index}`} key={index}>
                                                        <CountrySpan>{item.attributes.emoji_flag}</CountrySpan>
                                                        {item.id} {item.attributes.country_code}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="number-wrapper">
                                        <TextField
                                            label="Mobile number"
                                            variant="outlined"
                                            className="custom-outline-form-control"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={this.state.mobileNumber}
                                            type="number"
                                            data-test-id="mobileNumberText"
                                            onChange={this.handleMobileNumberChange}
                                        />
                                        {this.phoneError()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="mobileSendOtp"
                            onClick={() => this.checkValidationModalMobile()}
                            className="custom-button primary-yellow large full-width"
                        >
                            Send OTP
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    className="custom-dialog other-dialog"
                    open={this.state.openModalMobileOtp}
                    data-test-id="mobileOtpClose"
                    onClose={() => this.handleCloseModalMobileOtp()}
                    >
                    <DialogTitle className="title-wrap gray-text">
                        <span>Verify mobile number</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseMobileOtp"
                            onClick={() => this.handleCloseModalMobileOtp()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">
                                Enter the OTP that was sent to your mobile number
                            </p>
                            <div className="custom-form-group">
                                <OtpInputField value={this.state.otpStateMobile} valueLength={6} data-test-id="mobileOtp" onChange={this.handleOTPStateMobile} />
                                {this.otpError()}
                            </div>
                            <OuterWrapper>
                            <span onClick={()=>this.updateSellerMobile()} className="custom-primary-link">Send the code again</span>
                                {this.state.isOtpSent && <OtpSpan>OTP Sent Successfully</OtpSpan>}
                            </OuterWrapper>
                            
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="dialogMobileOtpVerifyBtn"
                            onClick={() => this.checkValidationOtpMobile()}
                            className="custom-button primary-yellow large full-width"
                        >
                            Verify
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.isMobileUpdate}
                    className="custom-dialog other-dialog"
                    data-test-id="mobileCloseSuccessModal"
                    onClose={() => this.closeMobileSuccessModal()}>
                    <DialogTitle className="title-wrap gray-text">
                        <span>Mobile Number Updated Successfully</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseMobileSuccess"
                            onClick={() => this.closeMobileSuccessModal()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                </Dialog>
                <Dialog
                    open={this.state.openModalEnterChangePassword}
                    className="custom-dialog other-dialog"
                    data-test-id="changePasswordClose"
                    onClose={() => this.handleCloseModalEnterChangePassword()}
                    >
                    <DialogTitle className="title-wrap gray-text">
                        <span>Create new password</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseChangePassword"
                            onClick={() => this.handleCloseModalEnterChangePassword()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">
                                Your password must be at least six characters and should include a combination of numbers, letters and special characters (!$@%).
                            </p>
                            {this.renderPasswordCurrentFields()}
                            {this.renderPasswordNewFields()}
                            {this.renderPasswordReNewFields()}
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="dialogChangePasswordSaveBtn"
                            onClick={() => this.checkValidationPasswordChange()}
                            className="custom-button primary-yellow large full-width"
                        >
                            Save changes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openModalPasswordSuccessfull}
                    data-test-id="passwordSucessClose"
                    onClose={() => this.handleCloseModalPasswordSuccessfull()}
                    className="custom-dialog success-dialog"
                >
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="img-wrap">
                                <img src={SuccessIc} alt="Success" />
                            </div>
                            <div className="content-wrap">
                                <span className="title">Successfully added</span>
                                <div className="content">
                                    <p>Your password has been successfully changed.</p>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.openModalDisableAccout}
                    data-test-id="disableAccountClose"
                    className="custom-dialog other-dialog"
                    onClose={() => this.handleCloseModalDisableAccount()}>
                    <DialogTitle className="title-wrap gray-text">
                        <span>Disable your account ?</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseDisableAccount"
                            onClick={() => this.handleCloseModalDisableAccount()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">
                                Are you sure you want to disable your account?
                            </p>
                            <div className="listing">
                                <span className="list-title">
                                    While your account is disabled:
                                </span>
                                <ul>
                                    <li>Your profile will be hidden.</li>
                                    <li>You won't receive any notifications.</li>
                                    <li>You can reactivate anytime.</li>
                                </ul>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="dialogDisableAccountCancelBtn"
                            onClick={() => this.handleCloseModalDisableAccount()}
                            className="custom-button transparent-button"
                        >
                            Cancel
                        </Button>
                        <Button
                            className="custom-button primary-red dialog large"
                        >
                            Disable
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    data-test-id="deleteAccountClose"
                    open={this.state.openModalDeleteAccount}
                    onClose={() => this.handleCloseModalDeleteAccount()}
                    className="custom-dialog other-dialog">
                    <DialogTitle className="title-wrap gray-text">
                        <span>Delete your account permanently ?</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseDeleteAccount"
                            onClick={() => this.handleCloseModalDeleteAccount()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">
                                This action is irreversible!
                            </p>
                            <div className="listing">
                                <ul>
                                    <li>All your data will be permanently erased.</li>
                                    <li>You won't be able to recover your account.</li>
                                    <li>All your content and history will be lost.</li>
                                </ul>
                            </div>
                            <div className="listing">
                                <span className="list-title">
                                    Please type your password to confirm:
                                </span>
                            </div>
                            <div className="custom-form-group">
                                <TextField
                                    label="Password"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="password"
                                    data-test-id="deleteAccountPasswordText"
                                    onChange={this.handlePasswordDeleteAccount}
                                />
                                {this.pwdError()}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="deleteAccountCancelBtn"
                            onClick={() => this.handleCloseModalDeleteAccount()}
                            className="custom-button transparent-button"
                        >
                            Cancel
                        </Button>
                        <Button
                            data-test-id="deleteAccountDeleteBtn"
                            className="custom-button transparent-button red-btn"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    onClose={() => this.handleCloseModalBankingDetail()}
                    data-test-id="bankingDetailClose"
                    className="custom-dialog other-dialog"
                    open={this.state.openModalBankingDetail}>
                    <DialogTitle className="title-wrap gray-text">
                        <span>Banking details</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseBankingDetail"
                            onClick={() => this.handleCloseModalBankingDetail()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">
                                {configJSON.bankingDetails}
                            </p>
                            <div className="custom-form-group">
                                <TextField
                                    label="Bank Account Number"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="number"
                                    value={this.state.bankAccountNumber}
                                    data-test-id="bankAccNumText"
                                    onChange={this.handleChangeBankAccountNumber}
                                />
                                {this.bankAccNumError()}
                            </div>
                            <div className="custom-form-group">
                                <TextField
                                    label="Account Holder Name"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.bankAccountHolderName}
                                    data-test-id="bankAccHolderNameText"
                                    onChange={this.handleChangeBankAccountHolderName}
                                    type="text"
                                />
                                {this.bankAccHoldNameError()}
                            </div>
                            <div className="custom-form-group">
                                <FormControl className="custom-outline-form-control select-menu">
                                <InputLabel id="demo-simple-select-helper-label">Bank Name</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    variant="outlined"
                                    label="Bank Name"
                                    data-test-id="bankNameText"
                                    id="demo-simple-select-helper"
                                    value={bankNameSelect!== "" && bankList.find((option: any) => option.name.toLowerCase() == bankNameSelect.toLowerCase())?.name}
                                    onChange={this.handleMenuItemClick}
                                >
                                    {bankList.map((option: any) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.name}
                                        >
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                </FormControl>
                                {this.bankNameError()}
                            </div>
                            <div className="custom-form-group">
                                <TextField
                                    label="IFSC Code"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.bankAccountIfscCode}
                                    data-test-id="bankAccIfscText"
                                    onChange={this.handleChangeBankAccountIfscCode}
                                />
                                {this.bankAccountIfscCodeError()}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="dialogChangeBankDetailValidateBtn"
                            onClick={() => this.checkValidationBankDetails()}
                            className="custom-button primary-yellow large full-width"
                        >
                            Verify
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    className="custom-dialog other-dialog"
                    data-test-id="bankOtpClose"
                    open={this.state.openModalBankOtp}
                    onClose={() => this.handleCloseModalBankOtp()}>
                    <DialogTitle className="title-wrap gray-text">
                        <span>Verify bank details</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseBankOtp"
                            onClick={() => this.handleCloseModalBankOtp()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">
                                {configJSON.otpForRegisteredNumber}
                            </p>
                            <div className="custom-form-group">
                                <OtpInputField value={this.state.otpStateBankAccount} valueLength={6} data-test-id="bankOtp" onChange={this.handleOTPStateBankAccount} />
                                {this.otpError()}
                            </div>
                            <span data-test-id="resendBankOtp" onClick={()=>this.resendBankOtp()} className="custom-primary-link">{configJSON.sendCodeAgain}</span>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="dialogBankOtpVeifyBtn"
                            onClick={() => this.checkValidationOtpBank()}
                            className="custom-button primary-yellow large full-width"
                        >
                            Verify
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openModalBankingSuccessfull}
                    data-test-id="bankSucessClose"
                    onClose={() => this.handleCloseModalBankingSuccessfull()}
                    className="custom-dialog success-dialog"
                >
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="img-wrap">
                                <img src={SuccessIc} alt="Success" />
                            </div>
                            <div className="content-wrap">
                                <span className="title">Successfully added</span>
                                <div className="content">
                                    <p>Your bank account have been successfully added</p>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    data-test-id="pickupAddressClose"
                    open={this.state.openModalPickUpAddress}
                    className="custom-dialog other-dialog"
                    onClose={() => this.handleCloseModalPickUpAddress()}>
                    <DialogTitle className="title-wrap gray-text">
                        <span>Change pick up address</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogClosePickupAddress"
                            onClick={() => this.handleCloseModalPickUpAddress()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">
                                Update your pick-up address by entering it.
                            </p>
                            <div className="custom-form-group shipping-group">
                                <div>
                                    <span className="shipping-title">Shipping Address</span>
                                    <p className="shipping-content">Turn this on if you want the same address for pickup and drop-off.</p>
                                </div>
                                <div>
                                    <Switch
                                        name="customSwitch"
                                        className="io-switch-switchBase"
                                        checked={this.state.sameDropOff}
                                        data-test-id="sameDropOff"
                                        onChange={this.handleDroppOffSwitch}
                                    />
                                </div>
                            </div>
                            <div className="custom-form-group">
                                <TextField
                                    label="Address line 1"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.pickUpaddressline1}
                                    data-test-id="pickupaddress1Text"
                                    onChange={this.handleChangePickUpAddressline1}
                                />
                                {this.pickUpaddressline1Error()}
                            </div>
                            <div className="custom-form-group">
                                <TextField
                                    label="Address line 2"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.pickUpaddressline2}
                                    data-test-id="pickupaddress2Text"
                                    onChange={this.handleChangePickUpAddressline2}
                                />
                                {this.pickUpaddressline2Error()}
                            </div>
                            <div className="custom-form-group">
                                <TextField
                                    label="Pin Code"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.pickUppincode}
                                    data-test-id="pickuppincodeText"
                                    onChange={this.handleChangePickUpPincode}
                                    type="number"
                                />
                                {this.pickUppincodeError()}
                            </div>
                            <div className="custom-form-group">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={countryList.map((item: any) => item.name)}
                                    getOptionLabel={(option: any) => option}
                                    style={{ width: '100%', maxWidth: '509px' }}
                                    onBlur={this.ValidatePickUpCountry}
                                    onChange={this.handlePickUpCountryChange}
                                    value={pickUpcountry}
                                    data-test-id="pickupcountryText"
                                    className="auto-complete-list"
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            placeholder="Country"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <ExpandMoreIcon style={{ height: '32px', width: '32px' }} />
                                                ),
                                            }}
                                        />)}
                                />
                                {this.pickUpcountryError()}
                            </div>
                            <div className="custom-form-group">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={pickUpStateList.map((item: any) => item.name)}
                                    getOptionLabel={(option: any) => option}
                                    style={{ width: '100%', maxWidth: '509px' }}
                                    onBlur={this.ValidatePickUpState}
                                    value={pickUpstate}
                                    className="auto-complete-list"
                                    data-test-id="pickupstateText"
                                    disabled={pickUpStateList.length === 0}
                                    onChange={this.handlePickUpStateChange}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            placeholder="State"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <ExpandMoreIcon style={{ height: '32px', width: '32px' }} />
                                                ),
                                            }}
                                        />)}
                                />
                                {this.pickUpstateError()}
                            </div>
                            <div className="custom-form-group">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={pickUpCityList}
                                    getOptionLabel={(option: any) => option}
                                    onBlur={this.ValidatePickUpCity}
                                    onChange={this.handlePickUpCityChange}
                                    value={pickUpcity}
                                    data-test-id="pickupcityText"
                                    className="auto-complete-list"
                                    disabled={pickUpCityList.length === 0}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            placeholder="City"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <ExpandMoreIcon style={{ height: '32px', width: '32px' }} />
                                                ),
                                            }}
                                        />)}
                                />
                                {this.pickUpcityError()}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="dialogPickupAddressValidateBtn"
                            onClick={() => this.checkValidationPickUpAddressChange()}
                            className="custom-button primary-yellow large full-width"
                        >
                            Verify
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openModalPickUpSuccessfull}
                    data-test-id="pickupSucessClose"
                    onClose={() => this.handleCloseModalPickUpSuccessfull()}
                    className="custom-dialog success-dialog"
                >
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="img-wrap">
                                <img src={SuccessIc} alt="Success" />
                            </div>
                            <div className="content-wrap">
                                <span className="title">Successfully added</span>
                                <div className="content">
                                    <p>Your pick-up address have been successfully added.</p>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    onClose={() => this.handleCloseModalDropOffAddress()}
                    open={this.state.openModalDropOffAddress}
                    data-test-id="dropoffAddressClose"
                    className="custom-dialog other-dialog">
                    <DialogTitle className="title-wrap gray-text">
                        <span>Change drop off address</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseDropoffAddress"
                            onClick={() => this.handleCloseModalDropOffAddress()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">
                                Update your drop off address by entering it.
                            </p>
                            <div className="custom-form-group">
                                <TextField
                                    label="Address line 1"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.dropOffaddressline1}
                                    data-test-id="dropoffaddress1Text"
                                    onChange={this.handleChangeDropOffAddressline1}
                                />
                                {this.dropOffaddressline1Error()}
                            </div>
                            <div className="custom-form-group">
                                <TextField
                                    label="Address line 2"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.dropOffaddressline2}
                                    data-test-id="dropoffaddress2Text"
                                    onChange={this.handleChangeDropOffAddressline2}
                                />
                                {this.dropOffaddressline2Error()}
                            </div>
                            <div className="custom-form-group">
                                <TextField
                                    label="Pin Code"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.dropOffpincode}
                                    data-test-id="dropoffpincodeText"
                                    onChange={this.handleChangeDropOffPincode}
                                    type="number"
                                />
                                {this.dropOffpincodeError()}
                            </div>
                            <div className="custom-form-group">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={countryList.map((item: any) => item.name)}
                                    getOptionLabel={(option: any) => option}
                                    style={{ width: '100%', maxWidth: '509px' }}
                                    onBlur={this.ValidateDropOffCountry}
                                    onChange={this.handleDropOffCountryChange}
                                    value={dropOffcountry}
                                    data-test-id="dropoffcountryText"
                                    className="auto-complete-list"
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            placeholder="Country"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <ExpandMoreIcon style={{ height: '32px', width: '32px' }} />
                                                ),
                                            }}
                                        />)}
                                />
                                {this.dropOffcountryError()}
                            </div>
                            <div className="custom-form-group">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={dropOffStateList.map((item: any) => item.name)}
                                    getOptionLabel={(option: any) => option}
                                    style={{ width: '100%', maxWidth: '509px' }}
                                    onBlur={this.ValidateDropOffState}
                                    value={dropOffstate}
                                    className="auto-complete-list"
                                    data-test-id="dropoffstateText"
                                    disabled={dropOffStateList.length === 0}
                                    onChange={this.handleDropOffStateChange}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            placeholder="State"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <ExpandMoreIcon style={{ height: '32px', width: '32px' }} />
                                                ),
                                            }}
                                        />)}
                                />
                                {this.dropOffstateError()}
                            </div>
                            <div className="custom-form-group">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={dropOffCityList}
                                    getOptionLabel={(option: any) => option}
                                    onBlur={this.ValidateDropOffCity}
                                    onChange={this.handleDropOffCityChange}
                                    value={dropOffcity}
                                    data-test-id="dropoffcityText"
                                    className="auto-complete-list"
                                    disabled={dropOffCityList.length === 0}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            placeholder="City"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <ExpandMoreIcon style={{ height: '32px', width: '32px' }} />
                                                ),
                                            }}
                                        />)}
                                />
                                {this.dropOffcityError()}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="dialogDropoffAddressValidateBtn"
                            onClick={() => this.checkValidationDropOffAddressChange()}
                            className="custom-button primary-yellow large full-width"
                        >
                            Verify
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openModalDropOffSuccessfull}
                    data-test-id="dropoffSucessClose"
                    onClose={() => this.handleCloseModalDropOffSuccessfull()}
                    className="custom-dialog success-dialog"
                >
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="img-wrap">
                                <img src={SuccessIc} alt="Success" />
                            </div>
                            <div className="content-wrap">
                                <span className="title">Successfully added</span>
                                <div className="content">
                                    <p>Your drop-off address have been successfully added.</p>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    data-test-id="businessLegalClose"
                    className="custom-dialog other-dialog"
                    open={this.state.openModalBusinessLegal}
                    onClose={() => this.handleCloseModalBusinessLegal()}>
                    <DialogTitle className="title-wrap gray-text">
                        <span>Business and legal</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseBusinessLegal"
                            onClick={() => this.handleCloseModalBusinessLegal()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <p className="gray-text">
                                Update your business and legal details by entering it.
                            </p>
                            <div className="custom-form-group">
                                <TextField
                                    label="GSTIN/ PAN No"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.businessLegalGstPan}
                                    data-test-id="businessLegalGstPanText"
                                    onChange={this.handleChangeBusinessLegalGstPan}
                                />
                                {this.businessLegalGstPanError()}
                            </div>
                            <div className="custom-form-group">
                                <TextField
                                    label="Email Id"
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.businessLegalEmail}
                                    data-test-id="businessLegalEmailText"
                                    onChange={this.handleChangeBusinessLegalEmail}
                                />
                                {this.businessLegalEmailError()}
                            </div>
                            <div className="custom-form-group">
                                <TextField
                                    label="Business licence No."
                                    variant="outlined"
                                    className="custom-outline-form-control"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.businessLegalBusinessLicence}
                                    data-test-id="businessLegalLicenceText"
                                    onChange={this.handleChangeBusinessLegalBusinessLicence}
                                />
                                {this.businessLegalBusinessLicenceError()}
                            </div>
                            <div className="custom-form-group">
                                {this.state.businessLegalDocumentFileName === "" ?
                                    <Button className="custom-button primary-yellow large">Upload
                                        <input
                                            accept="image/*"
                                            id="upload-business-licence-pic"
                                            type="file"
                                            className="upload"
                                            data-test-id="businessLegalFileUpload"
                                            onChange={this.handleBusinessLegalFile}
                                        />
                                    </Button>
                                    :
                                    <div className="file-success-wrap dashed-border">
                                        <div>
                                            <img src={GalleryIc} alt="file success" />
                                        </div>
                                        <div className="file-details">
                                            <div className="file-close-wrap">
                                                <span className="file-name">{this.state.businessLegalDocumentFileName}</span>
                                                <img src={CloseDocumentIc} alt="file close" data-test-id="businessLegalFileClear" onClick={() => this.handleBusinessLegalFileClear()} />
                                            </div>
                                            <span className="file-size">{this.state.businessLegalDocumentFileSize} KB</span>
                                        </div>
                                    </div>
                                }
                                {this.businessLegalDocumentError()}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="dialogBusinessLegalValidateBtn"
                            onClick={() => this.checkValidationBusinessLegalChange()}
                            className="custom-button primary-yellow large full-width"
                        >
                            Verify
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openModalBusinessLegalSuccessfull}
                    data-test-id="businessLegalSucessClose"
                    onClose={() => this.handleCloseModalBusinessLegalSuccessfull()}
                    className="custom-dialog success-dialog"
                >
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="img-wrap">
                                <img src={SuccessIc} alt="Success" />
                            </div>
                            <div className="content-wrap">
                                <span className="title">Successfully Requested</span>
                                <div className="content">
                                    <p>Your business details should be verified by the admin and updated soon</p>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    className="custom-dialog other-dialog"
                    data-test-id="bioClose"
                    open={this.state.openModalBio}
                    onClose={() => this.handleCloseModalBio()}>
                    <DialogTitle className="title-wrap gray-text">
                        <span>Bio</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="dialogCloseBio"
                            onClick={() => this.handleCloseModalBio()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="custom-form-group">
                                <TextField
                                    variant="standard"
                                    className="custom-outline-form-control editable-control no-underline small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    defaultValue={bio}
                                    onChange={this.handleChangeProfileBio}
                                    multiline
                                    maxRows={10}
                                    data-test-id="profileBio"
                                />
                                {this.profileBioError()}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="dialogBioSaveBtn"
                            onClick={() => this.checkValidationBioChange()}
                            className="custom-button primary-yellow large full-width"
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    data-test-id="openAddUPIModal"
                    open={this.state.openAddUPIModal}
                    onClose={() => this.handleCloseUPIModal()}

                >
                    <Box className={classes.addUPIDailog}>
                        <Box className={classes.addUPITitleContainer}>
                            <Typography className={classes.addUPITitle}>{configJSON.addNewUpiID}</Typography>
                            <img src={CloseIc} alt="Close"  onClick={() => this.handleCloseUPIModal()}/>
                        </Box>
                        <Box>
                            <TextField
                                label={configJSON.upi}
                                variant="outlined"
                                className={classes.upiTextField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={this.state.upiId}
                                onChange={this.handleupiId}
                                data-test-id="upiId"
                            />
                            {this.state.upiError && <Typography className={classes.upiError}>{this.state.upiError}</Typography>}
                        </Box>
                        <Box>
                            <Box className={classes.saveAndVerifyButtonContainer}>
                                <YellowCheckbox
                                    checked={true}
                                    disabled={true}
                                />
                                <Typography className={classes.verifyText}>{configJSON.verifyText}</Typography>
                            </Box>
                        
                        <Button  data-test-id="verifyAndSave" className={classes.verifyAndSaveButton} onClick={this.handleVerifyAndSaveButton}>{configJSON.verifyAndSave}</Button>
                        </Box>
                    </Box>
                </Dialog>
                <Dialog
                    data-test-id="openprofileQRgenerationModal"
                    open={this.state.openprofileQRgenerationModal}
                    onClose={() => this.handleCloseProfileQRgenerationModal()}
                    style={{display:"none"}}
                >
                    <Box className={classes.profileQrModal}>
                        <Box className={classes.profileQrModalClose}>
                            <img src={CloseIc} alt="Close" onClick={() => this.handleCloseProfileQRgenerationModal()} data-test-id="closeQrmodal"/>
                        </Box>
                        <Typography className={classes.profileQRcodeBox} >
                        <Typography component="span" ref={this.qrRef}>
                        <QRCode qrStyle="fluid" value={profileUrl} logoImage={qrLogo} size={150} />
                        </Typography>
                        
                        <img className={classes.flixooImage} src={flxooTextLogo} alt="flxooTextLogo"/>
                        </Typography>
                        <Box className={classes.buttonContainer}>
                        <Button  data-test-id="downloadButton" startIcon={<img src={downloadIcon}/>} className={classes.downloadButton} onClick={this.handledownloadButton}>{configJSON.download}</Button>
                        <Tooltip 
                         title="Copied!" 
                         open={this.state.tooltipCopyUrl} 
                         disableHoverListener
                         disableFocusListener
                         disableTouchListener
                        >
                        <Button  data-test-id="copyButton"  onClick={()=>this.handleCopyURL(profileUrl)}  startIcon={<img src={copy}/>} className={classes.copyButton}>{configJSON.copy}</Button>
                        </Tooltip>
                    </Box>
                    </Box>
                </Dialog>
                <StayTuned openTunedBox={this.state.openTunedBox} data-test-id="closeStayTuned" closeTunedBox={this.closeTunedBox} />
                <MessageModalWeb data-test-id="errorMessage" displayPopup={this.state.errorModal} closeErrorPopup={this.errorModalClose} errorMessage={this.state.imageError} />
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(webStyles)(SellerUserBusinessEdit)
const CountrySpan = styled('span')({
    fontFamily: "'Segoe UI Emoji', 'Apple Color Emoji', 'Roboto', sans-serif !important",
    marginRight: '5px',
});

const LatLongViewWrapper = styled(Box)({
    "& .titleTxt": {
        color: "#555555",
        margin: "1em 0em",
        fontSize: "1.2em",
        fontStyle: "normal",
        fontFamily: "Poppins !important",
        fontWeight: 600,
        lineHeight: "27px",
    },
    "& .paperBox": {
        padding: "16px",
        boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.10)",
        paddingRight: "0",
    },
    "& .currentLocBox": {
        display: "flex",
        alignItems: "center",
        gap: "6px"
    },
    "& .currentLocTxt": {
        textDecoration: "underline",
        fontWeight: 500,
        cursor: "pointer"
    },
    "& .currentLocIcon": {
        cursor: "pointer"
    },
    "& .inputContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        marginTop: "2rem",
        paddingRight: "20px",
    },
    "& .submitButton": {
        backgroundColor: "#f5ea16",
        textTransform: "none"
    },
    "& .infoTxt": {
        fontSize: "12px",
        marginTop: "6px",
        marginLeft: "3px",
        color: "grey"
    }
});

const OtpSpan = styled('span')({
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 500,
    color: "green",
    display: "inline-block",
    textDecoration: "none",
    cursor: "pointer",
    textAlign: "end"
});

const OuterWrapper = styled('div')({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
});

// Customizable Area End
// Customizable Area End