// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { Message } from "framework/src/Message";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

type AnyStringKeyObject = {
    [key: string]: any;
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles{
    navigation: any;
    id: string;
    // Customizable Area Start
   
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    eachProductData:AnyStringKeyObject,
    isSideBarOpen:boolean
    selectedVariantObject: AnyStringKeyObject,
    selectedVariantImages:AnyStringKeyObject[],
    selectedVariantVideos: AnyStringKeyObject[],
    loading:boolean
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ProductDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getProductDetailsId :string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.NavigationScreenNameMessage)
            // Customizable Area End
        ];

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            eachProductData:{},
            isSideBarOpen:true,
            selectedVariantObject: {},
            selectedVariantImages:[],
            selectedVariantVideos: [],
            loading:false
            // Customizable Area End
        };

    }


    // Customizable Area Start

    async componentDidMount() {
        const array = window.location.pathname.split("/");
        const id = array[array.length - 1];
        this.getProductDetails(id)
        removeStorageData("addVariantCatalogueID");
        removeStorageData("editVariantCatalogueID");
        removeStorageData("editVarientID");
        removeStorageData("activeStepVariant")
      }

   

    async receive(from: string, message: Message) {
        if (this.getProductDetailsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
      
            if (apiResponse?.errors) {
              alert(apiResponse?.errors)
            } else {
              this.setState({
                eachProductData: apiResponse.data,
                loading:false
              })
            }
          }
    }

    getProductDetails= async(catalogueId:string)=>{      
        this.setState({
            loading:true
        })
        const singupLogin =  await getStorageData("singupLogin");
    
        const endPoint = `${configJSON.getSellerCatalogueDescription}/${catalogueId}`
        const headers = {
            token: singupLogin,
        };

        const getInventoryListMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getProductDetailsId = getInventoryListMsg.messageId;
    
        getInventoryListMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
        );
        getInventoryListMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getInventoryListMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(getInventoryListMsg.id, getInventoryListMsg);
    
    }

    handleSideBarOpenClose = () => {
        this.setState({ isSideBarOpen: !this.state.isSideBarOpen })
    }

    handleVariantColorClick = (variantObject:AnyStringKeyObject) => {
        this.setState({ 
            selectedVariantObject: variantObject,
            selectedVariantImages: variantObject.catalogue_variant_images,
            selectedVariantVideos: variantObject.catalogue_variant_videos
        });
    };

    handleClickOfEachProduct =(itemId:string)=>{
        setStorageData("editVariantCatalogueID", itemId)
        setStorageData("editVarientID", this.state.selectedVariantObject.catalogue_variant_color_id)
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "AddProductVariants");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), itemId);
        this.send(message)
    }
    handleEditProduct = () => {
        const array = window.location.pathname.split("/");
        const id = array[array.length - 1];
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "EditProduct");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
        this.send(message)
    }
    handleBackNavigation=()=>{
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage),"InventoryManagement");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

   
    // Customizable Area End
}