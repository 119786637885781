import React from "react";
// Customizable Area Start
import ListingProductController ,{Props,configJSON} from "./ListingProductController.web";
import { createTheme,  withStyles, Theme,createStyles } from "@material-ui/core/styles";
import {Box,Grid,TextField,Checkbox,Typography,Button,Switch,SelectProps} from "@material-ui/core";
import MessageModalWeb from "../../../components/src/MessageModal.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    }
});

export const webStyles = (theme: Theme) => ({
    listingContainer:{
        padding:"0em 4em 5em",
    },
    saveAndButton:{
        width: '30%',
        height: '3em',
        background: '#F5EA16',
        borderRadius:"25px",
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        pointer:"cursor",
        textTransform:"none" as const,
        "&:hover":{
            background: '#F5EA16',
        }
    },
    listingTitle:{
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        margin:"2em 0em 1em"
    },
    textFieldStyle:{
        height:"3.5em",
        margin:"0.5em 0em",
        borderRadius:"40px"
    },
    formControl_Option_Selected: {
      height:"3.5em",
      color: "#555",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontStyle: "normal",
      margin:"1em 0em",
      fontWeight: 500,
      lineHeight: "normal",
      "& .MuiSelect-select": {
        color: "rgba(0, 0, 0, 0.54)",
          height: "fit-content !important",
          background:"transparent",
      },
      "& .MuiListItem-root": {
          height: "100%",
      },
      "@media (max-width: 560px)": {
          width: "100%",
      },
      "& .MuiMenuItem-root:hover": {
        color: "lightgrey !important",
      },
      "& .MuiSelect-icon":{
        color:"rgba(0, 0, 0, 0.3)"
      }
  },
    textAreaField: {
      height:"3.5em",
      margin:"0.5em 0em",
      borderRadius:"40px",
      "& .MuiInputBase-root": {
        height: "100% !important",
         borderRadius:"10px",
        "& .MuiInputBase-input": {
          overflow: "auto !important",
          height: "100% !important",
        },
      },
    },
    switchContainer:{
       display:"flex",
       justifyContent:"space-between"
    },
    switchTitle:{
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        opacity: 0.7,
        margin:"0.9em 0em"
    },
    switchDescription:{
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        margin:"0.2em 0em"
    },
    nobrandName:{
        color: 'rgba(68, 68, 68, 0.70)',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: 'normal',
    },
    nobrandNameCheckbox:{
     marginLeft:"-12px"
    },
    saveAndButtonContainer:{
      display:"flex",
      justifyContent:"flex-end",
      width:"100%",
      margin:"-5em 0px 0px 3em",
      [theme.breakpoints.down('md')]: {
        margin:"1em"
      },
    },
    productDescriptionContainer:{
     marginBottom:"3em"
    },
    errorStyle:{
      color:"red",
      fontSize:"12px",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    specifications:{
         color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        margin:"15px 0"
    }



});
const menuProps: Partial<SelectProps> = {
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: 200,
        width: 'auto',
      },
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  disablePortal: true,
  },
};
export const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 49,
      height: 27,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: "3px 0 3px 4px",
      '&$checked': {
        transform: 'translateX(26px)',
        padding: "4px 0",
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#FFFF00', 
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        backgroundColor: 'black', 
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 20,
      height: 20,
      backgroundColor: '#444444 !important', 
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: any) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});


// Customizable Area End

export class ListingProduct extends ListingProductController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
        const {classes} = this.props
        const { switchTitles, switchDescriptions, askPriceLabel } = configJSON;
        const {specifications,dynamicSpecifications} = this.state
        return (
          <Box data-test-id="listingMainContainer" className={classes.listingContainer}>
            <MessageModalWeb
              data-test-id="messageModal"
              displayPopup={this.state.isPopupVisible}
              closeErrorPopup={this.handleClosemessageModal}
              errorMessage={this.state.popupMessage}
            />
            <Typography className={classes.listingTitle}>{configJSON.listingTitle}</Typography>
           <Grid container spacing={4}> 
            <Grid item xs={12} md={12} lg={8}>
                    <TextField
                    fullWidth
                    data-test-id="skuId"
                    label={configJSON.skuId}
                    variant="outlined"
                    value={this.state.skuId}
                    onChange={this.handleTextFieldChange("skuId")}
                    className={classes.textFieldStyle}
                    />
                     <Box component="span" className={classes.errorStyle}>{this.state.skuIdValidation}</Box>
                    <TextField
                    fullWidth
                    data-test-id="productTitle"
                    label={configJSON.productTitle}
                    variant="outlined"
                    value={this.state.productTitle}
                    onChange={this.handleTextFieldChange("productTitle")}
                    className={classes.textFieldStyle}
                    />
                     <Box component="span" className={classes.errorStyle} data-test-id="errorMessage">{this.state.productTitleValidation}</Box>
                     <TextField
                        select
                        fullWidth
                        data-test-id="primaryCategory"
                        label={configJSON.selectYourPrimaryCategory}
                        value={this.state.primaryCategory}
                        onChange={this.handleSelectChange("primaryCategory")}
                        variant="outlined"
                        className={classes.formControl_Option_Selected}
                        SelectProps={{
                          native: true,
                        }}
                    >
                        <option disabled value="default" style={{color:"rgba(0, 0, 0, 0.54)"}}>
                           {configJSON.selectAnyOne}
                        </option>
                        {this.state.categoriesList?.map(category => (
                            <option key={category.id} value={category.id}  data-test-id="categoryItem">
                                {category.attributes.name}
                            </option>
                        ))}
                    </TextField>
                    <Box component="span" className={classes.errorStyle}>{this.state.primaryCategoryValidation}</Box>
                    <TextField
                        fullWidth
                        data-test-id="subCategory"
                        label={configJSON.selectYourSubCategory}
                        value={this.state.subCategory}
                        onChange={this.handleSelectChange("subCategory")}
                        variant="outlined"
                        select
                        disabled={this.state.primaryCategory ==="default"}
                        className={classes.formControl_Option_Selected}
                        SelectProps={{
                          native: true,
                        }}
                    >
                        <option disabled value="default">
                          {configJSON.selectAnyOne}
                        </option>
                        {this.state.subCategoriesList?.map(subCategory => (
                            <option key={subCategory.id} value={subCategory.id} data-test-id="subCategoryItem">
                                {subCategory.attributes.name}
                            </option>
                        ))}
                    </TextField>
                    <Box component="span" className={classes.errorStyle}>{this.state.subCategoryValidation}</Box>
                    <TextField
                        fullWidth
                        data-test-id="brand"
                        label="Brand"
                        variant="outlined"
                        value={this.state.brand}
                        onChange={this.handleTextFieldChange("brand")}
                        className={classes.textFieldStyle}
                        disabled={this.state.isbrandNameChecked}
                        />  
                    <Box component="span" className={classes.errorStyle}>{this.state.brandValidation}</Box>
                    <Box>
                        <Checkbox
                               data-test-id="isnotbrand"
                                color="default"
                                inputProps={{ 'aria-label': 'checkbox with default color' }}
                                className={classes.nobrandNameCheckbox}
                                onChange={this.handleCheckboxChange("isbrandNameChecked")}
                                value={this.state.isbrandNameChecked}
                        />
                        <Typography component="span" className={classes.nobrandName}>{configJSON.nobrandName}</Typography>    
                    </Box>
                    <Box className={classes.productDescriptionContainer}>
                    <TextField
                        fullWidth
                        data-test-id="productDescription"
                        label="Product description"
                        variant="outlined"
                        value={this.state.productDescription}
                        onChange={this.handleTextFieldChange("productDescription")}
                        className={classes.textAreaField}
                        multiline
                        InputProps={{
                          style: { minHeight: "100px" },
                        }}
                    />
                    </Box>
                    <Box component="span" className={classes.errorStyle}>{this.state.productDescriptionValidation}</Box>
                    
                    <TextField
                        fullWidth
                        data-test-id="statusDetails"
                        label="Status details"
                        value={this.state.statusDetails}
                        onChange={this.handleSelectChange("statusDetails")}
                        variant="outlined"
                        select
                        className={classes.formControl_Option_Selected}
                        SelectProps={{
                          native: true,
                        }}
                    >
                        <option disabled value="default">
                          {configJSON.selectAnyOne}
                        </option>
                        {configJSON.statusDetails?.map((eachObject:any) => (
                            <option key={eachObject.id} value={eachObject.value} data-test-id="eachObject">
                                {eachObject.value}
                            </option>
                        ))}
                    </TextField> 

                    <Box component="span" className={classes.errorStyle}>{this.state.statusDetailsValidation}</Box>          
                    <TextField
                        fullWidth
                        type="number"
                        data-test-id="MRP"
                        label="MRP"
                        variant="outlined"
                        value={this.state.MRP}
                        onChange={this.handleTextFieldChange("MRP")}
                        className={classes.textFieldStyle}
                        InputLabelProps={{
                          shrink: true,
                      }}
                    />
                    <Box component="span" className={classes.errorStyle}>{this.state.MRPValidation}</Box>
                    <TextField
                        fullWidth
                        type="number"
                        data-test-id="yourSellingPrice"
                        label="Your selling price"
                        variant="outlined"
                        value={this.state.yourSellingPrice}
                        onChange={this.handleTextFieldChange("yourSellingPrice")}
                        className={classes.textFieldStyle}
                        InputLabelProps={{
                          shrink: true,
                      }}
                    />
                    <Box component="span" className={classes.errorStyle}>{this.state.yourSellingPriceValidation}</Box>
                    <Box>
                   {specifications.length !=0 && <Typography className={classes.specifications}>{configJSON.specifications}</Typography>}
                    {specifications?.map((eachSpecification: any) => (
                            <Box key={eachSpecification.id}>
                              {eachSpecification?.attributes?.specification_type === "dropdown" ? (
                                <TextField
                                 data-test-id={`dynamicdropdown${eachSpecification.id}`}
                                  select
                                  fullWidth
                                  label={eachSpecification?.attributes?.name}
                                  value={dynamicSpecifications.find((spec) => spec.id == eachSpecification.id)?.value || "default"}
                                  onChange={this.handleSpecificationChange(eachSpecification.id)}
                                  variant="outlined"
                                  className={classes.formControl_Option_Selected}
                                  SelectProps={{
                                    native: true,
                                  }}
                                >
                                  <option disabled value="default">
                                    {configJSON.selectAnyOne}
                                  </option>
                                  {eachSpecification?.attributes?.specification_data.map((option: any) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </TextField>
                              ) : (
                                <TextField
                                data-test-id={`dynamicTextField${eachSpecification.id}`}
                                  fullWidth
                                  label={eachSpecification?.attributes?.name}
                                  variant="outlined"
                                  value={dynamicSpecifications.find((spec) => spec.id == eachSpecification.id)?.value || ""}
                                  onChange={this.handleSpecificationChange(eachSpecification.id)}
                                  className={classes.textFieldStyle}
                                />
                              )}
                            </Box>
                      ))}
                      <Box component="span" className={classes.errorStyle}>{this.state.SpecificationValidation}</Box>
                    </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
          <Box className={classes.switchContainer}>
            <Box>
              <Typography className={classes.switchTitle}>
                {switchTitles.bargain}
              </Typography>
              <Typography className={classes.switchDescription}>
                {switchDescriptions.bargain}
              </Typography>
            </Box>
            <IOSSwitch
              checked={this.state.isBargainOn}
              disabled={this.state.isOnOrderDisable}
              onChange={this.handleSwitchChange("isBargainOn")}
              data-test-id="isBargainOn"
            />
          </Box>
          <Box className={classes.switchContainer}>
            <Box>
              <Typography className={classes.switchTitle}>
                {switchTitles.onOrder}
              </Typography>
              <Typography className={classes.switchDescription}>
                {switchDescriptions.onOrder}
              </Typography>
            </Box>
            <IOSSwitch
              checked={this.state.isOnOrder}
              disabled={this.state.isOnOrderDisable}
              onChange={this.handleSwitchChange("isOnOrder")}
              data-test-id="isOnOrder"
            />
          </Box>
            <Box component="span" className={classes.errorStyle}>{this.state.askPriceValidation}</Box>
            </Grid>
            <Box className={classes.saveAndButtonContainer}> 
                <Button data-test-id="saveButton" className={classes.saveAndButton} onClick={this.handleSaveButton}>{configJSON.saveAndContinue}</Button>
            </Box>
           </Grid>

          </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
export default withStyles(webStyles)(ListingProduct)
// Customizable Area End