import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Paper,
  Button,
  styled
} from "@material-ui/core";
import { minusImg, plusImg, closeImg, arrowImg } from "./assets"
import { CartData, OrderItems } from "./Cfmultiplecarts1Controller";
let config = require('../../../framework/src/config');
import MessageModalWeb from "../../../components/src/MessageModal.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import Cfmultiplecarts1Controller, {
  Props,
  configJSON,
} from "./Cfmultiplecarts1Controller";

export default class Cfmultiplecarts1 extends Cfmultiplecarts1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledContainer component={"main"}>
      <Loader loading={this.state.loading} />
          <MessageModalWeb
            displayPopup={this.state.isPopupVisible}
            closeErrorPopup={this.closePopup}
            errorMessage={this.state.popupMessage} 
            data-test-id="msgModalID"
            />
            <MessageModalWeb
            displayPopup={this.state.isCartPopup}
            closeErrorPopup={this.closeCartPopup}
            errorMessage={this.state.cartPopupMsg} 
            data-test-id="msgModalID2"
            />
            <PageHeadings>
              <Title data-test-id="cartItemCountID">{`${configJSON.cartTxt}(${this.state.cartData.length})`}</Title>
              <ClearAll variant="text" disableElevation disableRipple onClick={()=>this.clearCart()}>{configJSON.clearAllTxt}</ClearAll>
            </PageHeadings>
            <MainContent>
              {
                this.state.cartData.map((cart: CartData) => {
                  let itemCount = cart.attributes.order_items.length;
                  return (
                    <CartItemContainer key={cart.id}>
                      <ClearButton variant="text" disableElevation onClick={()=> this.clearCart(cart.id)} data-test-id="clearCartBtnID" >
                        <img src={closeImg} alt="close" />
                      </ClearButton>
                      <CartItemHeader>
                        <CartHeaderLeft>

                          <StoreImage>
                            <img 
                            src={cart.attributes.seller_details.attributes.background_photo} 
                            style={{
                              fontSize: '50px',
                              backgroundColor:"Black",
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                              color: "#F5EA16",
                              display: cart.attributes.seller_details.attributes.background_photo?"block":"none"
                           }} alt=""/>
                           <p
                            style={{
                              fontSize: '57px',
                              backgroundColor:"Black",
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                              color: "#F5EA16",
                              textAlign:"center",
                              width:"100%",
                              height:"100%",
                              margin:"0",
                              display: cart.attributes.seller_details.attributes.background_photo?"none":"block"
                           }}>{cart.attributes.seller_details.attributes.business_name[0].toLocaleUpperCase()}</p>
                          </StoreImage>
                          <StoreDetails>
                            <StoreName>
                              {cart.attributes.seller_details.attributes.business_name}
                            </StoreName>
                            <StoreStats>
                              <StoreTotalPrice>₹{this.calculateTotalStoreItemAmount(cart.attributes.order_items)}</StoreTotalPrice>
                              <StoreTotalItem data-test-id="storeItemCountID">{itemCount} {itemCount > 1 ? 'Items': 'Item'}</StoreTotalItem>
                              <StoreTotalItem data-test-id="storeItemQtyTestId" >{this.calculateStoreItemTotalQty(cart.attributes.order_items)}</StoreTotalItem>
                            </StoreStats>
                          </StoreDetails>
                        </CartHeaderLeft>
                        <CartHeaderRight>
                          <StyledButton variant="text" disableElevation onClick={() => this.goToBuyerShoppingCart(cart.id)} data-test-id="checkoutBtnID">
                            Checkout
                          </StyledButton>
                          <img src={arrowImg} alt="arrow img" className={cart.id === this.state.activeCartItem ? "rotate180" : ""} onClick={() => this.cartItemToggle(cart.id)} data-test-id="arrowID" />
                        </CartHeaderRight>
                      </CartItemHeader>
                      
                      {
                        cart.id === this.state.activeCartItem &&
                        <CartItemBody>
                          {
                            cart.attributes.order_items.map((order: OrderItems) => {
                              return (
                                <CartItem key={order.id}>
                                  <CartItemLeft>
                                    <CartItemImage>
                                      <img src={config.baseURL + order.attributes.catalogue.attributes.product_images[0].url} alt={order.attributes.catalogue.attributes.title} />
                                    </CartItemImage>
                                    <CartItemRemove variant="text" disableElevation onClick={() => this.deleteCartData(order.id)} data-test-id="deleteBtnID">
                                      <img src={closeImg} alt="close" />
                                    </CartItemRemove>
                                    <CartItemDetail>
                                      <Typography className="itemName" data-test-id="itemTitleID">{order.attributes.catalogue.attributes.title}</Typography>
                                      <Typography className="itemPrice">₹{order.attributes.total_price}</Typography>
                                    </CartItemDetail>
                                  </CartItemLeft>
                                  <CartItemAction>
                                    <ActionButton variant="text" disableElevation onClick={() => this.updateDelete(order, 0)} data-test-id="minusBtn">
                                      <img src={minusImg} alt="minus" />
                                    </ActionButton>
                                    <ItemCount data-test-id="itemCountTxtID">{order.attributes.quantity <= 9 ? "0" + order.attributes.quantity : order.attributes.quantity}</ItemCount>
                                    <ActionButton variant="text" disableElevation onClick={() => this.updateDelete(order, 1)} data-test-id="plusBtn">
                                      <img src={plusImg} alt="plus" />
                                    </ActionButton>
                                  </CartItemAction>
                                </CartItem>

                              )
                            })
                          }
                        </CartItemBody>
                      }
                    </CartItemContainer>
                  )
                })
              }
            </MainContent>
    </StyledContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledContainer = styled(Box)({
  maxWidth: "809px",
  width: "100%",
  minHeight: "calc(100vh - 358px)",
  marginTop: "158px",
  marginBottom: "80px",
  margin: "auto"
});
const PageHeadings = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});
const Title = styled(Typography)({
  color: "#000",
  fontWeight: 500,
  fontSize: "20px",
  lineHeight: "30px",
  letterSpacing: "0.3px"
});
const ClearAll = styled(Button)({
  color: "#2E2E2E",
  fontWeight: 500,
  fontSize: "16px",
  width: "max-content",
  borderBottom: "1px solid #2E2E2E",
  borderRadius: "0",
  padding: "0",
  lineHeight: "14px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#fff"
  }
});
const MainContent = styled(Box)({
  marginTop: "74px",
});
const CartItemContainer = styled(Paper)({
  padding: "12px",
  borderRadius: "12px",
  boxShadow: "0 1px 6px rgba(0,0,0,0.4)",
  position: "relative",
  marginBottom: "20px"
});
const ClearButton = styled(Button)({
  borderRadius: "50%",
  border: "1px solid #E6E6E6",
  width: "24px",
  minWidth: "24px",
  height: "24px",
  backgroundColor: "#F3F3F3",
  position: "absolute",
  right: "-12px",
  top: "-15px",
  "&:hover": {
    backgroundColor: "#F3F3F3",
  }
})
const CartItemHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "12px",
});
const CartHeaderLeft = styled(Box)({
  display: "flex",
  alignItem: "center",
  gap: "12px"
})
const StoreImage = styled(Box)({
  width: "141px",
  height: "85px",
  borderRadius: "4px",
  overflow: "hidden",
  "& img": {
    width: "100%",
    height: "100%"
  }
});
const StoreDetails = styled(Box)({
  alignSelf: "center"
});
const StoreName = styled(Typography)({
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "27px",
  color: "#2E2E2E",
  marginBottom: "10px"
});
const StoreStats = styled(Box)({
  display: "flex",
  gap: "12px"
});
const StoreTotalPrice = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "15px",
  color: "#4E4E4E"
});
const StoreTotalItem = styled(Typography)({
  fontSize: "14px",
  fontWeight: 300,
  lineHeight: "15px",
  color: "#6E6E6E"
});
const CartHeaderRight = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "20px",
  "& .rotate180": {
    transition: "transform 0.3s ease",
    transform: "rotate(180deg)"
  }
});
const StyledButton = styled(Button)({
  backgroundColor: "#F5EA16",
  borderRadius: "22px",
  padding: "8px 24px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#F5EA16",
  }
});
const CartItemBody = styled(Box)({
  padding: "20px 0",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  borderTop: "1px solid #DDDDDD",
});
const CartItem = styled(Box)({
  display: "flex",
  justifyContent: "space-between"
});
const CartItemLeft = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "12px",
  position: "relative",
});
const CartItemImage = styled(Box)({
  borderRadius: "4px",
  overflow: "hidden",
  width: "64px",
  height: "64px",
  "& img": {
    width: "100%",
    height: "100%"
  }
});
const CartItemRemove = styled(Button)({
  borderRadius: "50%",
  border: "1px solid #E6E6E6",
  width: "24px",
  minWidth: "24px",
  height: "24px",
  backgroundColor: "#F3F3F3",
  position: "absolute",
  left: "-8px",
  top: "-15px",
  "&:hover": {
    backgroundColor: "#F3F3F3",
  }
})
const CartItemDetail = styled(Box)({});
const CartItemAction = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "20px"
});
const ActionButton = styled(Button)({
  color: "#444444",
  width: "32px",
  minWidth: "32px",
  height: "32px",
  borderRadius: "50%",
  border: "1px solid #444444",
  "&:hover": {
    backgroundColor: "#ffff",
  }
});

const ItemCount = styled(Typography)({});
// Customizable Area End
