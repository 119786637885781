import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    history?: any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    selectedTab: number;
    filteredData: any;
    openTunedBox: boolean;
    openFilterBox: boolean;
    openSortFilter: boolean;
    openCategoryFilter: boolean;
    openPriceFilter: boolean;
    openSizeFilter: boolean;
    openColorFilter: boolean;
    sortBy: string;
    categoryData: any;
    subCategoryData: any;
    openItemIndex: any;
    showLoader: boolean;
    categoryID: string;
    subCategoryID: any;
    priceFilter: string;
    minPrice: string;
    maxPrice: string;
    sizeFilter: any;
    colorFilter: any;
    openSubCategory: any;
    message: '';
    messageModel: boolean;
    forYouSubCategoryIDs: string;
    wishListProduct: any;
    showingAll: boolean;
    perPageCount: number;
    pageCount: number;
    tokenPresentOrNot:null | string
  // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class BuyerForgotPasswordController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getFilteredDataApiCallId: string = '';
    getCategoryDataApiCallId: string = '';
    getSubCategoryDataApiCallId: string = '';
    getForYouSubCategoryDataApiCallId: string = '';
    addItemInCartApiCallId: string = '';
    apiAddToWishList: string = '';
    apiDeleteFromWishList: string = ''
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        this.state = {
            loading: false,
            selectedTab: 0,
            filteredData: [],
            openTunedBox: false,
            openFilterBox: false,
            openSortFilter: false,
            openCategoryFilter: false,
            openPriceFilter: false,
            openSizeFilter: false,
            openColorFilter: false,
            sortBy: '',
            categoryData: [],
            subCategoryData: [],
            openItemIndex: null,
            showLoader: false,
            categoryID: '',
            subCategoryID: [],
            priceFilter: '',
            minPrice: '',
            maxPrice: '',
            sizeFilter: [],
            colorFilter: [],
            openSubCategory: null,
            message: '',
            messageModel: false,
            forYouSubCategoryIDs: '',
            wishListProduct: [],
            showingAll: false,
            perPageCount: 0,
            pageCount: 1,
            tokenPresentOrNot:null
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getFilteredDataApiCallId) {
                if (responseJson.catalogues) {
                    this.setState({
                        loading: false,
                        filteredData: responseJson.catalogues.data,
                        perPageCount: responseJson.meta.total_pages
                    })
                } else if (responseJson.errors) {
                    this.setState({
                        loading: false,
                        message: responseJson.errors[0].token,
                        messageModel: true
                    })
                } 
                if (responseJson.wishlist_items) {
                    this.setState({
                        wishListProduct: responseJson.wishlist_items.product_ids
                    })
                } else {
                    this.setState({
                        wishListProduct: []
                    })
                }
            } else if (apiRequestCallId === this.getCategoryDataApiCallId) {
                if (responseJson.data) {
                    this.setState({
                        loading: false,
                        categoryData: responseJson.data
                    })
                } else if (responseJson.errors) {
                    this.setState({
                        loading: false,
                    })
                }
            } else if (apiRequestCallId === this.getForYouSubCategoryDataApiCallId) {
                if (responseJson.data) {
                    this.setState({
                        loading: false,
                        forYouSubCategoryIDs: responseJson.data.map((item: any) => item.id).join(',')
                    }, () => {
                        this.getFilteredData();
                    })
                } else if (responseJson.errors) {
                    this.setState({
                        loading: false,
                    })
                }
            } else if (apiRequestCallId === this.getSubCategoryDataApiCallId) {
                if (responseJson.data) {
                    this.setState({
                        showLoader: false,
                        subCategoryData: responseJson.data
                    })
                } else if (responseJson.errors) {
                    this.setState({
                        showLoader: false,
                    })
                }
            } else if (apiRequestCallId === this.addItemInCartApiCallId) {
                if (responseJson.message) {
                    this.setState({
                        loading: false,
                        message: responseJson.message,
                        messageModel: true
                    })
                } else if (responseJson.errors) {
                    this.setState({
                        loading: false,
                        message: responseJson.errors,
                        messageModel: true
                    })
                }
            }

            switch (apiRequestCallId) {
                case this.apiAddToWishList:
                    this.checkItemAddedWishList(responseJson);
                    break;
                case this.apiDeleteFromWishList:
                    this.checkItemRemovedWishList(responseJson);
                    break;
                default:
                    break;
            }

        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        const tokenPresentOrNot =  await getStorageData('buerLoginToken')
        this.setState({tokenPresentOrNot:tokenPresentOrNot})
        const headingElement = document.getElementById("myref")
        if (headingElement) headingElement.scrollIntoView({ behavior: 'smooth' })
        this.getFilteredData();
        this.getCategoryData();
        this.getForYouSubCategoryData();
        // Customizable Area End
    }

    // Customizable Area Start

    checkItemAddedWishList = (responJson: any) => {
        if (responJson.message) {
            this.getFilteredData();
            this.closeMessageModal();
            this.setState({ message: responJson.message, messageModel: true })
        } else {
            this.closeMessageModal();
            this.setState({ message: responJson.errors, messageModel: true })
        }
    }

    checkItemRemovedWishList = (responJson: any) => {
        if (responJson.message) {
            this.getFilteredData();
            this.closeMessageModal();
            this.setState({ message: responJson.message, messageModel: true })
        } else {
            this.closeMessageModal();
            this.setState({ message: responJson.errors[0], messageModel: true })
        }
    }

    checkProductStatus = (producArray: any, ID: any) => {
        const index = producArray?.findIndex(
            (item: any) => 
            item.product_id === ID);
        if (index !== -1) {
            return true;
        } else { return false;
        }
    }
    
    verifyToken=()=>{
        if(this.state.tokenPresentOrNot === null){
          const message = new Message(getName(MessageEnum.NavigationMessage));
          message.addData(getName(MessageEnum.NavigationTargetMessage),"GetStartedLogin");
          message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(message)
        }
      }

    deleteProductItem = (producArray: any, ID: any) => {
        this.verifyToken()
        const index = producArray.find(
            (item: any) => item.product_id === ID).favourite_id;

        if (index) {this.deleteWishListItem(index)
        } else {
            return false;
        }
    }

    handleTabChange = (event: any, newValue: number) => {
        this.setState({
            selectedTab: newValue,
            openTunedBox: true
        });
    };

    closeTunedBox = () => {
        this.setState({
            openTunedBox: false,
            selectedTab: 0
        });
    }

    showALL = () => {
        this.setState({
            showingAll: !this.state.showingAll
        }, () => {
            this.getFilteredData();
        });
    }

    handleChangePagiation = (event: any, value: number) => {
        this.setState({
            pageCount: value
        }, () => {
            this.getFilteredData();
        });
    }

    openFilterDialogBox = () => {
        this.setState({
            openFilterBox: true,
        });
    }

    closeFilterDialogBox = () => {
        this.setState({
            openFilterBox: false,
        });
    }

    openSortDialogBox = () => {
        this.setState({
            openSortFilter: true,
            openFilterBox: false
        });
    }

    handleSortByChange = (event: any) => {
        this.setState({
            sortBy: event.target.value,
        });
    };

    handleClearSortBy = () => {
        this.setState({
            sortBy: '',
            openSortFilter: false,
        }, () => {
            this.getFilteredData()
        })
    }

    closeSortDialogBox = () => {
        this.setState({
            openSortFilter: false,
        });
    }

    openCategoryDialogBox = () => {
        this.setState({
            openCategoryFilter: true,
            openFilterBox: false
        });
    }

    selectCategory = (id: any, index: number) => {
        this.state.openItemIndex !== index && this.getSubCategoryData(id)
        this.setState((prevState) => {
            return {
                openItemIndex: prevState.openItemIndex === index ? null : index,
            }
        });
        this.setState({
            categoryID: id,
            subCategoryID: '',
            subCategoryData: []
        })
    };

    selectSubCategory = (id: any) => {
        const index = this.state.subCategoryID.indexOf(id)
        if (index !== -1) {
            const updatedSubCategoryID = [...this.state.subCategoryID]
            updatedSubCategoryID.splice(index, 1);
            this.setState({
                subCategoryID: updatedSubCategoryID
            })
        } else {
            this.setState({
                subCategoryID: [...this.state.subCategoryID, id]
            })
        }
    }

    handleClearCategory = () => {
        this.setState({
            categoryID: '',
            subCategoryID: '',
            openCategoryFilter: false
        }, () => {
            this.getFilteredData()
        })
    }

    closeCategoryDialogBox = () => {
        this.setState({
            openCategoryFilter: false,
        });
    }

    openPriceDialogBox = () => {
        this.setState({
            openPriceFilter: true,
            openFilterBox: false
        });
    }

    handlePriceChange = (event: any) => {
        const { value } = event.target;

        const [minString, maxString] = value.split('-');
        this.setState({
            priceFilter: value,
            minPrice: minString,
            maxPrice: maxString
        })
    }

    handleMinPriceChange = (event: any) => {
        this.setState({
            minPrice: event.target.value
        })
    }

    handleMaxPriceChange = (event: any) => {
        this.setState({
            maxPrice: event.target.value
        })
    }

    handleClearPrice = () => {
        this.setState({
            priceFilter: '',
            minPrice: '',
            maxPrice: '',
            openPriceFilter: false
        }, () => {
            this.getFilteredData()
        })
    }

    closePriceDialogBox = () => {
        this.setState({
            openPriceFilter: false,
            openFilterBox: true
        });
    }

    openSizeDialogBox = () => {
        this.setState({
            openSizeFilter: true,
            openFilterBox: false
        });
    }

    handleSizeChange = (event: any) => {
        const index = this.state.sizeFilter.indexOf(event.target.value);
        if (index !== -1) {
            const updatedColors = [...this.state.sizeFilter];
            updatedColors.splice(index, 1);
            this.setState({
                sizeFilter: updatedColors
            })
        } else {
            this.setState({
                sizeFilter: [...this.state.sizeFilter, event.target.value]
            });
        }
    }

    handleClearSize = () => {
        this.setState({
            sizeFilter: '',
            openSizeFilter: false,
        }, () => {
            this.getFilteredData()
        })
    }

    closeSizeDialogBox = () => {
        this.setState({
            openSizeFilter: false,
            openFilterBox: true
        });
    }

    openColorDialogBox = () => {
        this.setState({
            openColorFilter: true,
            openFilterBox: false
        });
    }

    handleColorChange = (color: string) => {
        const index = this.state.colorFilter.indexOf(color);
        if (index !== -1) {
            const updatedColors = [...this.state.colorFilter];
            updatedColors.splice(index, 1);
            this.setState({
                colorFilter: updatedColors
            })
        } else {
            this.setState({
                colorFilter: [...this.state.colorFilter, color]
            });
        }
    }

    handleClearColor = () => {
        this.setState({
            colorFilter: '',
            openColorFilter: false,
        }, () => {
            this.getFilteredData()
        })
    }

    closeColorDialogBox = () => {
        this.setState({
            openColorFilter: false,
            openFilterBox: true
        });
    }

    handleClearAll = () => {
        this.setState({
            openFilterBox: false,
            categoryID: '',
            sortBy: '',
            minPrice: '',
            priceFilter: '',
            sizeFilter: '',
            maxPrice: '',
            colorFilter: '',
            subCategoryID: ''
        }, () => {
            this.getFilteredData()
        })
    }

    handleClickCategory = (id: any, index: number) => {
        this.setState((prevState) => {
            return {
                openSubCategory: prevState.openSubCategory === index ? null : index,
                subCategoryID: '',
                subCategoryData: [],
                categoryID: id
            }
        }, () => {
            this.getFilteredData()
            this.getSubCategoryData(id)
        });
    }

    handleClickSubCategory = (id: any) => {
        const index = this.state.subCategoryID.indexOf(id)
        if (index !== -1) {
            const updatedSubCategoryID = [...this.state.subCategoryID]
            updatedSubCategoryID.splice(index, 1);
            this.setState({
                subCategoryID: updatedSubCategoryID
            }, () => {
                this.getFilteredData()
            })
        } else {
            this.setState({
                subCategoryID: [...this.state.subCategoryID, id]
            }, () => {
                this.getFilteredData()
            })
        }
    }

    closeMessageModal = () => {
        this.setState({
            messageModel: false
        })
    }

    getCategoryData = () => {
        this.setState({
            loading: true
        });

        const header = { "Content-Type": "application/json" };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCategoryDataApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_categories/categories`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSubCategoryData = (id: any) => {
        this.setState({ showLoader: true })
        const header = { "Content-Type": "application/json" };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSubCategoryDataApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_categories/sub_categories?category_id=${id}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getForYouSubCategoryData = async () => {
        this.setState({ showLoader: true })
        const header = { "Content-Type": "application/json", token: await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getForYouSubCategoryDataApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_categories/sub_categories/for_you_sub_categories`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getFilteredData = async () => {
        const { sortBy, minPrice, maxPrice, sizeFilter, colorFilter, categoryID, subCategoryID, forYouSubCategoryIDs } = this.state;

        this.setState({
            loading: true,
            openSortFilter: false,
            openFilterBox: false,
            openPriceFilter: false,
            openCategoryFilter: false,
            openColorFilter: false,
            openSizeFilter: false,
        });

        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };
        const queryParams = [];

        // Add parameters only if the corresponding state values are present
        if (sortBy) queryParams.push(`sort=${sortBy}`);
        if (categoryID) queryParams.push(`category_id=${categoryID}`);
        if (subCategoryID) queryParams.push(subCategoryID.map((number: any) => `sub_category_id[]=${number}`).join('&'));
        if (minPrice) queryParams.push(`start_price=${minPrice}`);
        if (maxPrice) queryParams.push(`end_price=${maxPrice}`);
        if (sizeFilter.length > 0) queryParams.push(`size=${sizeFilter.join(',')}`);
        if (colorFilter.length > 0) queryParams.push(`color=${colorFilter.join(',')}`);
        if (!categoryID) queryParams.push(`sub_category_ids[]=${forYouSubCategoryIDs}`);
        queryParams.push(`page=${this.state.pageCount}`);
        if (this.state.showingAll) {
            queryParams.push(`per_page=${this.state.perPageCount*10}`);
        }

        const queryString = queryParams.length > 0 ? queryParams.join("&") : "";

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getFilteredDataApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_advanced_search/search?type=products&${queryString}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    addItemInCart = async (item: any) => {
        this.verifyToken()
        this.setState({ loading: true });

        const httpBody = {
            "catalogue_id": Number(item.id),
            "catalogue_variant_id": Number(item.attributes.catalogue_variants[0].id),
            "quantity": 1
        }

        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.addItemInCartApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_order_management/add_item_to_cart`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    updateWishListItem = async (id: number, type: string) => {
        this.verifyToken()
        const buyertoken = await getStorageData("buerLoginToken");
        if (buyertoken) {
          const header = {
            "token": buyertoken,
            "Content-Type": "application/json"
          };
          if (type == "catalogue") {
            type = "product"
          }
          const body = {
            "data": {
              "favouriteable_id": id,
              "type": type
            }
          }
    
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
    
          this.apiAddToWishList = requestMessage.messageId;
    
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
    
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body));
    
          requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.exampleAPiMethod
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.favouriteItemEndPoint
          );
    
          runEngine.sendMessage(requestMessage.id, requestMessage);
        }
      }
    
      deleteWishListItem = async (id: number) => {
        const buyertoken = await getStorageData("buerLoginToken");
        if (buyertoken) {
          const header = {
            "token": buyertoken,
            "Content-Type": "application/json"
          };
    
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
    
          this.apiDeleteFromWishList = requestMessage.messageId;
    
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );

          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.delete
          );
    
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.favouriteItemEndPoint}/${id}`
          );
    
          runEngine.sendMessage(requestMessage.id, requestMessage);
        }
      }

    handleProductDescription = (productId: string | number) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDescription");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
        this.send(message);
    };
    // Customizable Area End
}
