import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";

interface OrderImage {
  id: number;
  url: string;
}

interface ShipmentDetails {
  name: string;
  mobile: string;
  pin_code: string;
}

interface CatalogueAttributes {
  title: string;
  product_images?: OrderImage[];
}

interface Catalogue {
  id: number;
  type: string;
  attributes: CatalogueAttributes;
}

interface OrderAttributes {
  id: number;
  order_management_order_id: number;
  quantity: number;
  status: string;
  order_status_id: number;
  placed_at: string;
  created_at: string;
  updated_at: string;
  unit_price: string;
  total_price: string;
  grand_total: string;
  order_number: string;
  catalogue: Catalogue;
  shipment_details?: ShipmentDetails;
}

interface OrderDataPayloadType {
  id: string;
  type: string;
  attributes: OrderAttributes;
}

interface AllOrdersApiResponse {
  data: OrderDataPayloadType[];
}

interface ApiCallPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object | string;
  type?: string;
}

interface ProductImage {
  id: number;
  url: string;
}

export interface Order {
  id: number;
  request_detail: string;
  quantity: number;
  request_status: string;
  order_status: string | null;
  price: number | null;
  cancellation_reason: string | null;
  expected_delivery: string;
  account_id: number;
  catalogue_id: number;
  created_at: string;
  updated_at: string;
  final_price: string | null;
  deliver_by: string | null;
  product_title: string;
  product_images: ProductImage[];
  pin_code: string | null;
  contact: string | null;
  bargain_status	: string;
}

interface OrdersResponse {
  orders: Order[];
  message: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  tabValue: number;
  filterStatus: string;
  orderDataList: any;
  copyOrderDataList: any;
  openBox: boolean;
  searchValue: string;
  loading: boolean;
  all: number;
  orderData : Array<Order>;
  copyOrderData : Array<Order>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisedOrderStatusController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrderDataListApiCallId: string = '';
  getBuyerOrdersListingDataApiCallId : string = '';
  getBuyerBargainOrderListingApiCallId : string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      tabValue: 0,
      filterStatus: '',
      orderDataList: [],
      copyOrderDataList: [],
      openBox: false,
      searchValue: '',
      loading: false,
      all: 0,
      orderData : [],
      copyOrderData: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if(responseJsonData && !responseJsonData.errors){
        switch (apiRequestCallID){
          case  this.getBuyerOrdersListingDataApiCallId :
            this.getBuyerOrderListingSuccesCallback(responseJsonData);
            break;
          case this.getBuyerBargainOrderListingApiCallId:
            this.getBuyerOrderListingSuccesCallback(responseJsonData);
            break;
          case this.getOrderDataListApiCallId:
            this.getOrderDataSuccessCallBack(responseJsonData);
            break;
          default:
            break;
        }
      }

    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleChange = (event: any, newValue: any) => {
    if (newValue === 1) {
      this.getBuyerBargainOrderLisitingApiCall();
    } else if (newValue === 2) {
      this.getBuyerOrderListingApiCall();
    }
    this.setState({
      tabValue: newValue,
      openBox: true,
      filterStatus: ""
    })
  }

  async componentDidMount() {
    const headingElement = document.getElementById("myref")
    if(headingElement) headingElement.scrollIntoView({behavior: 'smooth'})
    this.getOrderDataList();
  }

  handleFilterStatus = (status: string) => {
    const filteredItem = status ? this.state.orderDataList.filter((item: any) => String(item.attributes.status) === status) : this.state.orderDataList;
    this.setState({ copyOrderDataList: filteredItem, filterStatus: status, searchValue: '' })
  };

  handleFilterOrderStatus = (status: string, isOnOrder: boolean) => {
    const filteredData = status
      ? this.state.orderData.filter(order => {
        let orderStatus = isOnOrder ? order.request_status : order.bargain_status;
        return String(orderStatus) === status;
      })
      : this.state.orderData;
    this.setState({ copyOrderData: filteredData, filterStatus: status, searchValue: '' });
  };

  HandleSingleOrderStatus = (itemId: any) => {
    this.props.navigation.push('BuyerOrderDetails', `?item_id=${encodeURIComponent(itemId)}`, encodeURIComponent(itemId))
  }

  closeBox = () => {
    this.setState({
      openBox: false,
      tabValue: 0
    })
  }

  handleSearchFieldValue = (event: any) => {
    this.setState({
      searchValue: event.target.value
    },()=>{
      if (this.state.searchValue === '') {
        this.getOrderDataList();
      }
    })
  }

  handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      this.getOrderDataList();
    }
  }

  getOrderDataList = async () => {
    this.setState({ loading: true });

    const { searchValue, filterStatus } = this.state;

    const queryParams = [];
    if (searchValue) queryParams.push(`search=${searchValue}`);
    if (filterStatus) queryParams.push(`status=${filterStatus}`);
    const queryString = queryParams.length > 0 ? queryParams.join("&") : "";

    const header = { "Content-Type": "application/json", token: await getStorageData("buerLoginToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getOrderDataListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_order_management/order_items?${queryString}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  apiCall = async (apiReqData: ApiCallPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiReqData;
    let buyerToken = await getStorageData("buerLoginToken");
    const header = {
      "Content-Type": contentType,
      token: buyerToken,
    };
    const requestApiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestApiMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestApiMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    requestApiMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    body && type != 'formData' ?
      requestApiMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
      : requestApiMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(requestApiMessage.id, requestApiMessage);
    return requestApiMessage.messageId;
  };

  getBuyerOrderListingApiCall = async () => {
    this.setState({loading: true});
      this.getBuyerOrdersListingDataApiCallId = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.getBuyerOrderListingApiEndPoint + `?on_order_orders=true`,
      });
  };

  getBuyerBargainOrderLisitingApiCall = async () => {
    this.setState({loading: true});
    this.getBuyerBargainOrderListingApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getBuyerOrderListingApiEndPoint + `?bargain_orders=true`,
    });
  };

  getBuyerOrderListingSuccesCallback = (responseJson: OrdersResponse) => {
    this.setState({orderData: responseJson.orders, loading: false, copyOrderData: responseJson.orders });
  };

  formateDate = (expectedDate: string) => {
    return moment(expectedDate).format("DD-MM-YYYY"); 
  };

  getOrderDataSuccessCallBack = (responseJson: AllOrdersApiResponse) => {
    this.setState({ loading: false });
    if (responseJson.data) {
      this.setState({ orderDataList: responseJson.data, copyOrderDataList: responseJson.data });
    }
    if (this.state.filterStatus === '') {
      this.setState({ all: responseJson.data.length });
    }
  };

  handleFilterOrderStatusCount = (orderStatus: string) => {
    const filteredItem = this.state.orderDataList?.filter((item: any) => item.attributes.status === orderStatus);
    if (filteredItem.length > 0) {
      return `(${filteredItem.length})`
    }
  };

  handleOtherOrderStatusCount = (orderStatus: string, isOnOrder: boolean) => {
    const filterOrderData = this.state.orderData?.filter((item: Order) => {
      let status = isOnOrder ? item.request_status : item.bargain_status;
      return String(status) === orderStatus;
    });
    if (filterOrderData.length > 0) {
      return `(${filterOrderData.length})`
    }
  };
  // Customizable Area End
}
